@import "@ph/common/styles/main";

:global .ant-modal-close-x {
  img {
    width: 40px;
    height: 40px;
  }
}

:global .ant-divider-horizontal {
  border-top: 1px solid $sand;
}

.kitScanFormContainer {
  flex-direction: row;
  margin-top: 45px;

  .receiveKitResultContainer, .allReceivedKitsSummaryContainer {
    margin: 30px 0 40px;
    min-height: 145px;
  }

  label {
    display: block;
    padding-bottom: 16px;
  }

  .receiveBtnContainer {
    margin-left: 45px;
    margin-top: 36px;
    
    :global .ant-btn {
      height: 60px !important;
      padding: 16px 40px !important;
    }
  }
}

.allReceivedKitsSummaryContainer {
  margin: 60px 0;
}

.allReceivedKitsSummaryContainer img {
  padding-right: 25px;
}