@import "@ph/common/styles/main";

.md03Container {
	margin-top: 4px;
	margin-bottom: 30px;
	@extend .border-2;
	position: relative;

	
	// max-height: 0;
	// overflow: hidden;
	// transition: max-height 0.3s ease-out;
		

	// &.open {
	// max-height: 1000px; /* Adjust this value based on your content */
	// transition: max-height 0.5s ease-in;
	// }


	@include for-phone-only {
		margin: 0px 16px 20px 16px;
	}
	// max-height: 0;
	// overflow: hidden;
	// transition: max-height 0.3s ease-out;

	// &.open {
	//     max-height: 1000px; // Adjust this value based on your content's maximum height
	// }

	.titleContainer {
		background-color: $uv-blue;
		color: $white;
		padding: 40px 30px;
		@extend .border-bottom-2;
		.title {
			@extend .h4;
		}
		.subtitle {
			// text-align: right;
			@extend .body-txt-md;
		}
		.description,
		.label {
			@extend .body-txt-lg;
		}
		.priceContainer {
			margin: 20px 0px;
			@extend .center-flex;
			gap: 8px;
			width: 100%;
			justify-content: left;
			flex-wrap: wrap;
			.price {
				@extend .body-txt-lg;
				@extend .font-Neue-medium;
				color: var(--text-color);
			}
			.discountPrice {
				@extend .body-txt-lg;
				color: #6283d8;
				text-decoration: line-through;
			}
		}
		.tagContainer {
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			margin: 20px 0px;
		}
		@include for-phone-only {
			padding: 20px 16px;
			.priceContainer {
				margin: 14px 0px;
			}
			.tagContainer,
			.label {
				margin: 14px 0px;
			}
			.subtitle {
				padding-top: 14px;
				border-top: 1px solid $white;
			}
		}
	}
	.productContainer {
		padding: 40px 30px;
		display: flex;
		gap: 40px;
		flex-direction: column;
		.imageContainer {
			width: 100%;
			height: 100%;
			max-height: 200px;
			background-color: transparent;
			@extend .center-flex;
			img {
				width: 100%;
				height: 100%;
			}
			@include for-phone-only {
				padding: 0px 16px 0px 16px;
			}
		}
		.productInfo {
			@extend .border-bottom-2;
			padding-bottom: 20px;
			// padding-right: 30px;
			@include for-phone-only {
				padding: 0px 16px 20px 16px;
			}
			.leftContainer {
				color: $uv-blue;
				.label {
					@extend .note-2;
					@extend .font-Mono-bold;
					text-transform: uppercase;
					@include for-both-phone-and-tablet {
						margin-top: 18px;
					}
				}
				.title {
					@extend .h4;
					display: flex;
					align-items: left;
					justify-content: center;
					min-height: 160px;
					flex-direction: column;
					gap: 8px;
					.nextTitle {
						@extend .h5;
					}
					@include for-phone-only {
						min-height: 34px;
						padding: 16px 0px;
						gap: 2px;
						font-size: $h3-text-mobile;
						line-height: $h3-line-height-mobile;

						.nextTitle {
							font-size: $h4-text-mobile;
							line-height: $h4-line-height-mobile;
						}
					}
				}
				.secondTitle {
					padding-top: 10px;
					min-height: unset;
					@include for-phone-only {
						padding-top: 0px;
						padding-bottom: 18px;
					}
				}
			}
			.rightContainer {
				display: flex;
				justify-content: space-between;
				height: 100%;
				flex-direction: column;
				.infoContainer {
					.question {
						@extend .note-1;
						@extend .font-Mono-medium;
						text-transform: uppercase;
					}
					.info {
						@extend .note-2;
						@extend .font-Mono-medium;
						padding: 20px 0px;
					}
				}

				.guide {
					@extend .note-2;
					@extend .font-Mono-medium;

					color: $uv-blue;
				}
			}
		}
		@include for-phone-only {
			padding: 20px 0px;
			gap: 20px;
			background-color: #f5f1f0;
		}
	}
	.buttonContainer {
		@extend .center-flex;
		justify-content: space-between;
		.first {
			text-decoration: underline;
			@extend .body-txt-md;
			cursor: pointer;
		}
		.addButton {
			@extend .body-txt-md;
			@extend .center-flex;
			padding: 8px 30px;
			background-color: $uv-blue;
			border-radius: 42px;
			min-width: 200px;
			@extend .border-2;
			border-color: $uv-blue;
			cursor: pointer;
			color: $white;
			gap: 12px;

			&.checked {
				background-color: $white;
				color: $night;
				border-color: $night;
			}

			@include for-phone-only {
				width: calc(100% - 32px);
			}
		}
		@include for-phone-only {
			flex-direction: column-reverse;
			gap: 16px;
			padding-bottom: 30px;
		}
	}

	.multiImageContainer {
		display: flex;
		gap: 20px;
		width: 100%;
		height: 100%;
		img,
		svg {
			width: calc(50% - 10px);
			height: 100%;
			object-fit: fill;
		}
		@include for-phone-only {
			padding: 0px 16px 0px 16px;
		}
	}
}

.checkbox {
	:global {
		.ant-checkbox {
			.ant-checkbox-inner {
				width: 30px;
				border: none;
				border-radius: 0px;
				height: 30px;
				&:after {
					display: inline-block;
					width: 30px;
					height: 30px;
					border: none;
					background: $white;
					opacity: 1;
					transform: rotate(0deg) scale(1) translate(-20%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
				}
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				width: 30px;
				border: 1px solid $night;
				border-radius: 0px;
				height: 30px;
				&:after {
					content: "";
					display: table;
					width: 9px;
					height: 17px;
					border: 2px solid $night;
					border-top: none;
					border-left: none;
					border-inline-start: 0;
					opacity: 1;
					position: absolute;
					inset-inline-start: 21.5%;
					top: 46%;
					left: 24%;
					box-sizing: border-box;
					transform: rotate(45deg) scale(1) translate(-50%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
					background: none;
					border-color: $night;
				}
			}
			&:after {
				border-color: $night !important;
			}
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: transparent !important;
			border-color: $night !important;
			border: 2px solid $night;
		}
		:global(.ant-checkbox-inner) {
			&:hover {
				background-color: transparent !important;
				border-color: $night !important;
			}
		}
	}
	&:hover {
		background-color: transparent !important;
	}
}

.includedContainer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: fit-content;
	color: $night;
	cursor: pointer;
	@extend .center-flex;
	@include for-phone-only{
		margin-top: 20px;
	}
	.icon {
		background-color: $night;
		padding: 8px;
		height: 41px;
		width: 40px;
		svg {
			width: 100%;
			height: 100%;
		}
		@include for-phone-only {
			height: 34px;
			width: 35px;
		}
	}
	.text {
		background-color: $uv-blue-40;
		padding: 10px 14px;
		@extend .note-1;
		@extend .font-Mono-medium;
		text-transform: uppercase;
		@include for-phone-only {
			padding: 9px 14px;
		}
	}
}
