@import "../../styles/main";

.desktopShowCase {
    @extend .show-only-on-desktop;
    padding: 45px 50px;
}

.kitContent {
    padding: 35px 20px;
    gap: 20px;
    @include for-phone-only {
        padding: 0;
        gap: 8px;
    }
    :global .ant-col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        :local .kitTitle {
            flex: 0.5;
        }
    }
    .mainTitle div {
        font-family: "Neue Haas Grotesk Light" !important;
    }
    .kitTitle {
        font-style: normal;
        font-weight: 100;
        color: $night;
    }
    .kitSubtitle {
        @extend .body-txt-lg;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: $night;
        @include for-phone-only {
            font-size: $body-text-mobile-lg;
            line-height: $body-line-height-mobile-lg;
        }
    }
    .kitShowCaseImgContainer {
        height: 220px !important;
        max-width: 220px !important;

        img {
            width: 100%;
        }
    }
    .colType1 {
        align-items: flex-start;
        padding: 20px;
        gap: 30px;
        @include for-phone-only {
            padding: 20px 35px;
        }
        @include for-both-phone-and-tablet {
            align-items: center;
            text-align: center;
            gap: 10px;
        }
        @include for-screen-1367-1199 {
            gap: 10px;
        }
    }
    .colType2 {
        align-items: center;
        @include for-phone-only {
            padding: 20px;
        }
        @include for-medium-phone-only {
            padding: 0 16px;
        }
        @include for-small-phone-only {
            padding: 0 16px;
        }
        .imgContainer {
            width: 350px;
            height: 350px;
            border-radius: 4px;
            background-color: transparent;

            img {
                border-radius: 4px;
            }
            @include for-both-phone-and-tablet {
                width: 100%;
            }
        }
    }
    .colType3 {
        padding: 20px;
        gap: 20px;
        align-items: flex-start;
        @include for-both-phone-and-tablet {
            padding: 16px 25px 35px;
            align-items: center;
            text-align: center;
            gap: 10px;
        }
        @include for-screen-1367-1199 {
            gap: 10px;
        }
        .kitSubtitleSmall {
            @extend .body-txt-md;
            color: $primary-color;
        }
        .protocolButton {
            background: $uv-blue !important;
            color: $white !important;
        }
        .kitButton {
            width: fit-content;
            height: 60px;
            border-radius: 12px !important;
            margin-top: 20px;
            color: $white;
            font-size: $button-text;
            font-weight: 500;
            line-height: $button-text;
            @include for-phone-only {
                // width: fit-content;
                height: 40px;
                border-radius: 4px;
                font-size: $button-text-mobile-large;
                line-height: $button-text-mobile-large;
            }
        }
    }
    .colAlignment {
        align-items: flex-start !important;
        text-align: left !important;
        @include for-phone-only {
            align-items: center !important;
            text-align: center !important;
        }
    }
    .colType4 {
        display: flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 0 20px 0;
        // @include for-both-phone-and-tablet {
        //     padding: 20px 0 20px 0;
        // }
        @include for-phone-only {
            padding: 20px;
            align-items: center;
        }
        @include for-medium-phone-only {
            padding: 0 16px 16px 16px;
        }
        @include for-small-phone-only {
            padding: 0 16px 16px 16px;
        }
    }
}

.h2Title {
    @extend .h4;
}