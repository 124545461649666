@import "../../../../common/styles/main.scss";

.progressContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@include for-phone-only {
		display: block;
	}
}

.statusContent {
	// padding: 40px 40px 50px 40px;
	background-color: $day;
	max-width: 80%;
	// @extend .center-flex;
	@include for-both-phone-and-tablet {
		// padding: 20px 20px 35px 20px;
		border-radius: 12px;
		margin-bottom: 18px;
		max-width: 100%;
	}
	.verticalSeparator {
		width: 3px;
		height: 80%;
		background: $sand;
	}

	.step {
		margin-inline-start: -55px;
		@include for-phone-only {
			margin-inline-start: -30px;
			margin-top: 8px;
			width: 600px;
		}
	}
	.stepIcon {
		width: 58px;
		height: 66px;
		@include for-both-phone-and-tablet {
			width: 36px !important;
		}
	}
	.stepIcon2 {
		width: 38px;
	}
	.verticalDivider {
		background: $night;
		margin: 0;
		height: 18px;
		// top: 2.5px;
	}
	.horizontalDivider {
		background: $night;
		margin: 0 0 0 10px;
		width: 20px !important;
		min-width: 20px !important;
	}
}

// .horizontalStepKitFlow.ant-steps-icon {
// 	top: 3px;
// }

$arrow-size: 8px;
$arrow-color: black;
$arrow-transition: all 0.3s ease-in;

.arrow {
	position: relative;
	top: 7px;
	// transform: rotate(180deg);

	&.arrowLeft {
		left: 7px;

		&:after {
			content: "";
			position: absolute;
			transition: $arrow-transition;

			border-top: $arrow-size solid transparent;
			border-bottom: $arrow-size solid transparent;
			border-right: $arrow-size solid transparent;
			border-left: $arrow-size solid $arrow-color;

			right: -8px;
			bottom: -16px;
		}
	}

	&.arrowRight {
		right: 7px;

		&:before {
			content: "";
			position: absolute;
			transition: $arrow-transition;

			border-top: $arrow-size solid transparent;
			border-bottom: $arrow-size solid transparent;
			border-right: $arrow-size solid $arrow-color;
			border-left: $arrow-size solid transparent;

			left: -8px; // Adjusted for right arrow
			bottom: -16px;
		}
	}
}

.halfCircle {
	position: relative;
	top: 25px;
	width: 49px;
	height: 24px;
	border-top-left-radius: 100px;
	border-top-right-radius: 100px;
	transform: rotate(180deg);
	transition: background 0.1s ease;
	
    &.filled {
        background: $night;
		cursor: pointer;
    }
    &.empty {
        background: #F5F1F0;
		cursor: pointer;
    }

	@include for-phone-only {
		width: 36px;
    	height: 18px;
	}
}


// quiz progress bar start
.horizontalStepQuizProgress {
	// @include for-both-phone-and-tablet {
	//   display: none;
	// }
	padding-top: 50px;

	@include for-both-phone-and-tablet{
		padding-top: 40px;
	}
	
	@include for-phone-only {
		padding-top: 2px;
	}

	:global(.ant-steps-item-container) {
		position: relative;
	}

	:global(.ant-steps-item-content) {
		position: absolute;
		top: -40px;

		@include for-phone-only {
			position: inherit;
			top: -16px;
		}
	}

	:global(.ant-steps-item-finish),
	:global(.ant-steps-item-process) {
		:global(.ant-steps-item-container) {
			& > :global(.ant-steps-item-tail) {
				&:after{
					background-color: transparent;
				}
				
			}
		}
	}

	:global(.ant-steps-item-wait) {
		:global(.ant-steps-item-icon .barIcon) {
			background: transparent;
			pointer-events: none;
		}
		
		@include for-both-phone-and-tablet {
			.ant-steps-item-icon .barIcon {
				display: inherit;
			}
			.ant-steps-item-content {
				display: none;
			}
		}
	}

	:global(.ant-steps-item-finish) {
		@include for-both-phone-and-tablet {
			.ant-steps-item-content {
				display: none;
			}
		}
	}

	:global(.ant-steps-item-wait
		.ant-steps-item-container
		> .ant-steps-item-tail::after){
		background-color: #302e2b !important;
	}
	:global(.ant-steps-label-vertical .ant-steps-item-tail) {
		padding: 1px 0 !important;
		background: $night;
		height: 2px;
		margin-top: -2px;
	}
	:global(.ant-steps:not(.ant-steps-vertical)
		.ant-steps-item-custom
		.ant-steps-item-icon) {
		margin-left: 57px;
	}
	:global(.ant-steps-label-vertical .ant-steps-item ){
		overflow: visible;
		width: 58px;
		@media (max-width: 1440px) {
			width: 51px;
		}
		@include for-phone-only {
			width: 22px;
		}
	}

	:global(.status-icon-container) {
		:global(.ant-steps-item-icon) {
			margin-left: 32px !important;
			@include for-phone-only{
			margin-left: 40px !important;

			}
		}
		:global(.ant-steps-item-content) {
			margin-left: 0;
		}
		:global(.ant-steps-icon) {
			top: -9px !important;
		}
	}
	:global(.ant-steps-item-description) {
		color: $night !important;
		white-space: nowrap;
		@extend .note-1;
		@extend .font-Mono-medium;

		@include for-phone-only {
			font-size: $note-2-text-mobile;
		}
	}
	:global(.ant-steps .ant-steps-item-tail){
		top: 16px;
	}
	:global(.ant-steps.ant-steps-label-vertical .ant-steps-item-content){
		margin-top: 16px;
		@include for-phone-only {
			margin-top: -8px;
		}
	}
	.status-completed {
		color: $crest;
		font-size: 18px;
		margin-top: 12px;
	}
}
