@import "../../../styles/main";
.backContainer {
    display: flex;
    align-items: center;
    img {
        width: 50px;
        margin-left: 0px;
        color: $uv-blue;
        @include for-both-phone-and-tablet {
            width: 36px;
        }
    }
    
}
.navText {
    margin-left: 12px;
    font-family: "Neue Haas Grotesk Light" !important;
    font-style: normal;
    font-weight: 100;
    color: $uv-blue;
    @extend .h3;
    @include for-both-phone-and-tablet {
        font-size: $h2-text-mobile;
        line-height: $h2-line-height-mobile;
    }
}