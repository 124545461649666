@import "../../styles/main";

.heading {
    font-size: $h3-text;
    line-height: $h3-line-height;
    color: $primary-color;
    @include for-both-phone-and-tablet {
        font-size: $h3-text-mobile;
        line-height: $h3-line-height-mobile;
    }
}
.statusContentWrapper {
    border-bottom: 1px solid $night;
    .statusContent:last-child {
        padding: 0 40px;
        @include for-both-phone-and-tablet {
            display: none;
        }
    }
    &:last-child {
        .statusContent:last-child {
            @extend .hide;
        }
    }

    @include for-both-phone-and-tablet {
        border:none;
    }
}
.subjectKitContent {
    // padding: 30px 40px 40px;
    display: flex;
    flex-direction: column;
    // border-radius: 12px;
    border: 1px solid $night;
    overflow: hidden;
    margin: 50px 0px 80px;
    // gap: 40px;
    @include for-both-phone-and-tablet {
        margin: 25px 0px 25px;
        padding: 0 0 25px;
        border:none;
        // border-radius: 12px;
        overflow: visible;
    }
}
.statusContent {
    padding: 40px 40px 50px 40px;
    background-color: $white;
    @include for-both-phone-and-tablet {
        padding: 20px 20px 35px 20px;
        margin-bottom: 18px;
        border-bottom: 1px solid $night;
    }
    .verticalSeparator {
        width: 3px;
        height: 80%;
        background: $sand;
    }
    .titleContainer {
        margin-top: 4px;
        margin-bottom: 20px;
    }
    .title {
        @extend .body-txt-lg;
        color: $primary-color;
        
        // letter-spacing: 0.05em;
        @include for-both-phone-and-tablet {
            margin-top: 40px;
        }
    }
    .subtitle {
        font-size: $h5-text;
        line-height: $h5-line-height;
        //letter-spacing: 0.05em;
        color: $primary-color;
        @include for-both-phone-and-tablet {
            font-size: $h5-text-mobile;
            line-height: $h5-line-height-mobile;
        }
    }
    .step {
        margin-inline-start: -55px;
    }
    .stepIcon {
        width: 60px;
        @include for-both-phone-and-tablet {
            width: 36px !important;
        }
    }
    .stepIcon2 {
        width: 38px;
    }
    .verticalDivider {
        background: $night;
        margin: 0;
        height: 18px;
        top: 2.5px;
    }
    .horizontalDivider {
        background: $night;
        margin: 0 0 0 10px;
        width: 20px !important;
        min-width: 20px !important;
    }
    .statusBlock {
        @extend .status-info-container;
        flex-direction: column;
        gap: 8px;
        padding: 20px 20px 20px;
        border-radius: 0px !important;
        @include for-both-phone-and-tablet {
            gap: 4px;
        }
        .heading {
            @extend .body-txt-lg;
            @extend .font-Neue-medium;
        }
        .subtitle {
            @extend .body-txt-md;
        }
        .blockIcon {
            color: $infrared !important;
            font-size: 36px;
            width: 50px;
        }
        .blockButton {
            width: fit-content;
            height: $button-height;
            padding: 0 30px;
            font-size: $button-text;
            line-height: $button-text;
            margin-bottom: -44px;
            margin-top: 8px;
            color: $day;
            @include for-both-phone-and-tablet {
                width: 60%;
                margin-bottom: 0;
                margin-top: 10px;
            }
            @include for-both-phone-and-tablet {
                font-size: $button-text-mobile;
                line-height: $button-text-mobile;
                height: 40px;
                border-radius: 4px;
            }
        }
    }
    .colRight {
        display: flex;
        flex-direction: column;
        align-self: center;
        gap: 30px;
        @include for-both-phone-and-tablet {
            gap: 40px;
            margin-top: 28px;
        }
    }
    .rowDivider {
        background: $sand;
        margin: 0;
        height: 1px;
    }
    .kitExceptionContainer {
        @extend .status-info-container;
        flex-direction: row;
        gap: 40px;
        padding: 40px 80px;

        @include for-both-phone-and-tablet {
            flex-direction: column;
            padding: 25px 15px;
            gap: 16px;
        }

        .statusIcon {
            color: $day;
            font-size: 75px;

            @include for-both-phone-and-tablet {
                font-size: 40px;
            }
        }

        .statusMessage {
            @extend .h3;
            color: $day;
            //letter-spacing: 1.25px;
            text-align: left;
            p {
                @include for-desktop-up {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .sampleDateContainer {
        // border: 1px solid #2d2a26;
        background-color: $stone;
        color: $night;
        padding: 8px 24px;
        margin-right: 24px;
        // border-radius: 6px;
        display: inline-block;
        min-width: 21%;
        text-align: center;
        span {
            @extend .font-Mono-medium;
        }
        @media (max-width: 1425px) and (min-width: 800px) {
            width: max-content;
        }
        @include for-both-phone-and-tablet {
            text-align: center;
            width: 100%;
            margin-bottom: 12px;
            padding: 8px 2px;
        }

        .bodyText {
            font-weight: 500;
            @extend .note-1;
            @extend .font-Mono-medium;
            color: $night;

        }
        .kitDate {
            @include for-both-phone-and-tablet {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

.horizontalStepKitFlow.ant-steps-icon {
    top: 3px;
}

.findkitContainer {
    @extend .bg-stone;
    padding: 40px 10vw;
    @include for-both-phone-and-tablet {
            padding: 30px 24px;
        }
    .lineSeperator {
        height: 80px;
        padding: 0px !important;
        border-inline-start: 1px solid $night;
        border-block-start: none;

        @include for-both-phone-and-tablet {
            border-inline-start: none;
            height: 4px; 
            width: 100%;  
            border-block-start: 1px solid $night;
        }
    }


}
