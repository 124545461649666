@import "@ph/common/styles/main";

.footerContainer {
    background: $primary-color;
    color: $secondary-color;
    height: $header-md-height !important;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-phone-only {
        height: calc(#{$footer-xs-height} - 450px) !important;
    }
    // .logoSection {
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start;
    //     padding-right: 1rem;
    //     @include for-phone-only {
    //         padding-right: 0;
    //         margin-bottom: 20px;
    //     }
    //     img {
    //         width: 150px;
    //         height: auto;
    //         content: url("../../../../../common/assets/images/admin_header.svg");
    //     }
    // }
    .h1Text {
        font-size: $h1-text;
        line-height: $h1-line-height;
        color: $secondary-color;
        font-family: "Quincy CF";
        font-weight: 100;
    }
    .logo {
        // object-fit: cover;
        height: auto;
        width: 150px;
        // content: url("../../../../../common/assets/images/admin_header.svg");
        // @include for-medium-phone-only {
        //     height: 50px;
        //     width: 120px;
        // }
        // @include for-small-phone-only {
        //     height: 40px;
        //     width: 120px;
        // }
    }
    //18
    .bodyText2 {
        font-size: $h5-text;
        color: $primary-color;
        line-height: $h5-line-height;
        color: $secondary-color;
        cursor: pointer;
        @include for-phone-only {
            font-size: $h5-text-mobile;
            line-height: $h5-line-height-mobile;
        }
    }
    .socialIconSection {
        gap: 1.5rem !important;
        // margin-top: 0.5rem;
        @include for-phone-only {
            margin: 20px 0;
        }
        .socialIcon {
            width: 1.8rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .centerAlignMobile {
        @include for-phone-only {
            justify-content: center !important;
        }
    }
}
