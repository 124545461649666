@import "../../../styles/main";

.darkHeader {
	background: $primary-color !important;
	.sidebarToggleButton {
		color: $secondary-color !important;
	}
	.headerTabContainer {
		.headerTab {
			.headerTabLink {
				.text {
					color: $white !important;
					&:hover {
						color: $uv-blue-40 !important;
					}
				}
			}
		}
		@include for-phone-only {
			display: none;
		}
	}
	.buttonSection {
		.space {
			position: relative;
			.verticalSeparator {
				background: $secondary-color !important;
			}
		}
	}
}

.headerContainer {
	background: $secondary-color;
	height: $header-desktop-height;
	position: fixed;
	top: 0;
	align-items: center;
	justify-content: center;
	display: flex;
	width: 100%;
	padding: 0 60px;
	z-index: 999;
	transition: all 0.45s ease-in-out;
	:global(.ant-col) {
		max-height: 100%;
	}
	:global(.ant-row) {
		max-width: $header-desktop-max-width;
		width: 100%;
	}
	.sidebarToggleButton {
		border: none;
		color: $primary-color;
		outline: none;
		background: inherit;
		z-index: 1000;
		width: fit-content;
		padding: 0;
	}
	@include for-phone-only {
		height: 64px !important;
		display: block;
		padding: 0 16px;
		top: unset;
		bottom: 0;
	}
	.logoSection {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		img {
			width: 160px;
			height: 82px;
			@include for-phone-only {
				width: 28px;
				height: 22px;
			}
		}
	}
	.headerTabContainer {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.logoSection {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-right: 3vw;
			margin-left: -6px;

			img {
				width: 160px;
				height: 82px;

				@include for-phone-only {
					width: 106.22px;
					height: 33px;
				}
			}
		}
		.headerTab {
			display: flex;
			flex-direction: row;
			justify-content: center;
			// gap: 20px;
			gap: 24px;
			.headerTabLink {
				// margin: 0 10px;
				.text {
					color: $secondary-color;
					@extend .body-txt-md;
					white-space: pre;
					letter-spacing: 0.05em !important;
				}
			}
		}
		@include for-phone-only {
			display: none;
		}
	}
	.buttonSection {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;
		.space {
			// gap: 30px !important;
			gap: 24px !important;

			@include for-both-phone-and-tablet {
				gap: 12px !important;
			}

			@include for-phone-only {
				gap: 24px !important;
			}
			.productButton {
				@extend .center-flex;
				border: none !important;
				box-shadow: none !important;
				height: 100% !important;
				svg {
					width: 18px;
					height: 18px;
					margin-left: 8px;

					@include for-phone-only {
						transform: rotate(179deg);
					}
				}
				span {
					@extend .body-txt-lg;
					@include for-phone-only {
						font-size: $body-text-mobile-med;
					}
				}
				button {
					box-shadow: none !important;
					&:disabled {
						border: none;
						display: flex;
						align-items: center;
					}
				}
			}
			.AddButton {
				background: $uv-blue !important;
				color: $white !important;
				border: none !important;
				&:disabled {
					border: none;
					background-color: #f5f1f0 !important;
					color: #bab9b8 !important;
				}
			}
		}
		@include for-phone-only {
			justify-content: flex-start;
			.space {
				justify-content: space-between;
				width: 100%;
			}
		}
	}
}

.text {
	color: $white;
	&:hover {
		color: $uv-blue-40;
	}
}

.cartContainer {
	background-color: $white;
	width: 550px;
	// position: absolute;
	top: 58px;
	right: 200px;
	height: auto;
	margin: -12px;

	.cartTitleContainer {
		background: $uv-blue;
		color: $white;
		@extend .note-2;
		@extend .font-Mono-medium;
		padding: 20px 20px;
	}

	.subCartContainer {
		@include for-phone-only {
			max-height: 450px;
			overflow: scroll;
		}
	}
	.cartItem {
		padding-top: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// border-top: 1px solid $stone;

		.cartTitle {
			color: $night;
			@extend .body-txt-lg;
			@extend .font-Mono-medium;
			@media (max-width: 1440px) {
				font-size: $note-1-text;
				line-height: $note-1-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-1-text-mobile;
				line-height: $note-1-line-height-mobile;
			}
		}
		.itemTitle,
		.itemPrice,
		.discountPrice {
			color: $uv-blue;
			text-transform: uppercase;
			@extend .note-1;
			@extend .font-Mono-medium;
			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
		}
		.discountPrice {
			color: #abbdea;
			text-decoration: line-through;
			padding-left: 8px;
		}
		.itemTitle {
			width: 70%;
		}
		.itemPrice,
		.discountPrice {
			@extend .font-Mono-bold;
		}
	}
	.subCartSection {
		padding: 20px 20px;

		.innerContainer {
			border-left: 3px solid $uv-blue;
			padding-left: 16px;
			padding-top: 10px;
			padding-bottom: 10px;

			.subCartTitle {
				color: $uv-blue;
				@extend .note-1;
				@extend .font-Mono-bold;
			}
		}
		&.divider {
			border-top: 1px solid #f5f1f0;
		}
	}
	.subTotal {
		padding: 24px 24px 50px 24px;
		// border-top: 1px solid $stone;

		.subItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 6px 0px;

			.itemTitle,
			.itemPrice {
				color: $night;
				text-transform: capitalize;
				@extend .note-1;
				@extend .font-Mono-medium;
			}
			.itemPrice {
				text-transform: uppercase;
			}
		}
	}
	.total {
		padding: 24px 24px 24px 14px;
		border-left: 10px solid $uv-blue;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #f5f1f0;

		.itemTitle,
		.itemPrice {
			color: $night;
			text-transform: capitalize;
			@extend .body-txt-lg;
			@extend .font-Mono-medium;
			@media (max-width: 1440px) {
				font-size: $note-1-text;
				line-height: $note-1-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-1-text-mobile;
				line-height: $note-1-line-height-mobile;
			}
		}
		.itemPrice {
			@extend .font-Mono-bold;
		}
	}
	@include for-phone-only {
		width: calc(100% + 24px);
		min-width: calc(100% + 24px);
		margin-right: 0px;
	}
}

.cartPopover {
	padding: 0px !important;
}

.otpInput {
	input {
		text-align: center !important;
		border: none !important;
		border-radius: 0px !important;
		font-size: $h4-text !important;
		color: $primary-color !important;
		background: $stone !important;
		font-weight: 400 !important;
		caret-color: $primary-color !important;
		outline: none !important;
		width: 50px !important;
		height: 40px !important;
		text-transform: uppercase !important;
		@include for-phone-only {
			// width: 30 !important;
			// height: 34px !important;
			border-radius: 0px !important;
			font-size: $h4-text-mobile !important;
		}
	}
}
.continueButton {
	width: fit-content;
	height: 40px;
	border-radius: 12px;
	padding: 0 30px;
	font-size: $button-text;
	line-height: $button-text;
	@include for-phone-only {
		font-size: $button-text-mobile;
		line-height: $button-text-mobile;
		border-radius: 4px;
	}
}
// .continueButton:disabled {
//     background: $sand !important;
//     color: $secondary-color !important;
// }
.logoutIcon {
	width: 25px;
	height: auto;
	@include for-small-phone-only {
		height: 15px;
	}
}
.logoutSection {
	display: flex;
	align-items: center;
	gap: 10px;
	@include for-small-phone-only {
		gap: 8px;
	}
}
.customViewBoxPadding {
	@include for-phone-width-297 {
		padding: 0 0.5rem !important;
	}
}
