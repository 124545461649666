
@import "../../styles/main";
.phSelect{
    width: 100%;
    :global(.ant-select-selector) {
        border-radius: 4px !important;
        padding: 11px !important;
        height: 50px !important;
        background-color: transparent !important;
        border-color: $night !important;
        input {
            padding-top: 13px !important;
            background-color: transparent !important;
        }
        
    }
    :global(.ant-select-arrow){
        color: $night;
    }
    &.dark {
        :global(.ant-select-selector) { 
            border-color: $day !important;
        }
        :global(.ant-select-selection-placeholder) {
            color: #838383 !important;
        }
        :global(.ant-select-selection-item){
            color: $day !important;
        }
        :global(.ant-select-arrow){
            color: $day;
        }
        input {
            color: $day !important;
        }
        
    }
}