@import "../../../styles/main";

.stickyHeader {
    position: fixed !important;
    top: 0;
    width: 100%;
}

.headerContainer {
    background: $uv-blue;
    height: $protocol-header-md-height !important;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-phone-only {
        height: $protocol-header-xs-height !important;
        display: block;
    }
    .sidebarToggleButton {
        border: none;
        color: $primary-color;
        outline: none;
        background: inherit;
        z-index: 1000;
        width: fit-content;
        padding: 0;
    }
   
    .buttonSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }
    .space {
        gap: 1.5rem !important;
        @media (max-width: 820px) {
            gap: 1rem !important;
        }
    }

    .cartIcon {
        height: auto;
        width: 2rem;
        cursor: pointer;
        @include for-phone-only {
            height: $protocol-header-xs-height;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}
