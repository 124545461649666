@import "../../../styles/main";

.incrementButton {
    padding: 0px 4px;
    padding-top: 1px;
    height: 30px !important;
    display: flex;
    align-items: center;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 29px;
    background-color: transparent;
    border-left: 2px solid $night;
    border-bottom: 2px solid $night;

    @include for-both-phone-and-tablet {
        padding: 0px 7px;
        padding-top: 1px;
    }
    @include for-phone-only {
      border-left: 1px solid $night;
    border-bottom: 1px solid $night;  
    }
    .included {
        padding: 0px 10px;
        @extend .note-2;
        @extend .font-Mono-medium;
    }
    .count {
        @extend .note-1;
        @extend .font-Mono-medium;
        @extend .center-flex;
        letter-spacing: 1px;
        padding: 0px 6px;
        color: $night;
        height: 100%;
        @include for-phone-only{
            padding: 0px 0px;
        }
    }
    .buttonIcon {
        color: $night;
        font-size: 14px;
    }
    .plusButton,
    .minusButton {
        box-shadow: none;
        border: none;
        background: transparent !important;
        height: 100%;
        padding: 0px 0px !important;
    }
}

.checkbox {
    position: absolute;
    top:0;
    right:0;

    &.relative {
        position: relative;
    }

    :global {
        .ant-checkbox {
            top: 0 !important;
            .ant-checkbox-inner {
                width: 29px;
                border-radius: 0px;
                height: 30px;
                &:after {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    border-left: 2px solid $night;
                    border-bottom: 2px solid $night;
                    border-right: 1px solid $night;
                    // border-right: 1px solid $night;
                    background: linear-gradient(#000 0 0), linear-gradient(#000 0 0), #fff;
                    background-position: center;
                    background-size: 16px 2px, 2px 16px; // thickness = 2px, length = 50% (25px)
                    background-repeat: no-repeat;
                    opacity: 1;
                    transform: rotate(0deg) scale(1) translate(-7px, -15px);
                    transition: all 0.1s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;

                    @include for-phone-only{
                        border-left: 1px solid $night;
                        border-bottom: 1px solid $night;
                    }
                }
            }
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
            width: 30px;
            border: 1px solid $night;
            border-radius: 0px;
            height: 30px;
            &:after {
                content: "";
                display: table;
                width: 9px;
                height: 17px;
                border: 2px solid #fff;
                border-top: 0;
                border-inline-start: 0;
                opacity: 1;
                position: absolute;
                inset-inline-start: 21.5%;
                top: 46%;
                left: 24%;
                box-sizing: border-box;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                transition: all 0.1s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                background: none;
            }
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $night !important;
            border-color: $night !important;
            &:hover{
                background-color: $night !important;
                border-color: $night !important;
            }
        }
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled){
                &:hover {
                    border-color: $night !important;
                }
            }
            .ant-checkbox-checked:not(.ant-checkbox-disabled){
                &:after{
                   border-color: $night !important; 
                }
            }
    }
}
