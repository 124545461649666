@import "../../../styles/main";
.emptyDataContainer {
    background: transparent;
    margin: 50px 0px 100px;
    text-align: center;
    @include for-phone-only {
        margin: 45px 0;
    }

    .textContainer {
        margin: 24px 0px;
    }

    .divider {
        border: 1px solid $night;
    }

    .text {
        @extend .h4;
    }
}
