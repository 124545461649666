#frontrow-widget div:empty {
	display: flex;
}
#frontrow-widget span:empty {
	display: flex;
}

#frontrow-widget {
    width: 100%;
    margin-top: 10px;
}
.frontrow-container {
    width: 100%;
    margin-top: 20px;
}
.fr-text {
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1;
}
.fr-text span.fr-shorter {
	width: 80%;
}
.fr-text span {
	display: flex;
	width: 100%;
	background-color: #e0e0e0;
	height: 20px;
	border-radius: 100px;
}
.fr-loading-box {
	display: flex;
	padding: 18px 16px;
	gap: 14px;
	border-radius: 12px;
	background-color: #fff;
	border: none;
	width: 100%;
	cursor: pointer;
}
.fr-loading-provider {
	border-radius: 100px;
	width: 36px;
	height: 36px;
	border: 2px #fff solid;
	background-color: #e0e0e0;
}

.fr-provider-container {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	min-width: 80px;
}
.fr-sample {
	margin-left: -14px;
}
@media only screen and (max-width: 480px) {
	.fr-loading-box {
		padding: 14px 12px;
		gap: 8px;
	}
}
