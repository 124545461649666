@import '@ph/common/styles/main';

.portalSidebar {
  background-color: $secondary-color;
  font-family: "Neue Haas Grotesk Display Pro", serif;
  padding: 12px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.12);

  ul {
    background-color: $secondary-color;
  }

  :global {
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      padding-top: 5px;
    }

    .ant-menu-submenu-arrow {
      top: 53%;
    }

    .ant-menu-title-content {
      @include getFontSizeMapFor('body-lg');
    }

    .ant-menu-submenu-selected {
      background-color: $sand !important;
      border-radius: 4px;
      padding-bottom: 8px;
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: transparent;
      padding: 0 8px;

      .ant-menu-item {
        margin: 0;
        padding-left: 25px !important;
      }
    }

    .ant-menu-item-selected {
      background-color: $night !important;
      border-radius: 4px;
      color: $day !important;
    }

    .ant-menu-submenu-title {
      font-weight: 600;
      border-radius: 4px;
    }
    .ant-menu-item-divider{
      border-color: $sand;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.portalSubmenu {
  font-weight: 500 !important;

  ul {
    background-color: $secondary-color !important;
  }
}
