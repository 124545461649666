@import "../../../styles/main";

.reportSection {
	margin-top: 100px;
	border: 2px solid $night;

	.reportSectionHeader {
		position: relative;

		.titleContainer {
			width: 100%;
			display: inline-block;
			// border-right: 2px solid $night;
			.title {
				background-color: $sand;
				display: flex;
				align-items: center;
				padding-left: 30px;
				min-height: 90px;
				@extend .h4;
			}
			.subTitle {
				padding: 14px;
				border-top: 2px solid $night;
				@extend .note-1;
				@extend .font-Mono-medium;
				text-transform: uppercase;
				padding-left: 30px;
			}
		}
		.description {
			@extend .body-txt-lg;
			@extend .center-flex;
			height: 100%;
			padding: 12px 30px;
			border-left: 2px solid $night;
		}
		@include for-both-phone-and-tablet {
			.description {
				padding: 12px 16px;
				border-top: 2px solid $night;
				border-left: none;
			}
			.titleContainer {
				border-right: none;
				.title,
				.subTitle {
					padding-left: 12px;
				}
				.subTitle {
					font-size: $note-2-text-mobile;
					line-height: $note-2-line-height-mobile;
				}
				.title {
					min-height: 52px;
					line-height: 20px;
				}
			}
		}
		@include for-phone-only {
			.description {
				border-top: 1px solid $night;
				padding: 12px 16px;
			}
			.titleContainer {
				.subTitle {
					border-top: 1px solid $night;
				}
			}
		}
	}

	@include for-both-phone-and-tablet {
		margin-top: 80px;
	}
	@include for-phone-only {
		border: 1px solid $night;
	}

	.reportSectionContainer {
		border-top: 2px solid $night;
		min-height: 300px;
		@include for-phone-only {
			border-top: 1px solid $night;
		}
	}
}

.reportSectionScore {
	display: inline-flex;
	font-size: 80px;
	background-color: #2d2926;
	align-items: center;
	color: $white;
	width: 100%;
	height: 100%;
	justify-content: center;
	@extend .font-NeueHGDP-light;
	@include for-both-phone-and-tablet {
		font-size: 60px;
	}
	@include for-phone-only {
		font-size: 56px;
	}
}

.reportSectionCount {
	position: absolute;
	left: -32px;
	top: -6px;
	@extend .body-txt-lg;
	@extend .font-Mono-medium;
	&.combined {
		display: none;
	}
	@include for-both-phone-and-tablet {
		left: 0;
		top: -30px;
		&.combined {
			display: inline;
		}
	}
}

.reportSectionHeaderSmall {
	width: 100%;
	border-bottom: 1px solid $stone;
	position: relative;
	// .reportSectionCount {
	// 	position: absolute;
	// 	left: -32px;
	// 	top: -6px;
	// 	@extend .body-txt-lg;
	// 	@extend .font-Mono-medium;
	// }
	.title {
		background-color: $night;
		@extend .h4;
		@extend .center-flex;
		justify-content: left;
		color: $white;
		padding: 20px 25px;
		height: 100%;
		min-height: 130px;
	}
	.subTitle {
		@extend .body-txt-lg;
		@extend .center-flex;
		padding: 20px 35px;
		background: $stone;
		height: 100%;
	}
	@include for-phone-only {
		.title,
		.subTitle {
			padding: 20px 16px;
		}
		.title {
			min-height: 60px;
		}
	}
}

.productSection {
	.productHeader {
		border-bottom: 2px solid $night;
		@extend .show-desktop-hide-mobile;
		.spacing {
			padding: 15px 25px;
			padding-right: 0px;
		}
		@include for-phone-only {
			border-bottom: 1px solid $night;
		}
	}
	.dividedCol {
		border-left: 2px solid $night;
		@include for-both-phone-and-tablet {
			border-top: 2px solid $night;
			border-left: none;
			margin-top: 16px;
			padding-bottom: 16px !important;
		}
		@include for-phone-only {
			border-top: 1px solid $night;
		}
	}
	.productImage {
		min-height: 150px;
		width: 100%;
		@extend .center-flex;
		img {
			width: 100%;
			height: 100%;
			max-height: 150px;
			object-fit: cover;
			// background-color: #d9d9d9;
		}

		@include for-both-phone-and-tablet {
			padding-bottom: 18px;
		}

		@include for-phone-only {
			min-height: 1px;
			padding-bottom: 0px;
		}
	}
	.productImage img:not([src]) {
		display: none;
	}

	.productRow {
		background-color: $sand;
		border-bottom: 2px solid $night;
		.productDetails {
			position: relative;
			min-height: 140px;
			@include for-phone-only {
				min-height: 1px;
			}
		}
		.directionText {
			height: 100%;
			display: flex;
			align-items: center;
			@extend .note-2;
			@extend .font-Mono-medium;
		}
		.spacing {
			padding-left: 25px;
			padding-right: 25px;
			@include for-both-phone-and-tablet {
				padding-left: 0px;
				padding-right: 0px;
			}
		}
		:global(.ant-col) {
			display: flex;
			align-items: center;
			justify-content: left;
			padding: 20px 0px;
			@include for-both-phone-and-tablet {
				padding-bottom: 0px;
				padding-top: 16px;
				padding-left: 16px;
				padding-right: 8px;
				// padding-bottom: 16px;
			}
		}
		@include for-both-phone-and-tablet {
			margin-top: 20px;
			border: 2px solid $night;
		}
		@include for-phone-only {
			border: 1px solid $night;
		}
		&.overlay {
			* {
				color: #908c8b;
			}
		}
		.hideMobile {
			@include for-phone-only {
				display: none;
			}
		}
		.directionContainer {
			display: none;
			@include for-phone-only {
				display: flex;
				padding: 0px 0px 16px 0px;
			}
			:global(.ant-col) {
				padding-top: 12px;
			}
		}
	}
	.imageSection {
		width: 100%;
		height: 100%;
		:global(.ant-col) {
			display: flex;
			align-items: center;
			justify-content: left;
			padding: 0px !important;
		}
		@include for-both-phone-and-tablet {
			min-height: 80px;
		}
	}
	.buttonContainer {
		padding: 40px 25px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: #f5f1f0;

		.addButton {
			background-color: $uv-blue !important;
			color: $white !important;
			font-weight: 600 !important;
			border: none;
			@include for-phone-only {
				height: 50px !important;
				font-size: 14px !important;
				width: 100%;
			}
		}
		@include for-both-phone-and-tablet {
			padding: 30px 12px;
			background-color: #ffffff;
		}
	}
	.thankyouContainer {
		padding: 40px 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: $night;

		.retestText,
		.thankyouText {
			@extend .h3;
			color: $white;
		}
		.thankyouText {
			font-style: italic;
			font-weight: 500;
			font-family: "PPEditorialOld UltralightItalic" !important;
		}
		@include for-both-phone-and-tablet {
			padding: 10px 0px 60px 0px;
			background-color: $white;
			.retestText,
			.thankyouText {
				color: $night;
				font-style: italic;
				font-weight: 500;
				font-family: "PPEditorialOld UltralightItalic" !important;
			}
		}
	}
}

.handSSection {
	margin-top: 100px;
	border: 2px solid $night;

	&.sensitivitySection {
		margin-top: 0px;
		border: none;

		.col1 {
			@include for-both-phone-and-tablet {
				border: none;
			}
		}
	}
	.header {
		position: relative;

		// .reportSectionCount {
		// 	position: absolute;
		// 	left: -32px;
		// 	top: -6px;
		// 	@extend .body-txt-lg;
		// 	@extend .font-Mono-medium;
		// }

		.doubleScore {
			position: absolute;
			left: -58px;
			top: 60px;
			min-width: 84px;
			border-top: 4px solid $night;
			transform: rotate(90deg);

			.leftCount {
				position: absolute;
				position: absolute;
				left: -26px;
				transform: rotate(269deg);
				top: -10px;

				@extend .body-txt-lg;
				@extend .font-Mono-medium;
			}
			.rightCount {
				position: absolute;
				right: -26px;
				transform: rotate(269deg);
				top: -10px;
				@extend .body-txt-lg;
				@extend .font-Mono-medium;
			}
		}

		.titleContainer {
			width: 100%;
			display: inline-block;
			border-right: 2px solid $night;
			border-right: none;
			border-top: none;
			.title {
				background-color: $sand;
				display: flex;
				align-items: center;
				padding-left: 30px;
				min-height: 80px;
				@extend .h4;
			}
			.subTitle {
				padding: 12px;
				border-top: 2px solid $night;
				border-right: none;
				padding-left: 30px;
				@extend .note-1;
				@extend .font-Mono-medium;
				text-transform: uppercase;
				@include for-phone-only {
					font-size: $note-2-text-mobile;
					line-height: $note-2-line-height-mobile;
				}
			}
			@include for-phone-only {
				.title,
				.subTitle {
					padding-left: 10px;
				}
				.subTitle {
					border-top: 1px solid $night;
				}
				.title {
					min-height: 52px;
				}
			}
		}
		.description {
			@extend .body-txt-lg;
			@extend .center-flex;
			height: 100%;
			padding: 12px 30px;
			border: 2px solid $night;

			border-left: none;
			border-right: none;
			@include for-phone-only {
				border: 1px solid $night;
				padding: 12px 16px;
				border-left: none;
				border-right: none;
			}
		}
	}
	.col2 {
		border-left: 2px solid $night;
		&.first {
			border-left: none;
			@include for-both-phone-and-tablet {
				border-left: 2px solid $night;
			}
			@include for-phone-only {
				border-left: 1px solid $night;
			}
		}
	}
	@include for-both-phone-and-tablet {
		border: none;
		margin-top: 80px;
		.row {
			gap: 70px;
		}
		.col2,
		.col1 {
			border: 2px solid $night;
		}
	}
	@include for-phone-only {
		.col1,
		.col2 {
			border: 1px solid $night;
		}
	}
}

.hallmarkImageHeader {
	width: 100%;
	.col1,
	.col2,
	.col3 {
		padding: 10px 14px 10px 14px;
		background: $uv-blue-40;
		color: $white;
		border: 2px solid $night;
		border-top: none;
		border-right: none;
		color: $night;
		display: flex;
		align-items: center;
	}
	.col4,
	.col5 {
		padding: 8px;
		background: $uv-blue-40;
		border-bottom: 1px solid $night;
		border-right: 1px solid $night;
		display: flex;
		align-items: center;
		justify-content: left;
		&.last {
			border-right: none;
			padding-right: 0px;
		}
	}
	.col5 {
		background: $uv-blue;
		color: $white;
		@include for-phone-only {
			padding-left: 16px;
		}
	}
	.col1 {
		flex: 0 0 18.5%;
		max-width: 18.5%;
		background: $uv-blue;
		color: $white;
		border-left: none;
		@extend .center-flex;
	}
	.col2 {
		flex: 0 0 50.2%;
		max-width: 50.2%;
		padding-left: 28px;
	}
	.col3 {
		flex: 1 0 29.166666666666668%;
		max-width: 32.166667%;
		border-right: none;
	}
	.container {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	.text {
		@extend .note-1;
		@extend .font-Mono-medium;
		text-transform: uppercase;
		@include for-phone-only {
			font-size: $note-3-text-mobile;
			line-height: $note-3-line-height-mobile;
		}
	}
}
.hallmarkSection {
	@extend .center-flex;
	padding: 50px 30px;

	.desktopImage {
		@extend .show-desktop-hide-mobile;
		svg {
			width: 100%;
		}
	}
	.mobileImage {
		.title {
			@extend .h4;
			margin-bottom: 26px;
		}
		@extend .show-mobile-hide-desktop;
		svg {
			width: 100%;
		}
	}
	.diagram {
		cursor: pointer;
		.hex {
			fill: #ffffff;
		}
		.text,
		.diagram {
			fill: #2d2a26;
		}
		.stroke {
			stroke: #2d2926;
		}
		&.selected {
			.hex {
				fill: #2d2a26;
			}
			.text {
				fill: $white;
			}
			.diagram {
				fill: $uv-blue-75;
			}
			.stroke {
				stroke: $uv-blue-75;
			}
		}
	}

	@include for-phone-only {
		padding: 30px 16px 40px 16px;
	}
}

.descriptionHeader {
	width: 100%;
	.iconContainer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 24px 0px;
		min-height: 145px;
		flex-direction: column;
		svg {
			width: 55%;
		}
		@include for-phone-only {
			margin: 18px 0px;
			min-height: 80px;
			svg {
				width: 40%;
			}
		}
	}
}

.hallMarkModal {
	animation-duration: 0.00001s !important;

	:global .ant-modal-content {
		background-color: $white;
		border-radius: 0px !important;
		box-shadow: none;
		text-align: center;
		padding: 0 !important;
		text-align: left;
		min-height: 380px;
		margin-left: 16px;
		margin-right: 17px;
		margin-top: -1px;

		:global .ant-modal-close-x {
			height: 36px;
			position: absolute;
			top: 0px;
			right: -2px;
			width: 24px;
		}
	}
	:global .ant-modal-body {
		padding: 0 !important;
	}
	.description {
		min-height: 400px;
	}
	:global(.ant-modal-close-x) {
		img {
			background: $white;
			border: 1px solid $night;
			padding: 7px;
		}
	}
	:global(.ant-modal-close) {
		top: 0;
		right: 8px;
	}
}

.hallmarkCount {
	font-family: "PPEditorialOld UltralightItalic" !important;
	@extend .h1;
	font-size: 100px;
	margin-bottom: 20px;
	margin-top: 40px;
	font-style: italic;
	font-weight: 500;
	@include for-phone-only {
		font-size: 70px;
		margin-top: 20px;
	}
}

.bodyContainer {
	width: 100%;
	display: flex;
	gap: 20px;
	svg {
		width: 100%;

		@include for-phone-only {
			height: 490px;
		}
	}
}

.onlyDescription {
	// border-left: 2px solid $night;
	@include for-both-phone-and-tablet {
		border-left: none !important;
	}
}