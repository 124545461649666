.hexagon-container {
  position: relative;
  width: calc(var(--size) * 1.732);
  height: var(--size);
  margin: calc(var(--size) / 2);

  .hexagon {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--base-color);
    clip-path: polygon(
      0% 25%,
      50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%
    );

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom left,
        var(--gradient-color),
        var(--base-color)
      );
      clip-path: polygon(
        0% 25%,
        50% 0%,
        100% 25%,
        100% 75%,
        50% 100%,
        0% 75%
      );
      opacity: 0.7;
    }
  }
}