@import "@ph/common/styles/main";

.topContainer {
	margin-top: 40px;
	.thankyouText {
		text-align: center;
		@extend .note-1;
		@extend .font-Mono-medium;
	}
	.topBox {
		@extend .border-2;
		margin-top: 40px;
		background: linear-gradient(
				90deg,
				#eaffa6 0%,
				#f1ddff 59%,
				#25a18e 99.5%
			);
		.title {
			padding: 28px 16px;
			@extend .center-flex;
			@extend .h4;
			@extend .border-bottom-2;
			
		}
		.subSection {
			text-align: center;
			padding: 50px 12%;
			.name {
				@extend .h1;
				font-style: italic;
				font-weight: 500;
				font-family: "PPEditorialOld UltralightItalic" !important;
				margin-bottom: 20px;
			}
			.quizText {
				font-style: italic;
				font-weight: 500;
				font-family: "PPEditorialOld UltralightItalic" !important;	
			}
			.subTitle {
				@extend .h4;
			}
		}
		.lastSection {
			background: $night;
			@extend .center-flex;
			padding: 20px 12px;
			.image {
				@extend .center-flex;
				height: 100%;
				padding-right: 12px;
			}
			.text {
				color: $white;
				text-transform: uppercase;
				@extend .note-1;
			}
		}
	}
	@include for-both-phone-and-tablet {
		margin-top: 10px;
		.topBox {
			margin-top: 20px;
			.title {
				padding: 16px 12px;
				font-size: $h5-text-mobile;
			}
			.subSection {
				padding: 40px 20px;
			}
		}
	}
}

.recProducts {
	margin-top: 60px;
	margin-bottom: 120px;
}

.floatButton {
	width: 100%;
	@extend .center-flex;
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999999;
	.cartButtonContainer {
		margin-top: 24px;
		margin-bottom: 24px;
		background-color: transparent;

		.addButton {
			width: 100%;
			background: $uv-blue !important;
			display: flex;
			align-items: center;
			justify-content: end;
			border: 1px solid $night;

			span {
				@extend .font-Neue-medium;
			}

			&:hover {
				background: $uv-blue !important;
			}
		}
	}
	@include for-phone-only {
		width: calc(100% + 30px);
		margin-left: -30px;
		bottom: 0px;
		background-color: $night;
		left: unset;
		transform: none;
		.cartButtonContainer {
			margin-top: 12px;
			margin-bottom: 14px;
			width: calc(100% - 120px);

			.addButton {
				justify-content: space-between;
				padding: 0px 24px !important;
				border-color: $uv-blue;
			}
		}
	}
}


