@import "@ph/common/styles/main.scss";

.statusContentWrapper {
	width: 900px;
	max-width: 100%;
	@include for-phone-only {
		width: 430px;
		margin-left: -50px;
	}
}

.statusContent {
	// padding: 40px 40px 50px 40px;
	background-color: $day;
	position: relative;

	@include for-both-phone-and-tablet {
		padding: 20px 20px 35px 20px;
		border-radius: 12px;
		margin-bottom: 18px;
	}

	.stepIcon {
		width: 39px;
		height: 49px;
		cursor: pointer;
		// position: relative;
		@include for-both-phone-and-tablet {
			width: 36px !important;
		}
	}
	.stepIcon2 {
		width: 38px;
	}
	.verticalDivider {
		background: $night;
		margin: 0;
		height: 18px;
		// top: 2.5px;
	}
	.horizontalDivider {
		background: $night;
		margin: 0 0 0 10px;
		width: 20px !important;
		min-width: 20px !important;
	}
	.colRight {
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: 30px;
		@include for-both-phone-and-tablet {
			gap: 40px;
			margin-top: 28px;
		}
	}
	.rowDivider {
		background: $sand;
		margin: 0;
		height: 1px;
	}
}

.horizontalStepKitFlow.ant-steps-icon {
	top: 3px;
}

$arrow-size: 8px;
$arrow-color: black;
$arrow-transition: all 0.3s ease-in;

.arrow {
	position: relative;
	top: 7px;
	// transform: rotate(180deg);

	&.arrowLeft {
		left: 7px;

		&:after {
			content: "";
			position: absolute;
			transition: $arrow-transition;

			border-top: $arrow-size solid transparent;
			border-bottom: $arrow-size solid transparent;
			border-right: $arrow-size solid transparent;
			border-left: $arrow-size solid $arrow-color;

			right: -8px;
			bottom: -16px;
		}
	}

	&.arrowRight {
		right: 7px;

		&:before {
			content: "";
			position: absolute;
			transition: $arrow-transition;

			border-top: $arrow-size solid transparent;
			border-bottom: $arrow-size solid transparent;
			border-right: $arrow-size solid $arrow-color;
			border-left: $arrow-size solid transparent;

			left: -8px; // Adjusted for right arrow
			bottom: -16px;
		}
	}
}

.outerContainer {
	@include for-phone-only {
		min-width: 350px;
        margin-top: 112px;
	}
}
