@import "../../../styles/main";

.slideContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
    margin-top: 18px;
    position: relative;
    width: 100%;
    @include for-phone-only {
        margin-top: 20px;
    }
    .valueText {
        @extend .font-NeueHGDP-light;
        font-style: normal;
        font-weight: 100;
        color: $primary-color;
        z-index: 100;
        font-size: 90px;
        @include for-phone-only {
            font-size: 100px;
        }
    }
    .NAtext {
        @extend .font-NeueHGDP-light;
        font-style: normal;
        font-weight: 100;
        color: $primary-color;
        z-index: 100;
        font-size: 70px;
        @include for-phone-only {
            font-size: 50px;
        }
    }
    .textContainer {
        position: relative;
        height: calc(#{$h5-line-height-mobile} + 10px);
        width: 100%;
        @include for-phone-only {
            height: calc(#{$h6-line-height-mobile} + 5px);
        }
    }
    .smallText {
        @extend .font-Mono-regular;
        @extend .note-1;
        color: $primary-color;
       
        // width: 61px;
        text-align: center;
        @include for-phone-only {
            width: fit-content;
        }
    }
    .hexagon {
        height: 110px;
        width: 110px;
        @include for-phone-only {
            height: 69px;
            width: 69px;
        }
    }
    .NAContainer {
        height: 110px;
        z-index: 99;
        width: 100%;
        background-image: linear-gradient(to right, #ffc3b8, $secondary-color, $sand, $crest);
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 24px;
        gap: 20px;
        border-radius: 12px;
        @include for-phone-only {
            height: 70px;
            gap: 10px;
            margin-top: 0;
            padding: 0 10px;
        }
    }
    .inputContainer {
        width: 100%;
        // margin: 20px 0;
        // border-radius: 12px;
        //background-image: linear-gradient(to right, #ffc3b8, $secondary-color, $sand, $crest);
        background-image: linear-gradient(109.77deg, rgba(213, 222, 245, 0.59) 11.2%, #ABBDEA 84.76%);

        padding: 0 30px;
        overflow: visible;
        @include for-phone-only {
            // margin: 10px 0;
            padding: 0 10px;
        }
        .track {
            background: url("../../../assets/images/PH_hydration_slider.svg");
            background-size: 100% 100px;
            background-repeat: no-repeat;
            background-position: center;
            outline: none;
            height: 110px;
            z-index: 99;
            width: 100%;
            @include for-phone-only {
                height: 70px;
                background: url("../../../assets/images/range_slider_mobile.svg");
                background-size: 100% 100px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .thumbContainer {
            // margin-top: -50px;
            position: relative;
            background-color: transparent;
            z-index: 20;
            margin-top: 0px;
            .thumbCheek {
                position: absolute;
                z-index: 200;
                cursor: pointer;
            }
            .thumbForehead {
                position: absolute;
                z-index: 300;
                cursor: pointer;
                // background: $bright-terra;
                // -webkit-clip-path: polygon(25% 50%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
                // clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
                // cursor: pointer;
                // z-index: 999;
                // width: 90px;
                // height: 90px;
                // display: flex;
                // align-items: center;
                // justify-content: center;
            }
        }
    }
}