@import "../../styles/main";

.button {
    opacity: 0;
    width: fit-content;
    height: 60px;
    border-radius: 12px;
    font-size: $button-text;
    line-height: $button-text;
    padding: 4px 30px;
    @include for-both-phone-and-tablet {
        height: 40px;
        border-radius: 4px;
        font-size: $button-text-mobile;
        line-height: $button-text-mobile;
    }
}
.healthProfileButton {
    @include for-both-phone-and-tablet {
        width: 50% !important;
        height: 40px !important;
        background: inherit !important;
        border: 1px solid $night !important;
        border-radius: 8px !important;
        padding: 0 !important;

        .subtitle {
            color: $night;
            margin-left: 0;
        }
    }
}
.viewReportButton {
    width: 50% !important;
    height: 40px !important;
    border-radius: 8px !important;
    padding: 0 !important;
    @include for-small-phone-only {
        width: 50% !important;
    }
}
.kitContent {
    // padding: 30px 40px 40px;
    // display: flex;
    // flex-direction: column;
    // border-radius: 12px;
    // border: 1px solid $day;
    // overflow: hidden;
    // gap: 40px;
    @include for-both-phone-and-tablet {
        padding: 0 0 25px;
    }
    :global(.ant-collapse-header) {
        padding: 30px 40px 30px 16px !important;
        @include for-both-phone-and-tablet {
            padding: 30px 40px 25px 16px !important;
        }
    }
    .title {
        font-family: "Neue Haas Grotesk Display Pro", sans-serif !important;
        font-style: normal;
        font-weight: 100;
        font-size: $h4-text;
        color: $primary-color;
        line-height: $h4-line-height;
        // @include for-both-phone-and-tablet {
        //     font-size: 30px;
        //     line-height: 36px;
        // }
        @include for-both-phone-and-tablet {
            font-size: $h4-text-mobile;
            line-height: $h4-line-height-mobile;
        }
    }
    .bodyText {
        font-weight: 500;
        font-size: $h4-text;
        line-height: $h4-line-height;
        letter-spacing: 0.05em;
        color: $day;
        @include for-both-phone-and-tablet {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .kitDate {
        @include for-both-phone-and-tablet {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .skinHealthProfile {
        color: $night;
        border-bottom: 1px solid $night;
    }
    .subtitle {
        font-size: $h5-text;
        line-height: $h5-line-height;
        letter-spacing: 0.05em;
        color: $primary-color;
        @include for-both-phone-and-tablet {
            font-size: $h5-text-mobile;
            line-height: $h5-line-height-mobile;
        }
        // @include for-medium-phone-only {
        //     font-size: $h6-text-mobile;
        //     line-height: 12px;
        // }
        // @include for-small-phone-only {
        //     font-size: $h6-text-mobile;
        //     line-height: 12px;
        // }
    }
    :global(.ant-collapse-content-box) {
        padding-top: 40px !important;
        @include for-both-phone-and-tablet {
            padding: 20px 0 !important;
        }
    }
    .showSectionMobile {
        display: none !important;
        @include for-both-phone-and-tablet {
            display: block !important;
        }
    }
    .reportHeaderSection2 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        @include for-both-phone-and-tablet {
            justify-content: space-between;
            padding: 0 20px;
            width: 100%;
            gap: 12px;
        }
        .checkIcon {
            color: $night;
            font-size: 16px;
            line-height: 20px;
            @include for-both-phone-and-tablet {
                position: absolute;
                left: -5px;
                top: -7px;
                line-height: 0;
                background-color: #fff;
            }
        }
    }

    .reportContent {
        .colLeft {
            display: flex;
            align-items: center;
            gap: 20px;
            @include for-medium-phone-only {
                gap: 10px;
            }
            @include for-small-phone-only {
                gap: 10px;
            }
            .productImage {
                // width: 250px;
                width: 50%;
                border-radius: 4px;

                @include for-both-phone-and-tablet {
                    height: 100%;
                }
            }
            .productDesc {
                display: flex;
                flex-direction: column;
                gap: 10px;
                @include for-both-phone-and-tablet {
                    gap: 6px;
                }
            }
        }
        .colRight {
            //padding-left: 10px;
            @include for-both-phone-and-tablet {
                padding-left: 0;
                padding-top: 10px;
            }
        }
        .reportSubRow {
            margin-top: 50px;
            margin-bottom: 50px;
            @include for-both-phone-and-tablet {
                margin: 25px 0;
            }
            &:last-child {
                @include for-both-phone-and-tablet {
                    margin: 0 !important;
                }
            }
        }
        .reportData {
            border: 1px solid #2d2a26;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 25px 45px 20px 45px;
            @media (min-width: 1600px) {
                margin-right: 60px;
            }
            height: 100%;
            @include for-both-phone-and-tablet {
                border: 0;
                gap: 10px;
                padding: 0;
                margin-right: 0;
                margin-bottom: 20px;
                border-radius: 0;
            }
            :global .ant-row .ant-col {
                @include for-both-phone-and-tablet {
                    border: 1px solid $sand;
                    border-right: 0;
                    padding: 20px 25px;
                    margin: 0 !important;
                    &:nth-child(n+3) {
                        border-top: 0 !important;
                    }
                }
            }
            .title {
                padding-top: 5px;
                display: inline-block;
            }
            .hydrationLevels {
                width: 100%;
                @include for-both-phone-and-tablet {
                    width: 85%;
                }
                div > span {
                    @include for-both-phone-and-tablet {
                        display: inline-block;
                        padding-left: 15px;
                    }
                }
            }
            .subtitle {
                @extend .body-txt-lg;
                font-weight: 500;
            }
            small {
                @extend .body-txt-lg;
                font-weight: 500;
                position: relative;
                top: -3px;
                padding-right: 4px;
                @include for-both-phone-and-tablet {
                    display: block;
                }
            }
            .sectionSeparator {
                height: 1px;
                width: 100%;
                background: $night;
                @include for-both-phone-and-tablet {
                    height: 0;
                }
            }
        }
    }
}