.bg-bright-terra {
  background-color: $bright-terra;
}

.bg-infrared-20 {
  background-color: $infrared-20;
}
.bg-uv-blue-40 {
  background-color: $uv-blue-40;
}
.bg-uv-blue {
  background-color: $uv-blue;
}
.bg-white {
  background-color: $white;
}
.bg-stone {
  background-color: $stone;
}

.bg-crest {
  background-color: $crest;
}
.bg-night {
  background-color: $night;
}
.bg-walnut {
  background-color: $walnut;
}
.bg-day {
  background-color: $day;
}
.bg-sand {
  background-color: $stone;
}
.bg-transperant {
  background-color: transparent;
}

.bg-bright-terra-opaque {
  @include background-opacity($bright-terra, 0.6);
}

.bg-crest-opaque {
  @include background-opacity($crest, 0.6);
}

.font-quincy {
  font-family: "Quincy CF", sans-serif !important;
}

.font-PP-italic {
  font-family: 'PPEditorialOld Italic' !important;
}
.font-PP-light-italic {
  font-family: 'PPEditorialOld UltralightItalic' !important;
}

.font-PP-bold-italic {
  font-family: 'PPEditorialOld UltraboldItalic' !important;
}

.font-NeueHGDP {
  font-family: "Neue Haas Grotesk Display Pro", sans-serif !important;
}

.font-Neue-medium {
  font-family: "Neue Haas Grotesk Medium" !important;
}

.font-Neue-bold {
  font-family: "PPNeueMontrealMono Bold" !important;
}

.font-NeueHGDP-light {
  font-family: "Neue Haas Grotesk Light", sans-serif !important;
}

.font-PPNeueMontrealMono {
  font-family: "PPNeueMontrealMono Book" !important;
}

.font-Mono-regular {
  font-family: "PPNeueMontrealMono Book" !important;
}

.font-Mono-medium {
  font-family: 'PPNeueMontrealMono Medium' !important;
}

.font-Mono-bold {
  font-family: 'PPNeueMontrealMono Bold' !important;
}

* {
  @extend .font-NeueHGDP;
}

h1, .h1 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('h1');
}

h2, .h2 {
  @extend .font-quincy;
  @include getFontSizeMapFor('h2');
}

h3, .h3 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('h3');
}

h4, .h4 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('h4');
}

h5, .h5 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('h5');
}
h6, .h6 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('h6');
}


.body-txt-lg {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('body-lg');
}

.body-txt-md {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('body-md');
}

.note-1 {
  @extend .font-PPNeueMontrealMono;
  @include getFontSizeMapFor('note-1');
}

.note-2 {
  @extend .font-PPNeueMontrealMono;
  @include getFontSizeMapFor('note-2');
}

.quiz-1 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('quiz-1');
}

.quiz-2 {
  @extend .font-NeueHGDP;
  @include getFontSizeMapFor('quiz-2');
}

.note-3 {
  @extend .font-PPNeueMontrealMono;
  @include getFontSizeMapFor('note-3');
}

.hide, .hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.text-left {
  text-align: left !important;
}
.font-normal {
  font-style: normal;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.text-light {
  color: $white !important;
}

.text-uv-blue {
  color: $uv-blue !important;
}

.text-dark {
  color: $night !important;
}

.text-bright-terra {
  color: $night;
}

.text-walnut {
  color: $walnut !important;
}

.text-error {
  color: $errorText !important;
}

.full-width {
  width: 100% !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.clearfix, .cf, .cb {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.float-left, .fl {
  float: left !important;
}

.float-right, .fr {
  float: right !important;
}

.float-none {
  float: none !important;
}

.td-underline {
  text-decoration: underline !important;
}

.item-stretch {
  align-items: stretch;
}

.hand-cursor {
  cursor: pointer;
}

.show-only-on-desktop {
  @include for-both-phone-and-tablet {
    display: none !important;
  }
}
.show-desktop-hide-mobile {
  @include for-both-phone-and-tablet {
    display: none !important;
  }
}
.show-mobile-hide-desktop {
  @include for-both-phone-and-tablet-up{
    display: none !important;
  }
}
.show-mobile-only {
  @include for-phone-up {
    display: none !important;
  }
}

.show-desktop-tab-hide-mobile {
  @include for-phone-only {
    display: none !important;
  }
}

.hide-on-desktop {
  @include for-desktop-up {
    display: none !important;
  }
}
.medium-text {
  font-weight: 600;
}
.bold-text {
  font-weight: 700;
}
.border-dark {
  border: 2px solid $night;
  border-radius: 8px;
}
.no-padding-column {
  padding: 0 !important;
}

.center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-none {
  display: none !important;
}

.border-2 {
  border: 2px solid $night;
  @include for-phone-only {
    border: 1px solid $night;
  }
}

.border-bottom-2 {
  border-bottom: 2px solid $night;
  @include for-phone-only {
    border-bottom: 1px solid $night;
  }
}