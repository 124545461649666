@import "@ph/common/styles/main";

:global .ant-table-body {
  min-height: 450px;
  background-color: $day;
  border-bottom: 2px solid #cec3c1;
}
.tableHeadCell {
  display: flex;
    width: 100%;
    height: 40px;
    // background-color: rgb(252, 211, 77);
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.dropButton {
  background-color: $day;
  border: 1px solid $night;
  width: 100%;
  height: 40px;
  text-align: left;
  margin-bottom: 18px;
}
// :global .ant-table-empty .ant-table-body {
//   overflow-y: hidden !important;
// }