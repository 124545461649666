@import "@ph/common/styles/main";

.QueButtonsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
        padding-top: 10px;
    }
    
    .QueButton {
        // border-radius: 5px !important;
        margin: 5px;
        display: flex;
        align-items: center;
        padding: 10px 24px;
        height: unset;
        @extend .body-txt-lg;

        @include for-phone-only {
            text-transform: capitalize;
            img {
                display: none;
            }
        }

        :global(.anticon-loading){
            display: flex;
            align-items: center;
        }

        &.prev {
            background-color: #F5F1F0 !important;
            &:disabled {
                background-color: #BAB9B8 !important;
            }
        }
        &.next {
            span{
            @extend .body-txt-lg;

            }
            &:disabled {
                color: $night !important;
                background-color: #BAB9B8 !important;

                path {
                    fill: $night;
                }
            }
        }
        &.final {
            background: linear-gradient(90deg, #2E5ACC 42%, #C46A5A 100%) !important;
        }
    }
}