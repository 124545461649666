@import "../../../styles/main";

.box {
  background: $stone;
  margin: 50px 0px 50px;
  border-radius: 12px;
  @include for-phone-only {
    margin: 45px 0;
    border-radius: 4px;
  }

  .headingContainer {
    background: $uv-blue;
    text-align: center;
    padding: 28px 25px;
    max-height: 120px;
    width: 100%;
    clear: both;
    position: relative;
    @include for-phone-only {
      padding: 24px 10px;
    }
    .heading {
      @extend .h4;
      color: $secondary-color;
      @include for-phone-only {
        display: inline-block;
        max-width: 90%;
      }
    }
    .iconContainer {
      display: inline-block;
      font-size: 30px;
      float: right;
      margin-top: -5px;
      //padding-right: 30px;
      //position: absolute;
      //right: 0;
      @include for-phone-only {
        font-size: 18px;
        margin-top: -2px;
        //padding-right: 20px;
      }
    }
  }

  .headingContainer.stone {
    background: $uv-blue;
  }

  .headingContainer.sand {
    background: $uv-blue;
  }

  .bodyContainer {
    background: inherit;
    // border-radius: inherit;
  }
}

.box.darkBackGround {
  background: $stone;
  // @include for-phone-only {
  //   background: transparent;
  // }
}