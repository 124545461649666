@mixin for-small-phone-only {
  @media (max-width: $screen-xs-min) {
    @content;
  }
}

@mixin for-phone-width-297 {
  @media (max-width: 297px) {
    @content;
  }
}

@mixin for-medium-phone-only {
  @media (max-width: $screen-xs-max) and (min-width: $screen-xs-min) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin for-phone-up {
  @media (min-width: $screen-sm-max) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @content;
  }
}

@mixin for-both-phone-and-tablet {
  @media (max-width: $screen-lg-max) {
    @content;
  }
}
@mixin for-both-phone-and-tablet-up {
  @media (min-width: $screen-lg-max) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $screen-xl-min) {
    @content;
  }
}
@mixin for-big-desktop-up {
  // @media (min-width: $screen-xl-min) {
  //     @content;
  // }
  @media (min-width: 1990px) {
    @content;
  }
}

@mixin for-screen-1367-1199 {
  @media (max-width: 1367px) and (min-width: $screen-lg-max) {
    @content;
  }
}

@mixin for-screen-height-800 {
  @media (max-height: 800px) {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.6) {
  background: $color !important; /* The Fallback */
  background: rgba($color, $opacity) !important;
}

@mixin clipTextToNLines($lines-to-show) {
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-show;
  line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin getFontSizeMapFor($el) {
  font-size: map-get($fontSizeMaps, $el);
  line-height: map-get($fontSizeMaps, #{$el}-line-height);
  @include for-phone-only {
    font-size: map-get($fontSizeMaps, #{$el}-mobile);
    line-height: map-get($fontSizeMaps, #{$el}-mobile-line-height);
  }
}