@import "../../../styles/main";

.box {
  background: $stone;
  color: $uv-blue;
  min-height: 260px;
  min-width: 100%;
  padding: 8px 6px 0px 6px;
  border-radius: 0px;
  cursor: default;

  :global(.ant-card-body){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &::before,
      &::after {
        content: ''; 
        display: none;
    }
  }
  @include for-both-phone-and-tablet {
    min-height: 180px;
    padding: 0px 0px 0px 0px;
  }
    .titleContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      // margin-bottom: 70px; 

      @include for-both-phone-and-tablet {
        // min-height: 40px;
      }
    }
    .navTitle {
        @extend .h4;
        font-family: "Neue Haas Grotesk Display Pro", serif;
        @include for-both-phone-and-tablet {
          font-size: $h3-text-mobile;
          line-height: 32px;
        }

    }
    .navImage img {
      width: 95px;
      @include for-phone-only {
        width: 68px;
      }
    }
    .commingSoon {
      @extend .note-1;
      @extend .font-Mono-medium;
      position: absolute;
      right: 0;
      top: 4px;
    }
    
    .disableImg img{
      opacity: 0.5;
    }
    .navSubTitle {
        min-height: 48px;
        @extend .font-Mono-medium;
        @extend .note-1;
        @include for-phone-only {
            min-height: 28px;
        }
    }   
}

.navHover {
  cursor: pointer !important;
  background: $stone;
  color: $uv-blue;
  &:hover, &.active {
    background: $uv-blue;
    color: $day;           
  }
}

.disableText {
  opacity: 0.5;
}
