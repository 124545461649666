@import "../../../styles/main";

.darkHeader {
    background: $primary-color !important;
    .sidebarToggleButton {
        color: $secondary-color !important;
    }
    .headerTabContainer {
        .headerTab {
            .headerTabLink {
                .text {
                    color: $secondary-color !important;
                }
            }
        }
    }
    .buttonSection {
        .space {
            .verticalSeparator {
                background: $secondary-color !important;
            }
        }
    }
}

.stickyHeader {
    position: fixed !important;
    top: 0;
    width: 100%;
    box-shadow: #2a2d2633 0px 8px 24px;
    -webkit-box-shadow: #2a2d2633 0px 8px 24px;
    -moz-box-shadow: #2a2d2633 0px 8px 24px;

    // // left: 0;
    // width: 100%;
}

.headerContainer {
    background: $secondary-color;
    height: $header-md-height !important;
    position: absolute;
    top: 0;
    width: 100%;
    // position: sticky;
    // top: 0;
    // width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: #2a2d2633 0px 8px 24px;
    -webkit-box-shadow: #2a2d2633 0px 8px 24px;
    -moz-box-shadow: #2a2d2633 0px 8px 24px;
    @include for-phone-only {
        height: $header-xs-height !important;
        display: block;
    }
    .sidebarToggleButton {
        border: none;
        color: $primary-color;
        outline: none;
        background: inherit;
        z-index: 1000;
        width: fit-content;
        padding: 0;
    }
    // @include for-both-phone-and-tablet {
    //     padding: 0 20px;
    // }
    .logoSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 1rem;
        @include for-phone-only {
            padding-right: 0;
        }
        img {
            width: 150px;
            height: auto;
            content: url("../../../assets/images/PH_logo_light_beta.svg");
            @include for-phone-only {
                // width: 106px;
                // height: calc(#{$header-xs-height} - 33px);
                content: url("../../../assets/images/PH_beta_logo_dark.svg");
                margin-left: 1.5rem;
            }
        }
    }
    .headerTabContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // margin: 20px 0;
        .headerTab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            // gap: 20px;
            gap: 2vw;
            .headerTabLink {
                // margin: 0 10px;
                .text {
                    color: $primary-color;
                    // font-size: 16px !important;
                    font-size: $h5-text !important;
                    font-weight: 600 !important;
                    line-height: $h5-line-height !important;
                    white-space: pre;
                    letter-spacing: 0.05em !important;
                }
                // @include for-phone-only {
                //     margin: 0 6px;
                // }
            }
        }
    }
    .buttonSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }
    .space {
        gap: 1.5rem !important;
        @media (max-width: 820px) {
            gap: 1rem !important;
        }
    }
    .linkText {
        color: $primary-color !important;
        white-space: pre;
        font-size: 12px !important;
        &:hover {
            // transform: scale(1.1);
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .active {
        color: $bright-terra !important;
        font-family: Medium !important;
    }
    .verticalSeparator {
        width: 1px;
        height: 35px;
        background: $primary-color;
        margin-right: 0.6rem;
        @media (max-width: 820px) {
            margin-right: 0;
        }
    }
    .cartIcon {
        height: auto;
        width: 2.9rem;
        cursor: pointer;
        @include for-phone-only {
            height: $header-xs-height;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
    .icon {
        // height: 1.4rem;
        // width: 1.4rem;
        height: 1.3rem;
        width: 1.3rem;
        @include for-phone-only {
            height: $header-xs-height;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}
