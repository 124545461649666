@import "../../styles/main";
.container {
    margin: 60px 30px;
    // display: grid;
    // align-content: space-between;
    // justify-content: center;
    width: 100%;
    @include for-phone-only {
        margin: 18px;
    }
    .navSection {
        width: 100%;
        text-align: left;
    }
}
