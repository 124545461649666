@import "@ph/common/styles/main";

.sliderContainer {
	width: 100%;
	max-width: 1440px;
	padding: 0px 10%;

	@include for-phone-only {
		transform: rotate(270deg);
		width: 600px;
		padding: 0px 5%;
		margin-top: 180px;
		margin-left: -18px;
	}
}

.slider {
	position: relative;
	// height: 20px;
	margin-bottom: 10px;
	height: 48px;
	border-radius: 40px;
	overflow: hidden;
	border: 2px solid $night;
	@include for-phone-only {
		transform: rotate(180deg);
		height: 40px;
		border: 1px solid $night;
	}
	@media (min-width: 1990px){
		border: 2.5px solid $night;
	}
}

.sliderGradient {
	width: 0%;
	position: absolute;
	top: 0;
	// left: 2px;
	height: 100%;
	border-radius: 40px;
	z-index: 1;
	transition: width 0.2s ease;
}

.sliderDots {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	display: flex;
	justify-content: space-between;
	z-index: 2;
	padding: 0px 10px;

	@include for-phone-only {
		padding: 0px 36px;
	}
}
.sliderDotsContainer {
	height: 100%;
	width: calc(100% + 4px);
	border-radius: 40px;
	position: relative;
	@include for-phone-only {
		width: calc(100% + 2px);
	}
}
.sliderDotWrap {
	width: 100%;
	height: 48px;
	cursor: pointer;
	@extend .center-flex;
	.sliderDot {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: $night;
		z-index: 4;
		@include for-phone-only {
			width: 6px;
			height: 6px;
		}
	}
}

.active {
	background-color: unset;
}

.sliderLabels {
	display: flex;
	justify-content: space-between;
	color: #333;
	margin-top: 60px;
	@extend .h3;
	padding: 0px 40px;
	.right {
		text-align: right;
	}

	@include for-phone-only {
		flex-direction: row-reverse;
		margin-top: 16px;
		padding: 0px 40px;
		min-height: 170px;
		.left,
		.middle,
		.right {
			transform: rotate(90deg);
		}

		.middle {
			position: relative;
			left: -40px;
			top: -70px;
		}
		.right {
			left: unset;
			position: relative;
			right: 100px;
			top: -26px;
		}
		.left {
			position: relative;
			top: -16px;
			left: -25px;
		}
	}
}

.arrowContainer {
	display: flex;
	justify-content: center;
	margin-top: 5px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	@include for-phone-only {
		position: absolute;
		top: 0;
		left: -8px;
		width: 100px;
		transform: rotate(90deg);
	}
}

.sliderText {
	display: flex;
	justify-content: space-between;
	color: $night;
	margin-top: 5px; // Space between labels and text
	@extend .quiz-2;
	span {
		width: 220px;
		text-align: center;
	}
	// .left,.right {
	//     position: absolute;
	// }
	.leftText {
		margin-left: -80px;
	}
	.rightText {
		margin-right: -80px;
	}
	@include for-phone-only {
		display: inline-flex;
		position: relative;
		left: 78px;
		top: -12px;
		font-size: $body-text-mobile-lg;
		span {
			width: 170px;
			text-align: left;
		}
		&.rightSide {
			left: 16px;
		}
		.leftText {
			margin-left: -62px;
		}
	}
}

.longArrowRight,
.longArrowLeft {
	display: block;
	margin: 27px auto;
	width: 18px;
	height: 18px;
	border-top: 2px solid #000;
	border-left: 2px solid #000;
}
.longArrowRight {
	transform: rotate(135deg);
}

.longArrowLeft {
	transform: rotate(-45deg);
	margin-right: 47px;
}
.longArrowRight::after,
.longArrowLeft::after {
	content: "";
	display: block;
	width: 2px;
	height: 44.5px;
	background-color: black;
	transform: rotate(-45deg) translate(15px, 4px);
	left: 0;
	top: 0;
}
