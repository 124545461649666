@import "../../../styles/main";

.miniProtocolContainer {
    background-color: $uv-blue;
    padding: 60px 55px;
    // margin-bottom: -76px;
    color: $white;
    @include for-both-phone-and-tablet {
        padding: 28px 24px;
    }
    .titleContainer {
        margin-bottom: 30px;
    }
    .miniProduct {
        margin-top: 12px;
        @include for-both-phone-and-tablet {
            margin-top: 0px;
        }
        .title {
            @extend .body-txt-lg;
            @extend .font-Neue-medium;
        }
        .description {
            @extend .body-txt-lg;
        }
        .divider {
            border-block-start: 1px solid $white;
        }
        
        .priceContainer {
            text-align: end;
            
            .price,.discountPrice {
                display: inline;
                @extend .body-txt-lg;
                @extend .font-Neue-medium;
            }
            .discountPrice {
                margin-right: 8px;
                color: $uv-blue-40;
                text-decoration: line-through;      
                text-transform: uppercase;
            }
            @include for-both-phone-and-tablet {
                justify-content: end;
            }
        }
    }
    .banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left top;
            @include for-both-phone-and-tablet {
            padding-bottom: 24px;
            }
        }
    .totalcontainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .totalText,.totalPrice {
            @extend .body-txt-lg;
            @extend .font-Neue-medium;
        }
        
        
    }
    .buttonContainer {
        margin-top: 20px;
        @include for-both-phone-and-tablet {
            text-align: center;
        }
    }

    .hideMobile {
        @include for-both-phone-and-tablet {
            display: none;
        }
    }
    .hideDesktop {
        display: none;
        @include for-both-phone-and-tablet {
            display: flex;
        }
    }
}