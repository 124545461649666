@import "../../../styles/main";

.textContainer {
  flex-wrap: wrap;
  display: flex;
  position: relative;
  height: calc(#{$h5-line-height-mobile} + 10px);
  width: 100%;
  margin-top: 30px;
  @include for-both-phone-and-tablet {
    margin-top: 20px;
    height: calc(#{$h6-line-height-mobile} + 5px);
  }

  .smallText {
    @extend .font-Mono-regular;
    @extend .note-1;
    flex-grow: 1;
    width: 50%;
    padding: 0 10px;
    @include for-phone-only {
      font-size: $note-2-text-mobile;
      line-height: $note-2-line-height-mobile;
      width: fit-content;
    }
  }
}

$baseContainerHeight: 120px;
$baseContainerHeight-Mobile: 80px;

$sliderHeight: 100px;
$sliderHeight-Mobile: 40px;

$thumbWidth: 100px;
$thumbHeight: 120px;

$thumbWidth-Mobile: 60px;
$thumbHeight-Mobile: 70px;

.skinMicrobiomeSliderContainer {
  width: 100%;
  background-image: linear-gradient(109.77deg, rgba(213, 222, 245, 0.59) 11.2%, #ABBDEA 84.76%);
  padding: 0 30px;
  margin-bottom: 80px;
  overflow: visible;
  position: relative;
  height: $baseContainerHeight;

  @include for-both-phone-and-tablet {
    height: $baseContainerHeight-Mobile;
    padding: 0 10px;
    margin-bottom: 35px;
  }
}

:global {
  #skinMicrobiomeSlider {
    height: $sliderHeight;
    position: relative;
    top: 10px;
    box-shadow: none;
    border: none;
    background: transparent url("../../../assets/images/range_slider.svg") no-repeat 0 center;
    background-size: 100%;

    @include for-both-phone-and-tablet {
      background-image: url("../../../assets/images/range_slider_mobile.svg");
      height: $sliderHeight-Mobile;
      top: 20px;
    }

    .noUi-connects, .noUi-marker {
      background: transparent;
    }

    .noUi-pips-horizontal, .noUi-value-horizontal {
      display: none;
    }

    .noUi-handle {
      background-image: url("../../../assets/images/range_thumb.svg");
      background-repeat: no-repeat;
      background-position: initial;
      background-size: 100px auto;
      background-color: transparent;
      width: $thumbWidth;
      height: $thumbHeight;
      border: 0;
      cursor: default;
      box-shadow: none;
      top: 5px;

      @include for-both-phone-and-tablet {
        background-size: 60px auto;
        width: $thumbWidth-Mobile;
        height: $thumbHeight-Mobile;
        top: -7px;
      }
    }

    .noUi-handle:after,
    .noUi-handle:before {
      display: none;
    }

    .noUi-tooltip {
      font-family: "Neue Haas Grotesk Light", sans-serif !important;
      bottom: auto;
      border: 0;
      background: none;
      color: $primary-color;
      font-size: $big-text;
      line-height: $big-text-line-height;
      font-weight: 100;
      transform: translate(-50%, 0);
      left: 50%;
      letter-spacing: -2px;
      @include for-both-phone-and-tablet {
        letter-spacing: -1px;
        font-size: $h1-text-mobile;
        line-height: $h1-line-height-mobile;
      }
    }
  }
}