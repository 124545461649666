@import "../../../styles/main";

.container {
	width: 100%;
}
.tagContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	// overflow: hidden;
	transition: height 2s ease-in-out;
	&.vertical {
		flex-direction: column;
		gap: 8px;
        
	}
}

.tag {
	background-color: $night;
	color: $white;
	padding: 6px 12px;
	border-radius: 42px;
	margin-right: 8px;
	margin-bottom: 8px;
	@extend .note-2;
	@extend .font-Mono-medium;
	text-transform: uppercase;
	width: fit-content;
	white-space: nowrap;
	transition: all 2s;

}

.moreTags {
	// padding: 6px 4px;
	// border-radius: 16px;
	@extend .note-2;
	@extend .font-Mono-medium;
	text-transform: uppercase;
	white-space: nowrap;
	cursor: pointer;
}

.popOver {
	
    :global(.ant-popover-inner){
        border-radius: 0px;
        @extend .border-2;
    }
}
