@import "@ph/common/styles/main";

.jotformContainer {
    width: 100vw;
    overflow: hidden;
    min-height: 100vh;
    // height: fit-content;
    // form {
    //     height: 100vh !important;
    //     overflow-y: auto;
    // }
    iframe {
        height: 100vh !important;
        position: absolute;
        width: 100% !important;
        top: 0;
        border: none;
    }
}

:global .jot-form-full-height + footer {
    display: none !important;
}