@import "@ph/common/styles/main.scss";

.buttonOptionsContainer {
	outline: 1px solid $night;
    align-items: stretch;
	// background: linear-gradient(
	// 	101.47deg,
	// 	#eaffa6 4.16%,
	// 	#f1ddff 42.11%,
	// 	#f1ddff 61.04%,
	// 	#25a18e 96.65%
	// );
	background: var(--desktop-bg);
	margin: 0px 10%;
	margin-bottom: 18px;
	
	@include for-phone-only {
		background: var(--mobile-bg);
		margin: 0px 8px;
		// border-bottom: 1px solid $night;
	}
	.gridCell {
		border: 1px solid $night;
		padding: 26px 20px;
		padding-left: 22px;
		@extend .center-flex;
		@extend .quiz-2;
		background: $white;
		cursor: pointer;
        height: 100%;
		transition: background 0.1s ease, border-left 0.1s ease,
		padding-left 0.1s ease;
        text-align: center;

		&.selected {
			background: transparent;
			border-left: 8px solid $night;
			padding-left: 14px;
		}
	
		@include for-phone-only {
			padding: 20px 16px;

			// &.other {
			// 	border-bottom: 2px solid $night;
			// }
		}
	}

	.otherInput {
		background: transparent;
		border: none;
		box-shadow: none;
        border-radius: 0px;
        width: 80%;
		@extend .quiz-2;
        max-height: 28px;
        text-align: center;
		:global(.ant-input-clear-icon){
			svg {
				color: $night;
				font-size: 18px;
			}
		}
		input {
			@extend .quiz-2;
			text-align: center;
			background: transparent;
			&:focus {
				border: none;
				box-shadow: none;
				color: $night;
				border-bottom: 2px solid $night;
				:global(.ant-input-clear-icon){
					visibility: visible;
				}
        	}

		}
		
        
	}
}
