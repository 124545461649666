@import "@ph/common/styles/main";

.topSectionWrapper {
  margin: 12px 0 20px;
}

.title {
  margin-bottom: 20px !important;
}

.subTitleBorder {
  border-right: 2px solid $night;
  padding-right: 10px;
  margin-right: 10px;
}

.subTitle {
  top: -5px;
  position: relative;
}

.searchAndTimeFilterContainer {
  display: flex;
  gap: 20px;

  .searchInput {
    flex-grow: 1;
    width: auto;
  }
}