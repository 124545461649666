@import "@ph/common/styles/main";

:global .ant-modal-content {
  background-color: $day !important;
  border-radius: 12px;
}

.pcrLayoutContainer {
  width: 100%;
  height: 100%;
  background-color: $sand;
  border-radius: 12px;
}

// .profilePicContainer {
//   // pointer-events: none;
  
// }
.profileText {
  font-size: 32px;
}


.kitCell {
  width: 100%; 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.controls{
    background-color: #6D3628;
  }

  @each $key,$value in $kitCellColors {
    &.#{$key}{
      background-color: $value;
    }
  } 
}

.searchInput {
  margin-top: 8px;
  margin-bottom: 8px;
}