@import "../../../styles/main";

.phTag {
    padding: 6px 16px;
    @extend .note-3;
    @extend .font-Mono-medium;
    text-transform: uppercase;
    border-radius: 42px;
    margin-inline-end: 0px;
    svg {
        width: 24px;
        margin-right: 6px;
        position: relative;
        top: 2px;
    }
    span {
        @extend .note-3;
        @extend .font-Mono-medium;
        text-transform: uppercase;
    }
    @include for-phone-only{
        padding: 4px 12px;
    }
}