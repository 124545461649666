@import "@ph/common/styles/main";

:global {
  .expanded-row td {
    border-bottom: 0 !important;
  }
}

.expandedRow {
  background-color: $night;
  border-radius: 8px;
  padding: 15px;
}

.expandedRowArrow {
  /* Used to position the arrow */
  position: relative;

  .expandedRowArrowTR {
    /* Size */
    height: 1rem;
    width: 1rem;

    background-color: #2d2a26;
    position: absolute;

    /* Position at the top right corner */
    right: 1rem;
    top: -15px;

    /* Border */
    border-left: 1px solid #2d2a26;
    border-top: 1px solid #2d2a26;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.closeIcon {
  width: 20px;
}

.lightText {
  color: $day;
}

.darkText {
  color: $night;
}

:global {
  .ant-form-item {
    padding: 0 10px !important;

    textarea {
      background: $stone;
      border-radius: 12px;
      padding: 10px;
    }
  }
}

$sampleColWidth: calc(25% - 14px);
.validateFormContainer {
  gap: 16px;
  padding-left: 10px;
  :global {
    .ant-col {
      background-color: #494642;
      border-radius: 8px;
      padding: 20px 20px;
    }
  }
  .label {
    @extend .lightText;
    font-weight: 600;
    display: block;
    margin-bottom: 10px !important;
  }

  .datepickerContainer {
    padding: 0 !important;

    :global .ant-form-item-control {
      padding: 40px 0 !important;
    }

    :global .ant-picker {
      border-radius: 8px;
      height: 40px;
      width: 100%;
    }
  }

  .helpText {
    @extend .lightText;
  }

  .commonColStyle {
    background-color: transparent;
    border: 2px solid $day;
    font-weight: bold;
    text-align: center;
  }

  .commonSelectedColStyle {
    background-color: $crest;
    border: 2px solid transparent;
  }

  .samplesContainer {
    gap: 16px;
    :global(.ant-col) {
      @extend .commonColStyle;
      cursor: pointer;
      flex: 0 0 $sampleColWidth;
      width: $sampleColWidth;
      padding: 10px 14px;

      :local .sampleLabel {
        @extend .lightText;
        display: inline-block;
        margin-bottom: 35px !important;
      }

      img {
        width: 25px;
        display: block;
        margin: 0 auto;
      }
    }

    :global(.ant-col).selected {
      @extend .commonSelectedColStyle;

      :local .sampleLabel {
        @extend .darkText;
      }
    }
  }

  .oilStripContainer {
    :global .ant-col {
      height: 54px;
      padding: 0 10px;
    }

    .labelContainer {
      @extend .commonColStyle;
      padding: 12px 10px;

      .stripLabel {
        @extend .lightText;
      }
    }

    .labelContainer.selected {
      @extend .commonSelectedColStyle;

      .stripLabel {
        @extend .darkText;
      }
    }

    .oilStripImageActionContainer {
      padding-left: 10px;
      display: inline-block;
    }

    .imgPreviewContainer {
      position: relative;
      display: inline-block;

      .previewIcon {
        padding-right: 10px;
      }

      .deleteOilStripImageIcon {
        cursor: pointer;
        position: absolute;
        top: -15px;
        right: 0;
      }
    }
  }
}
.validationStatusEmailContainer {
  gap: 16px;
  padding: 25px 10px;
}
.validationStatusMsgContainer {
  gap: 16px;
  padding: 25px 10px;

  :global(.ant-col) {
    flex: 0 0 $sampleColWidth;
    width: $sampleColWidth;
  }
}

.modalContent {
  padding: 10px 0;

  .switchContainer {
    padding-bottom: 10px;
  }
}

.confirmationActionBtnContainer {
  text-align: center;
  margin: 30px auto 30px;

  :global .ph-btn:first-child {
    margin-right: 20px;
  }
}

.datepickerContainer {
  padding: 0 !important;

  :global .ant-form-item-control {
    padding: 40px 0 !important;
  }

  :global .ant-picker,
  :global .ant-input {
    border-radius: 8px;
    height: 40px;
    width: 100%;
  }
  :global .ant-input[disabled]{
    color: $night;
    background-color: $sand;
  }
}

.lineSeperator {
  border: 1px solid $day;
  height: 100% !important;
}
.invisibleSeperator {
  border: 1px solid transparent;
  height: 100% !important;
}

.reportButton {
  border: 1px solid $white;
  background-color: $night;
  color: $white;
}