@import "../../../styles/main";

.slideContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	overflow: visible;
	margin-top: 54px;
	position: relative;
	width: 100%;
	min-height: 530px;
	
	&.smallHeight {
		min-height: 370px;
	}
	
	@include for-phone-only {
		margin-top: 44px;
		min-height: 440px;
		&.smallHeight {
			min-height: 270px;
		}
	}
	.valueText {
		@extend .font-NeueHGDP-light;
		font-style: normal;
		font-weight: 100;
		color: $primary-color;
		z-index: 100;
		font-size: 70px;
		@include for-phone-only {
			font-size: 60px;
		}
	}
	.NAtext {
		@extend .font-NeueHGDP-light;
		font-style: normal;
		font-weight: 100;
		color: $primary-color;
		z-index: 100;
		font-size: 60px;
		@include for-phone-only {
			font-size: 40px;
		}
	}
	.textContainer {
		position: relative;
		height: calc(#{$h5-line-height-mobile} + 10px);
		width: 100%;
		@include for-phone-only {
			height: calc(#{$h6-line-height-mobile} + 5px);
		}
		.left,
		.middle,
		.right {
			position: absolute;
			color: var(--base-color);
		}
		.left {
			left: 30px;
		}
		.middle {
			left: 50%;
			transform: translate(-50%, 0%);
		}
		.right {
			right: 30px;
		}
		@include for-both-phone-and-tablet {
			.left {
				left: 10px;
			}
			.right {
				right: 10px;
			}
		}
	}
	.smallText {
		@extend .font-Mono-medium;
		@extend .note-2;
		color: $primary-color;

		// width: 61px;
		text-align: center;
		@include for-phone-only {
			width: fit-content;
		}
	}
	.hexagon {
		height: 110px;
		width: 110px;
		@include for-phone-only {
			height: 69px;
			width: 69px;
		}
	}
	.NAContainer {
		height: 110px;
		z-index: 99;
		width: 100%;
		background-image: linear-gradient(
			to right,
			var(--base-color),
			$white
		);
		padding: 0 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 24px;
		gap: 20px;
		border-radius: 12px;
		@include for-phone-only {
			height: 70px;
			gap: 10px;
			margin-top: 0;
			padding: 0 10px;
		}
	}
	.inputContainer {
		width: 100%;
		// margin: 20px 0;
		// border-radius: 12px;
		//background-image: linear-gradient(to right, #ffc3b8, $secondary-color, $sand, $crest);
		//background-image: linear-gradient(109.77deg, rgba(213, 222, 245, 0.59) 11.2%, #ABBDEA 84.76%);

		padding: 0 30px;
		overflow: visible;
		@include for-phone-only {
			// margin: 10px 0;
			padding: 0 10px;
		}
		.trackContainer {
			border: 1px solid var(--base-color);
			// padding-left: 29px;
			.track {
				// background: url("../../../assets/images/face_range_slider.svg");
				// background-size: 100%;
				// background-repeat: no-repeat;
				// background-position: center;
				// outline: none;
				height: 75px;
				z-index: 99;
				width: 100%;

				@include for-phone-only {
					height: 70px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					// transform: scaleY(var(--mobile-scale));
				}
			}
			@include for-phone-only {
				padding-left: 0px;
			}
		}
		.thumbContainer {
			// margin-top: -50px;
			position: relative;
			background-color: transparent;
			z-index: 20;
			margin-top: 0px;
			.thumbCheek {
				position: absolute;
				z-index: 200;
				cursor: pointer;
			}
			.first {
				min-height: 60px;
			}
			.second {
				min-height: 175px;
			}
			.third {
				min-height: 286px;
			}
			.verticalArrow {
				position: relative;
				top: 76px;
				.arrow {
					min-width: 4px;
					max-width: 4px;
					min-height: 100%;
					background-color: var(--base-color);
					position: absolute;
				}
				.arrowDot {
					left: -4px;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					position: absolute;
					background-color: var(--base-color);
					bottom: 0;
				}
				.hex {
					position: absolute;
					bottom: -108px;
					left: -55px;
				}

				@include for-phone-only {
					top: 72px;
					.hex {
						width: 90px;
						left: -44px;
						bottom: -98px;
					}
				}
			}

			.thumbForehead {
				position: absolute;
				z-index: 300;
				cursor: pointer;
				// background: $bright-terra;
				// -webkit-clip-path: polygon(25% 50%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
				// clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
				// cursor: pointer;
				// z-index: 999;
				// width: 90px;
				// height: 90px;
				// display: flex;
				// align-items: center;
				// justify-content: center;
			}

			@include for-both-phone-and-tablet {
				.first {
					min-height: 50px;
				}
				.second {
					min-height: 164px;
				}
				.third {
					min-height: 278px;
				}
			}

			@include for-phone-only {
				.first {
					min-height: 40px;
				}
				.second {
					min-height: 130px;
				}
				.third {
					min-height: 220px;
				}
			}
		}
	}
	.threshold {
		border-bottom: 4px solid $night;
		left: 40%;
		transform: rotate(90deg);
		width: 350px;
		position: absolute;
		top: 131px;
		text-transform: uppercase;
		&:after,
		&:before {
			content: "";
			position: absolute;
			transition: all 0.3s ease-in;
		}

		&:after,
		&:before {
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-right: 10px solid transparent;
			border-left: 10px solid black;
			right: -8px;
			bottom: -12px;
			transform: rotate(180deg);
		}
		&:before {
			left: -8px;
			right: unset;
			border-right: 10px solid black;
			border-left: 10px solid transparent;
		}

		.thresholdText {
			@extend .note-2;
			@extend .font-Mono-medium;
			transform: rotate(180deg);
			padding-left: 20px;
			padding-top: 6px;
		}
		@include for-phone-only {
			border-bottom: 2px solid #2d2926;
			left: 26%;
			width: 300px;
			top: 106px;
			&:after,
			&:before {
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-right: 6px solid transparent;
				border-left: 6px solid black;
				bottom: -7px;
				right: -6px;
				left: unset;
			}
			&:before {
				border-right: 6px solid black;
				border-left: 6px solid transparent;
				left: -6px;
				right: unset;
			}
			.thresholdText {
				padding-left: 0px;
			}
		}
	}
}
