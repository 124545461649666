@import "../../../styles/main";

$bacteria_arrow_top: 64px;
$bacteria_arrow_height: 100px;
$bacteria_arrow_left: 80px;
$bad_bacteria-color: #c46a5a;

.reportHeaderContainer {
	position: relative;
	.headContainer {
		width: 100%;
		height: auto;
		z-index: -1;
		.picText {
			@extend .h3;
			text-transform: uppercase;
			@include for-both-phone-and-tablet {
				font-size: 13px;
			}
			@include for-phone-only {
				font-size: 13px;
			}
		}
	}
	.logo {
		position: absolute;
		left: 50px;
		width: 184px;
		height: 82px;
		top: -30px;

		@include for-phone-only {
			width: 101.22px;
			height: 33px;
			left: 18px;
			top: -5px;
		}
	}
	@include for-both-phone-and-tablet {
		padding-top: 20px;
	}
}

.reportSection {
	background-color: $white;
	min-height: 100vh;
	margin: -170px 0px 0px 0px;
	padding-top: 30px;
	position: relative;
	z-index: 1;
	@include for-both-phone-and-tablet {
		margin: -10px 0px 0px 0px;
		padding-top: 5px;
	}
}

.topSection {
	border: 2px solid $night;
	@include for-phone-only {
		border: 1px solid $night;
	}
	.reportNameCol {
		@extend .center-flex;
		background-color: $stone;
		border-left: 10px solid $night;
		.name {
			@extend .h2;
			@extend .font-NeueHGDP;
			text-transform: uppercase;
		}
		.hexLogo {
			width: 61px;
			height: 42px;
			margin-right: 12px;
		}
		@include for-phone-only {
			border-left: none;
			border-top: 8px solid $night;
			padding: 25px 10px;
			.hexLogo {
				width: 44px;
				height: 36px;
				margin-right: 10px;
			}
		}
	}

	.tableCol {
		.tableCell {
			.cellTitle {
				@extend .note-1;
				@extend .font-Mono-medium;
			}
			display: flex;
			align-items: center;
			padding: 8px 18px;
			min-height: 70px;

			border: 2px solid $night;
			border-top: none;
			border-right: none;
			&.last {
				border-bottom: none;
			}
			@include for-both-phone-and-tablet {
				padding: 8px 12px;
				.cellTitle {
					font-size: $note-2-text-mobile;
					line-height: $note-2-line-height-mobile;
				}
			}
			@include for-phone-only {
				border: 1px solid $night;
				border-top: none;
				border-right: none;
			}
			.tableText {
				@extend .h5;
				line-height: 26px;
			}
		}
		@include for-phone-only {
			border-top: 1px solid $night;
			.tableCell:has(.cellTitle) {
				border-left: none;
			}
			.tableText {
				line-height: $h5-line-height-mobile;
			}
		}
	}
	.topCol2 {
		// height: 100%;
		padding-left: 20px;
		@include for-both-phone-and-tablet {
			padding-left: 0;
			padding-top: 20px;
		}
		@include for-phone-only {
			padding-top: 10px;
		}
		.userDetailsTable {
			// border: 1px solid $primary-color;

			.itemGap {
				padding: 8px 10px;
				min-height: 60px;
				display: flex;
			}
		}
	}
}

.reportBox {
	width: 100%;
	padding: 0 5rem;
	padding-top: 30px;
	padding-bottom: 30px;
	background: linear-gradient(
		179.88deg,
		#f5f1f0 0.11%,
		rgba(245, 241, 240, 0) 300px
	);
	@include for-both-phone-and-tablet {
		width: 100vw;
		padding: 0 3.5rem;
		// padding: 0 15px;
	}
	@include for-phone-only {
		padding: 0 1.5rem;
	}
	.scoreHeadingSection {
		padding-top: 20px;
		.scoreHeading {
			@extend .h4;
			@include for-phone-only {
				.textSpan {
					display: block;
				}
			}
		}
		@include for-phone-only {
			padding-top: 30px;
		}
	}
}

.scoreSection {
	background: $white;
	// margin: 30px 0px;
	border: 2px solid $night;
	:global(.ant-col-xs-24) {
		flex: 0 0 100% !important;
	}
	.scoreCol {
		display: block;
		flex: 0 0 calc(100% / var(--score-col));
		max-width: calc(100% / var(--score-col));
		border-right: 2px solid $night;
		&.last {
			border-right: none;
			@include for-both-phone-and-tablet {
				border-right: 2px solid $night;
			}
			@include for-phone-only {
				border-right: 1px solid $night;
			}
		}

		@include for-phone-only {
			border-right: 1px solid $night;
		}
	}
	.scoreTitle {
		background: $night;
		color: $white;
		padding: 16px 12px;
		@extend .body-txt-lg;
		// border-left: 1px solid $white;

		&.first {
			border-left: none;
		}
	}

	.scoreSubtitle {
		padding: 8px 12px;
		border-bottom: 2px solid $night;
		border-left: none;
		@extend .note-2;
		@extend .font-Mono-medium;
		text-transform: uppercase;
	}

	.scoreContainer {
		min-height: 290px;
		display: flex;
		align-items: center;
		justify-content: center;
		// border-right: 2px solid $night;
		position: relative;

		.scoreImage {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			&.hexIcon {
				height: 170px;
				width: 200px;

				@include for-both-phone-and-tablet {
					width: 160px;
					height: 140px;
				}
			}
			&.smallImage {
				width: 80%;
			}
			&.sensitivityImage {
				width: 80%;
				@include for-both-phone-and-tablet {
					width: 160px;
				}
				@include for-phone-only{
					width: 150px;
				}
				@include for-small-phone-only {
					width: 80%;
				}
			}
			&.capsuleImage {
				height: 224px;
    			width: 200px;

				@include for-both-phone-and-tablet {
					width: 170px;
					height: 170px;
				}
				@include for-phone-only {
					width: 120px;
					height: 130px;
				}
			}
		}

		.scoreText {
			font-size: 110px;
			z-index: 9;
			@extend .font-NeueHGDP-light;
		}
	}
	.headerRow {
		background-color: $night;
		.scoreCol {
			border-right: 2px solid $white;
			border-left: none;
			&.last {
				border-right: none;
			}
		}
	}
	@include for-both-phone-and-tablet {
		border: none;
		.row {
			gap: 16px;
		}
		.scoreCol {
			display: block;
			flex: auto;
			max-width: unset;
			border: 2px solid $night;
		}
		.scoreHalfColumn {
			flex: 0 0 calc(50% - 8px);
			max-width: calc(50% - 8px);
		}
		.scoreTitle {
			border-left: none;
		}
		.scoreSubtitle {
			border-right: none;
		}
		.scoreContainer {
			min-height: 220px;
			border-right: none;

			.scoreText {
				font-size: 70px;
			}
		}
	}
	@include for-phone-only {
		.scoreCol {
			border: 1px solid $night;
		}
		.scoreSubtitle {
			border-bottom: 1px solid $night;
			font-size: $note-3-text-mobile;
			line-height: $note-3-line-height-mobile;
		}
		.scoreTitle {
			&.antiText {
				line-height: 20px;
			}
		}
		.bigTitle {
			min-height: 80px;
		}
		.bigSubtitle {
			min-height: 42px;
		}
	}
}

.faceContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 100%;
	flex-direction: column;

	.areaHex {
		padding-bottom: 24px;
		svg {
			width: 180px;
			height: 155px;
		}
		img {
			width: 160px;
			height: 135px;
		}
		@include for-phone-only {
			min-height: 180px;
			svg {
				width: 130px;
				height: 115px;
			}
		}
	}
	.faceHex {
		width: 260px;
		height: 420px;
		padding-bottom: 40px;
		@include for-phone-only {
			width: 230px;
			height: 370px;
		}
	}

	.faceScore {
		position: relative;
		img {
			width: 180px;
			height: 155px;
		}
		.score {
			position: absolute;
			font-size: 80px;
			color: $white;
			z-index: 9;
			top: 44%;
			left: 50%;
			transform: translate(-50%, -50%);
			@extend .font-NeueHGDP-light;
			// @include for-both-phone-and-tablet {
			// 	top: 18px;
			// 	left: 45px;
			// 	font-size: 80px;
			// }
			// @include for-phone-only {
			// 	top: 14px;
			// 	left: 35px;
			// 	font-size: 60px;
			// }
		}
		.subTitle {
			margin-top: 12px;
			text-align: center;
			@extend .note-1;
			@extend .font-Mono-medium;
		}
		@include for-phone-only {
			img {
				width: 130px;
				height: 115px;
			}
		}
	}
}

.bacteriaContainer {
	padding: 50px 6% 40px 6%;
	min-height: 790px;
	position: relative;
	.arrowLine {
		min-height: $bacteria_arrow_height;
		top: $bacteria_arrow_top;
		width: 100%;
		position: absolute;
		left: $bacteria_arrow_left;
		border-left: 2px solid $uv-blue;
		border-top: 2px solid $uv-blue;
		@extend .show-desktop-hide-mobile;
		&.arrowRight {
			border-right: 2px solid $bad_bacteria-color;
			border-top: 2px solid $bad_bacteria-color;
			border-left: none;
			right: $bacteria_arrow_left;
			left: unset;
		}
	}
	.mobileInfoLayout {
		display: flex;
		@include for-phone-only {
			display: flex;
			flex-direction: row;
			overflow: scroll;
			flex-wrap: nowrap;
			align-items: stretch;
			padding-left: 16px;
			.mobileContainer {
				display: block;
				flex: 0 0 80%;
				max-width: 80%;
				&::-webkit-scrollbar {
					display: none;
				}
			}
			margin-bottom: 24px;
		}
	}

	.badContainer {
		width: 100%;
		display: flex;
		align-items: baseline;
		justify-content: right;
		margin-top: 240px;
		height: calc(100% - 240px);

		@include for-both-phone-and-tablet {
			align-items: start;
			justify-content: left;
			margin-top: 20px;
			height: calc(100% - 90px);
		}
	}
	.buttonContainer {
		@extend .show-mobile-hide-desktop;
		margin-top: 28px;
		padding-left: 10px;
	}
	.goodButton {
		cursor: default;
		text-transform: uppercase;
		background-color: $uv-blue !important;
		position: absolute;
		left: -$bacteria_arrow_left;
		top: $bacteria_arrow_height - 2px;
		@extend .center-flex;
		border: none;
		span {
			@extend .note-1;
			@extend .font-Mono-medium;
		}
		&.buttonRight {
			right: -$bacteria_arrow_left;
			left: unset;
			background-color: $bad_bacteria-color !important;
		}
		@include for-both-phone-and-tablet {
			position: unset;
			top: 0;
		}
	}
	.goodDot {
		right: -3px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		position: absolute;
		top: -7px;
		background-color: $uv-blue;
		@extend .show-desktop-hide-mobile;

		&.rightDot {
			left: -3px;
			right: unset;
			background-color: $bad_bacteria-color;
			@extend .show-desktop-hide-mobile;
		}
	}
	@include for-phone-only {
		padding: 40px 0px;
		padding-bottom: 0px;
	}
}

.collpaseItems {
	background-color: transparent;
	border-radius: 0px;
	.headerContainer {
		padding: 14px 0px 14px 0px !important;
		border-left: 8px solid transparent;
		.heading {
			@extend .note-1;
			@extend .font-Mono-medium;
			text-transform: capitalize;
		}
		&.badHeaderContainer {
			border-right: 8px solid transparent;
			text-align: right;
			border-left: none;
			@include for-both-phone-and-tablet {
				text-align: left;
				padding: 14px 0px 14px 0px !important;
				border-left: 8px solid transparent;
			}
		}
	}

	.description {
		padding: 16px;
		.text {
			@extend .note-2;
			@extend .font-Mono-medium;
		}
	}

	:global(.ant-collapse-header) {
		border-bottom: 2px solid $night;
		padding: 0px !important;
		@include for-phone-only {
			border-bottom: 1px solid $night;
			padding-right: 12px !important;
			padding-left: 10px !important;
		}
	}
	:global(.ant-collapse-content-box) {
		padding: 0px !important;
	}
	:global(.ant-collapse-expand-icon) {
		padding: 22px 0px 14px 0px !important;
	}
	:global(.ant-collapse-item) {
		border-radius: 0px !important;
	}
	:global(.ant-collapse-header){
		border-radius: 0px !important;
	}

	&.goodBacteria {
		:global(.ant-collapse-header[aria-expanded="true"]) {
			border-left: 8px solid $uv-blue;
			padding-left: 2px !important;
		}
	}

	&.badBacteria {
		:global(.ant-collapse-header[aria-expanded="true"]) {
			border-right: 8px solid $bad_bacteria-color;
			padding-left: 2px !important;
		}
		@include for-both-phone-and-tablet {
			:global(.ant-collapse-header[aria-expanded="true"]) {
				border-left: 8px solid $bad_bacteria-color;
				border-right: none;
			}
			:global(.ant-collapse-header) {
				flex-direction: row-reverse;
			}
		}
	}
}

.reconHeaderTitle {
	background: $night;
	padding: 12px 16px;
	color: $white;
	@extend .note-1;
	@extend .font-Mono-medium;
}

.reconCollapseItems {
	background-color: transparent;
	border-radius: 0px;
	.reconHeaderContainer {
		padding: 14px 0px 14px 0px !important;
		border-left: 8px solid transparent;
		.heading {
			@extend .body-txt-lg;
			text-transform: capitalize;
		}
	}
	.benefits {
		background: $night;
		color: $white;
		padding: 12px 16px;
		@extend .note-1;
		@extend .font-Mono-medium;
	}
	.benefitsContainer {
		padding: 20px 16px;
		border-bottom: 2px solid $night;
		background: linear-gradient(180deg, var(--base-color) 0%, #ffffff 100%);
		@include for-phone-only {
			border-bottom: 1px solid $night;
		}
	}
	.description {
		padding: 16px;
		background-color: var(--base-color);
		.text {
			@extend .note-2;
			@extend .font-Mono-medium;
		}
	}

	:global(.ant-collapse-header) {
		border-bottom: 2px solid $night;
		padding: 0px 12px 0px 8px !important;
		@include for-phone-only {
			border-bottom: 1px solid $night;
		}
	}
	:global(.ant-collapse-content-box) {
		padding: 0px !important;
	}
	:global(.ant-collapse-expand-icon) {
		padding: 22px 0px 14px 0px !important;
	}
	:global(.ant-collapse-item){
		border-radius: 0px !important;
	}
	:global(.ant-collapse-header){
		border-radius: 0px !important;
	}

	:global(.ant-collapse-header[aria-expanded="true"]) {
		border-left: 8px solid $night;
		background-color: var(--base-color);
		padding: 0px 12px 0px 0px !important;
	}
}

.productHeader {
	width: 100%;
	position: relative;
	display: flex;
	// justify-content: space-between;
	padding: 0px 0px;
	background-color: $uv-blue;
	.reportSectionCount {
		position: absolute;
		left: -32px;
		top: -6px;
		@extend .body-txt-lg;
		@extend .font-Mono-medium;
		@include for-both-phone-and-tablet {
			left: 0;
			top: -30px;
		}
	}
	.title {
		@extend .h4;
		color: $white;
		height: 100%;
	}
	.leftSide,
	.rightSide {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 28px 25px;
		img {
			width: 46px;
		}
	}
	.rightSide {
		height: 100%;
		border-left: 2px solid $white;
		padding-left: 20px;
		.title,
		.subTitle {
			color: $white;
		}
		.title {
			@extend .note-1;
			text-transform: uppercase;
			@extend .font-Mono-medium;
		}
	}
	.rightCol {
		@extend .center-flex;
		height: 100%;
	}
	@include for-phone-only {
		padding: 20px 18px;
		.leftSide {
			padding: 0px;
			flex-direction: column-reverse;
			gap: 12px;

			img {
				width: 42px;
			}
		}
		.rightSide {
			border: none;
			padding: 0px;
			padding-left: 0px;
			gap: 12px;
		}
		.rightCol {
			height: auto;
			margin-top: 16px;
		}
	}
}

.hideMobileBorder {
	border: 2px solid $night;
	@include for-both-phone-and-tablet {
		border: none !important;
		border: 1px solid $night;
	}
}

.lastSection {
	margin-bottom: 40px;
}

.bacteriaInfoContainer {
	border: 2px solid $night;
	padding: 24px 24px 15px 24px;
	min-height: 418px;
	max-height: 418px;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 5px; // Width of the entire scrollbar
	}

	&::-webkit-scrollbar-track {
		background: transparent; // Color of the tracking area
	}

	&::-webkit-scrollbar-thumb {
		background: #2d2926; // Color of the scroll thumb
		border-radius: 12px; // Rounded corners on the scroll thumb
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #262626; // Color of the scroll thumb on hover
	}
	&.good {
		background: linear-gradient(180deg, #abbdea 0%, #ffffff 100%);
	}
	&.bad {
		background: linear-gradient(
			180deg,
			rgba(196, 106, 90, 0.7) 0%,
			#ffffff 100%
		);
	}
	.title {
		@extend .body-txt-lg;
		margin-bottom: 18px;
	}
	.description {
		@extend .body-txt-lg;
	}
	@include for-phone-only {
		padding: 20px 16px;
		border: 1px solid $night;
		min-height: 100%;
		.title {
			margin-bottom: 12px;
		}
	}

	// Your existing container styles

	// Webkit browsers (Chrome, Safari, newer versions of Edge)

	// Firefox
	// scrollbar-width: thin;
	// scrollbar-color: #888 #f1f1f1;

	// // For IE and Edge (older versions)
	// -ms-overflow-style: none; // Hides the default scrollbar for IE/Edge

	// // You can add a custom scrollbar for IE/Edge if needed
	// // This example adds a pseudo-element as a custom scrollbar
	// &:after {
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	width: 10px;
	// 	height: 100%;
	// 	background: #f1f1f1;
	// }
}

.serumLabelContainer {
	background: $night;
	display: flex;
	// gap: 20px;
	padding: 16px 35px;
	height: 100%;

	.titleContainer {
		@extend .center-flex;
		justify-content: left;
		gap: 20px;
		padding-right: 20px;
		@include for-phone-only {
			flex-direction: row-reverse;
			margin-bottom: 14px;
		}
	}

	.title,
	.subTitle {
		color: $white;
		@extend .note-1;
		@extend .font-Mono-medium;
		text-transform: uppercase;
	}
	.title {
		@extend .note-1;
		@extend .font-Mono-bold;
		
	}
	.subTitleContainer {
		display: flex;
		height: 100%;
		align-items: center;
		@include for-phone-only {
			height: auto;
		}
	}
	@include for-phone-only {
		padding: 16px 16px;
		.titleContainer {
			gap: 12px;
		}
		.subTitle {
			line-height: $note-1-line-height-mobile;
		}
	}
}

.lastCol {
	flex-grow: 1;
	max-width: 34%;
	@include for-both-phone-and-tablet {
		max-width: 100%;
	}
}

.bodyContainer {
	width: calc(100% - 32px);
	display: flex;
	gap: 20px;
	svg {
		width: 100%;

		@include for-phone-only {
			height: 360px;
		}
	}
}

.scalpContainer {
	width: calc(100% - 32px);
	@extend .center-flex;
	svg {
		width: 70%;

		@include for-phone-only {
			height: 270px;
		}
	}
}

.mobileBorder {
	@include for-phone-only {
		border-bottom: none;
	}
}
