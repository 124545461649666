@import "../../styles/main";

$tile-image-width: 240px;
$tile-image-height: 270px;
$mobile-img-height: 320px;

.container {
	display: flex;
	// justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	padding-bottom: 40px;

	&.reportv1 {
		padding-bottom: 0px;
	}
	:global .viewBoxWidth {
		@include for-desktop-up {
			width: 95%;
		}
		// 1465
		@media (max-width: 1465px) {
			width: 100%;
		}
	}
	.toggleExpand {
		color: $white !important;
		cursor: pointer;

		&:hover {
			color: $night !important;
		}
	}
	:global .ant-typography-expand {
		color: $night;
		margin-left: 0;
		font-family: "PPNeueMontrealMono Medium" !important;

		&:before {
			content: " ";
			display: block;
			margin-bottom: 10px;
		}

		&:hover {
			color: $uv-blue;
		}
	}
	.topSection {
		.topCol1 {
			// height: 100%;
			.textColHeight {
				height: $h3-line-height;
				@include for-phone-only {
					height: $h3-line-height-mobile;
				}
			}
			.waveColHeight {
				height: calc(100% - (#{$h3-line-height} + 20px));
				@include for-phone-only {
					height: auto;
				}
			}
		}
		.topCol2 {
			// height: 100%;
			padding-left: 20px;
			@include for-both-phone-and-tablet {
				padding-left: 0;
				padding-top: 20px;
			}
			@include for-phone-only {
				padding-top: 10px;
			}
			.userDetailsTable {
				border: 1px solid $primary-color;
				.itemGap {
					padding: 8px 10px;
					// @include for-phone-only {
					//     padding: 8px 10px;
					// }
				}
				.userProfile {
					display: grid;
					// grid-template-columns: 50% 50%;
					grid-template-columns: auto 200px;
					@include for-medium-phone-only {
						grid-template-columns: auto 180px;
					}
					@include for-small-phone-only {
						grid-template-columns: auto 132px;
					}
					.profileContent {
						border-right: 1px solid $primary-color;
					}
					.profileImage {
						display: flex;
						align-items: center;
						justify-content: center;
						.profilePic {
							width: 180px;
							@include for-medium-phone-only {
								width: 160px;
							}
							@include for-small-phone-only {
								width: 112px;
							}
						}
					}
				}
			}
		}
		.wave {
			width: 100%;
			// height: 253px;
			height: 100%;
			margin-top: 10px;
			&.mobileImg {
				display: none;
			}
			@include for-both-phone-and-tablet {
				height: auto;
				margin: 10px 0;
			}
			@include for-phone-only {
				&.desktopImg {
					display: none;
				}
				&.mobileImg {
					display: block;
				}
			}
			// background: url("/assets/images/PH_waves_report.svg");
			// background-repeat: no-repeat;
			// background-size: cover;
			// background-position: center center;
			// background-color: inherit;
		}
	}
	.userGreetingSection {
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding-top: 0;
		@include for-phone-only {
			gap: 10px;
		}
		.userText {
			@extend h1;
			@extend .font-NeueHGDP-light;
			color: $primary-color;
			text-transform: capitalize;
		}
	}
	.box {
		margin-top: 80px !important;
		@include for-phone-only {
			margin-top: 40px !important;
		}
	}
	.skinTypeBox {
		@include for-both-phone-and-tablet {
			margin-top: 30px !important;
		}
	}
	.hydrationBox {
		row-gap: 20px;
		margin-top: 30px !important;
		@include for-both-phone-and-tablet {
			row-gap: 40px;
		}
	}
	.microbiomeScoreBox {
		@include for-both-phone-and-tablet {
			margin-top: 0px !important;
		}
	}
	.horizontalseparator {
		margin: 0;
		width: 100%;
		background-color: $primary-color;
		border-block-start: 2px solid rgba(5, 5, 5, 0.06);
	}
	.boxMargin {
		margin: 50px 0px;
		@include for-both-phone-and-tablet {
			margin: 40px 0px;
		}
	}
	.showInSmallScreen {
		display: none !important;
		@include for-phone-only {
			display: flex !important;
		}
	}
	.showInBigScreen {
		display: flex !important;
		flex-direction: column;

		img {
			margin-top: 30px;
		}
		@include for-phone-only {
			display: none !important;
		}
	}
	.constellationTypeContainer {
		flex-direction: column;
		position: relative;
	}
	.constellationTitleContainer {
		position: absolute;
		text-align: left;
		padding-left: 8px;
		top: 8px;
		left: 0;
		right: 0;

		.h2Text {
			color: $secondary-color;
		}
	}
	.viewAllActionContainer {
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;

		.viewAllAction {
			border-bottom: 1px solid $night;
			font-family: "Medium", serif;
			font-weight: normal;
			@extend .body-txt-md;
			cursor: pointer;
			color: $night;
		}
	}
	.placeHolderImage {
		width: $tile-image-width !important;
		height: $tile-image-height !important;
		object-fit: scale-down;
		border-radius: 0px;
		content: url("../../assets/images/image_placeholder.svg");
		border: 1px solid $primary-color;
		@include for-phone-only {
			width: 100% !important;
			height: $mobile-img-height !important;
			border-radius: 0px;
		}
	}
	.tile1 {
		background-color: $uv-blue;
		padding: 30px;
		position: relative;
		// border-radius: 12px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		height: 100%;
		@include for-phone-only {
			padding: 20px;
			// border-radius: 4px;
			gap: 20px;
		}
	}
	.tile2 {
		background-color: $stone;
		padding: 30px;
		position: relative;
		// border-radius: 12px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		height: 100%;
		@include for-phone-only {
			padding: 20px;
			// border-radius: 4px;
			gap: 10px;
		}
	}

	.reportCollapse {
		background: $secondary-color;
		border: 1px solid $night;
		border-radius: 0;
		@include for-both-phone-and-tablet {
			border: 0 !important;
		}
		&.pieChartCollapse {
			border: none;
		}
		:global {
			.ant-collapse-item {
				&:not(:last-child) {
					border-bottom: 1px solid $night;
				}

				// &:last-child {
				//     border-bottom: 1px;
				// }
				@include for-both-phone-and-tablet {
					border: 1px solid $stone !important;
					border-radius: 0px;
					margin: 20px 0;
				}
			}
			.ant-collapse-header {
				background-color: $secondary-color;
				padding: 30px;
				@include for-phone-only {
					border-radius: 0px;
					padding: 20px 10px;
				}

				.ant-collapse-header-text {
					width: -webkit-fill-available;
				}

				.ant-collapse-arrow {
					right: 30px;
					@include for-phone-only {
						right: 10px;
					}
				}
			}

			.ant-collapse-content {
				border-top: none;
			}
			.ant-collapse-content > .ant-collapse-content-box {
				padding: 0 30px 30px 30px;
				background: $secondary-color;
				@include for-phone-only {
					padding: 0 10px 20px 10px;
				}
			}
		}
	}

	.pieChartCollapse {
		border-radius: 0;
		:global {
			.ant-collapse-item {
				border-radius: 0 !important;
				margin: 0 !important;
			}
			.ant-collapse-expand-icon {
				margin-inline-start: 0px !important;
			}
			.ant-collapse-item:nth-child(1) {
				.anticon {
					color: $white !important;
				}
			}
			.ant-collapse-item:nth-child(2) {
				.anticon {
					color: $white !important;
				}
			}
			.ant-collapse-item:nth-child(4) {
				.anticon {
					color: $night !important;
				}
			}
			.ant-collapse-item:nth-child(5) {
				.anticon {
					color: $night !important;
				}
			}
		}
	}

	.descContainer {
		flex: 1;
	}

	.productLabel {
		background-color: $stone;
		color: $primary-color;
		@extend .h4;
		width: 100%;
		margin-bottom: 21px;
		padding: 10px;
		text-align: center;
		@include for-phone-only {
			padding: 15px 10px;
			margin-bottom: 0;
		}
	}
	.tileContent {
		display: grid;
		// grid-template-columns: 270px auto;
		grid-template-columns: calc(20px + #{$tile-image-width}) auto !important;
		@include for-phone-only {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
		.productImgContainer {
			position: relative;
			background-color: $secondary-color;
			@include for-phone-only {
				padding: 8px;
			}
			.productTag {
				background-color: $infrared;
				color: $secondary-color !important;
				padding: 12px 10px 10px 10px;
				border-radius: 30px;
				@extend .body-txt-lg;
				font-family: "Medium", serif;
				position: absolute;
				right: 34px;
				top: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include for-phone-only {
					right: 14px;
				}
			}
			.tileImageRecommendation {
				width: $tile-image-width !important;
				height: $tile-image-height !important;
				object-fit: cover;
				@include for-phone-only {
					width: 100% !important;
					height: auto !important;
					max-height: $mobile-img-height !important;
					border-radius: 0;
				}
			}
		}
		.tileImage {
			// width: 250px;
			// height: 250px;
			width: $tile-image-width !important;
			height: $tile-image-height !important;
			object-fit: cover;
			@include for-phone-only {
				width: 100% !important;
				height: auto !important;
				max-height: $mobile-img-height !important;
				// border-radius: 4px;
			}
		}
	}

	.tileContent.masked {
		.productImgContainer,
		.innerContentPadding {
			opacity: 0.4;
		}
	}
	.prodName {
		@include for-both-phone-and-tablet {
			padding-top: 0;
		}
	}
	.fullWidthColumnMobile {
		@include for-both-phone-and-tablet {
			padding-left: 0 !important;
			padding-right: 0 !important;
			background: $stone;
			padding-bottom: 16px;
			.innerContentPadding {
				padding: 0 10px;
				text-align: start;
			}
		}
	}
	.productRecommendationRow {
		row-gap: 50px !important;
		@include for-phone-only {
			row-gap: 30px !important;
		}
	}
	.introColLeft,
	.rProdListRowContainer .prodColLeft {
		@include for-desktop-up {
			padding-right: 30px !important;
		}
	}
	.introColRight,
	.rProdListRowContainer .prodColRight {
		@include for-desktop-up {
			padding-left: 30px !important;
		}
	}
	.recommendationLegend {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $stone;
		@include for-small-phone-only {
			flex-direction: column;
			align-items: flex-start;
			gap: 3px;
			margin-bottom: 30px;
		}
	}
	.fullWidthTile2 {
		margin-left: 10px;
		@include for-both-phone-and-tablet {
			margin-left: 0;
			margin-top: 20px;
		}
		@include for-phone-only {
			margin-top: 20px;
		}
	}
	.fullWidthTile1 {
		margin-right: 10px;
		@include for-both-phone-and-tablet {
			margin-right: 0;
		}
	}
	.fullWidthTile3 {
		margin-right: 10px;
		@include for-both-phone-and-tablet {
			margin-left: 0;
			margin-top: 30px;
		}
		@include for-phone-only {
			margin-top: 10px;
		}
	}
	.fullWidthTile {
		background-color: $white;
		padding: 30px;
		position: relative;
		border: 1px solid $night;
		gap: 30px;
		@include for-phone-only {
			padding: 20px;
			gap: 10px;
			margin-top: 30px !important;
		}
		.ageDisplayGrid {
			display: grid;
			grid-template-columns: 59% 2% 39%;
			@include for-phone-only {
				display: flex;
				flex-direction: column;
				gap: 8px;
				margin-top: 0;
			}
			.ageDisplayBlock1 {
				background-color: $stone;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				width: 100%;
				margin: 30px 0;
				height: 110px;
				@include for-phone-only {
					margin: 16px 0;
					height: 70px;
				}
			}
			.ageDisplayBlock2 {
				background-color: transparent;
				border: 1px solid $primary-color;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				width: 100%;
				margin: 30px 0;
				height: 110px;
				@include for-phone-only {
					margin: 16px 0;
					height: 70px;
				}
			}
		}

		.leftCol {
			@include for-desktop-up {
				padding-left: 40px !important;
			}
		}
	}

	.darkBg {
		background-color: #494642;
	}
	.bacteriaPanel {
		display: inline;
		position: relative;
	}
	.positionedHeaderBlock {
		@extend .body-txt-lg;
		padding: 16px !important;
		position: absolute;
		left: -98px;
		top: -27px;
		color: $primary-color;
		font-weight: 600;
		width: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include for-phone-only {
			font-size: $body-text-mobile-med;
			padding: 8px !important;
			left: -57px;
			top: -13px;
			width: 42px;
		}
	}
	.positionedIcon {
		position: absolute;
		right: 30px;
		top: 30px;
		font-size: 21px;
		color: $secondary-color;
		@include for-phone-only {
			right: 20px;
			top: 20px;
		}
	}
	.tooltipIcon {
		font-size: 21px;
		color: $primary-color;
		vertical-align: 2px;
		@include for-both-phone-and-tablet {
			position: absolute;
			right: 0;
			vertical-align: -0.125em;
			top: 0;
			margin-left: 0;
		}
	}
	.collapseIcon {
		// font-size: 30px !important;
		// color: $primary-color !important;
		width: 28px;
		height: auto;
		@include for-phone-only {
			width: 20px;
			// font-size: 18px !important;
		}
	}
	.quincyText {
		@extend .font-NeueHGDP-light;
		font-style: normal;
		font-weight: 100;
	}
	.profilePicContainer {
		height: 90%;
		width: 90%;
	}
	.profilePicText {
		@extend .font-NeueHGDP-light;
		font-style: normal;
		font-weight: 100;
		font-size: 90px;
		color: $primary-color;
		z-index: 100;
		@include for-phone-only {
			font-size: 68px;
		}
	}

	.boldSmallTitleText {
		@extend .body-txt-lg;
		@extend .font-Neue-medium;
		color: $primary-color;
		// font-weight: 600;
		text-transform: uppercase;
	}
	.kitTypeName {
		@extend .h4;
		color: $primary-color;
	}
	//64
	.h1Text {
		@extend .h1;
		@extend .font-NeueHGDP-light;
		color: $primary-color;
	}
	.headingText {
		@extend .h3;
		color: $primary-color;
	}
	//48
	.h2Text {
		@extend .h3;
		@extend .font-NeueHGDP-light;
		color: $primary-color;
	}
	//36
	.h3Text {
		@extend .body-txt-lg;
		color: $primary-color;
	}
	//24
	.bodyText1 {
		@extend .body-txt-lg;
		letter-spacing: 0px !important;

		@include for-both-phone-and-tablet {
			font-size: $body-text-mobile-med;
			line-height: $body-line-height-mobile-med;
		}
	}
	.bodyCustom {
		font-size: 20px;
		line-height: $body-line-height-lg;
		@include for-both-phone-and-tablet {
			font-size: 16px;
			line-height: $body-line-height-mobile-lg;
		}
	}
	.bodyCustom2 {
		font-size: 16px;
		line-height: $body-line-height-med;
		@include for-both-phone-and-tablet {
			font-size: 12px;
			line-height: $body-line-height-mobile-med;
		}
	}
	//18
	.bodyText2 {
		@extend .body-txt-lg;
		color: $primary-color;
		@include for-both-phone-and-tablet {
			font-size: $body-text-mobile-small;
			line-height: $body-line-height-mobile-small;
		}
	}
	.descriptionLight {
		color: $secondary-color !important;
		white-space: break-spaces;

		:global .ant-typography-expand {
			&:before {
				content: " ";
				display: block;
			}
		}
	}
	//14
	.smallText {
		@extend .note-3;
	}
	.highlight {
		span {
			color: $night !important;
			font-weight: 600;
		}
		//b {
		//    color: $walnut !important;
		//}
	}
	.button {
		width: 90%;
		min-height: $button-height;
		font-size: $button-text;
		line-height: $button-text;
		color: $secondary-color;
		font-family: "Medium", serif;
		&.preOrder {
			@include for-desktop-up {
				min-width: 215px;
				max-width: 250px;
			}
		}
		@include for-phone-only {
			width: 100%;
			height: $button-height-mobile;
			font-size: $button-text-mobile;
			line-height: $button-text-mobile;
			position: relative;
			bottom: 0;
		}
	}
	.skinMBTypeCard {
		padding-bottom: 45px;
		@include for-both-phone-and-tablet {
			padding-bottom: 40px;
		}
	}
	.skinTypePercentContainer {
		color: $secondary-color !important;
		text-align: center;
		width: 100%;
		position: relative;

		.skinTypePercent {
			border: 1px solid $secondary-color;
			@extend .font-NeueHGDP-light;
			font-size: $h1-text;
			line-height: $h1-line-height;
			padding: 10px 0;
			text-align: center;
			@include for-both-phone-and-tablet {
				font-size: $h1-text-mobile;
				line-height: $h1-line-height-mobile;
				padding: 15px 0;
				margin-bottom: 5px;
			}
		}

		.skinTypePercentDesc {
			@extend .note-3;
			color: $secondary-color !important;
			margin-top: 5px;
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
		}
	}
	.recommendedProductListPanel {
		:global .ant-collapse-content-box {
			padding-bottom: 60px !important;
		}
		@include for-both-phone-and-tablet {
			margin-bottom: 0 !important;
			:global {
				.ant-collapse-content,
				.ant-collapse-content-box {
					padding-bottom: 0 !important;
					border-bottom-left-radius: 8px !important;
					border-bottom-right-radius: 8px !important;
				}
			}
		}
	}
	.recommendationRow {
		@include for-phone-only {
			margin-top: -20px;
		}
		.recommendationCols {
			padding: 30px 0;
			display: flex;
			flex-direction: row;
			gap: 20px;
			height: 100%;
			@include for-both-phone-and-tablet {
				border-bottom: 1px solid $stone !important;
			}
			@include for-phone-only {
				padding: 20px 0 0 0;
				gap: 10px;
				// flex-direction: row-reverse;
			}
			.content {
				display: flex;
				flex-direction: column;
				gap: 20px;
				@include for-phone-only {
					gap: 10px;
					padding-bottom: 20px;
				}
			}
			.image {
				width: 170px;
				height: 170px;
				@include for-phone-only {
					width: 100px;
					height: 100px;
				}
			}
		}

		.firstRowItems {
			.recommendationCols {
				@include for-desktop-up {
					padding-top: 0;
				}
			}
		}
		.lastItem {
			.recommendationCols {
				border-bottom: none !important;
				@include for-both-phone-and-tablet {
					.content {
						padding-bottom: 0 !important;
					}
				}
			}
		}

		.lightTextCol {
			.bodyText1,
			.bodyText2 {
				color: $day !important;
			}
		}
	}

	.recommendationRow.masked {
		.recommendationCols {
			opacity: 0.4;
		}

		.contentMask {
			@include for-both-phone-and-tablet {
				left: 10px;
				width: calc(100% - 20px);
			}
		}
	}
	.circleIconSmall {
		@extend .body-txt-lg;
		margin-right: 10px;
	}
	.circleIconBig {
		font-size: 22px;
		margin-right: 10px;
	}
	.emoSection {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 30px;
		margin-top: 20px;
		@include for-phone-only {
			gap: 16px;
			margin-top: 20px;
		}
		.emoIcon {
			width: 50px;
			height: 50px;
			@include for-phone-only {
				width: 30px;
				height: 30px;
			}
		}
		.emoText {
			padding: 10px 15px;
			@extend .body-txt-lg;
			@extend .font-Neue-medium;
			color: $secondary-color;
			@include for-phone-only {
				padding: 8px 10px;
			}
		}
	}
	.leftAlignmentMobile {
		@include for-both-phone-and-tablet {
			display: flex;
			align-items: center !important;
			justify-content: flex-start !important;
		}
		@include for-phone-only {
			text-align: left !important;
		}
	}
	.centerAlignmentMobile {
		@include for-phone-only {
			display: flex;
			align-items: center !important;
			justify-content: center !important;
		}
	}
	.pointContent {
		cursor: pointer;
		&:hover {
			opacity: 0.9;
		}
	}
	.bannerContent {
		// padding: 40px;
		// @include for-phone-only {
		//     padding: 16px;
		//     // padding-bottom: 10px;
		// }
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.marqueeSection {
			cursor: pointer;
			display: flex;
			align-items: center;
			max-width: 100vw;
			padding: 20px 0;
			overflow: hidden;
			@include for-phone-only {
				padding: 16px 0;
			}

			.marqueeContent {
				.marqueeImage {
					height: 400px;
					margin: 0 8px;
					@include for-phone-only {
						height: 120px;
						margin: 0 4px;
					}
				}
			}
		}
		.bannerSection {
			margin-bottom: 70px;

			.banner {
				// background: url("../../assets/images/beta_banner_wave.png");
				// background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("/assets/images/PH_waves_bg.svg");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				background-color: $primary-color !important;
				padding: 80px 5rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				@include for-both-phone-and-tablet {
					padding: 56px 48px;
				}
				@include for-phone-only {
					padding: 48px 0;
				}
				@include for-medium-phone-only {
					background-size: 300% 121%;
					background-position: top;
				}
				@include for-small-phone-only {
					background-size: 300% 121%;
					background-position: top;
				}
				.bannerTitle {
					@extend .h2;
					color: $secondary-color;
					font-style: normal;
					font-weight: 100;
					padding-right: 200px;
					@include for-both-phone-and-tablet {
						padding: 10px 25px;
						text-align: center;
					}
				}

				.bannerImage {
					width: auto;
					max-width: 100%;
					object-fit: contain;
					content: url("../../assets/images/beta_banner.png");
					@include for-both-phone-and-tablet {
						padding: 10% 5%;
						max-width: 100%;
						width: auto;
						height: auto;
					}
					@include for-phone-only {
						object-fit: contain;
						content: url("../../assets/images/beta_banner_mobile.png");
					}
				}

				.bannerButtonSmall {
					width: fit-content;
					height: $button-height;
					margin-top: 20px;
					font-size: $button-text;
					line-height: $button-text;
					display: none;
					color: $primary-color;
					// @include for-both-phone-and-tablet {
					//     display: block;
					// }
					@include for-phone-only {
						display: block;
						height: $button-height-mobile;
						font-size: $button-text-mobile-large;
						line-height: $button-text-mobile-large;
						width: fit-content;
					}
				}
			}
		}
	}

	//admin-kitcode-section

	// admin-header

	.headerContainer {
		background: $primary-color;
		color: $secondary-color;
		height: $header-md-height !important;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		@include for-phone-only {
			height: $header-xs-height !important;
		}
		.linkText {
			color: $secondary-color !important;
			white-space: pre;
			font-size: 12px !important;
			margin-right: 20px;
			@include for-phone-only {
				margin-right: 0;
			}
			// &:hover {
			//     // transform: scale(1.1);
			//     text-decoration: underline;
			//     text-underline-offset: 3px;
			// }
		}
		.active {
			color: $bright-terra !important;
		}
		.otpInput {
			input {
				text-align: center !important;
				border: none !important;
				border-radius: 0px !important;
				font-size: $h4-text !important;
				color: $primary-color !important;
				background: $stone !important;
				font-weight: 400 !important;
				caret-color: $primary-color !important;
				outline: none !important;
				width: 50px !important;
				height: 40px !important;
				text-transform: uppercase !important;
				@include for-phone-only {
					// width: 30 !important;
					// height: 34px !important;
					border-radius: 0px !important;
					font-size: $h4-text-mobile !important;
				}
			}
		}
		.continueButton {
			width: fit-content;
			height: 40px;
			border-radius: 12px;
			padding: 0 30px;
			font-size: $button-text;
			line-height: $button-text;
			@include for-phone-only {
				font-size: $button-text-mobile;
				line-height: $button-text-mobile;
				border-radius: 4px;
			}
		}
		// .continueButton:disabled {
		//     background: $sand !important;
		//     color: $secondary-color !important;
		// }
		.logoutIcon {
			width: 25px;
			height: auto;
			@include for-small-phone-only {
				height: 15px;
			}
		}
		.logoutSection {
			display: flex;
			align-items: center;
			gap: 10px;
			@include for-small-phone-only {
				gap: 8px;
			}
		}
		.customViewBoxPadding {
			@include for-phone-width-297 {
				padding: 0 0.5rem !important;
			}
		}
		.logoSection {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-right: 1rem;
			@include for-phone-only {
				padding-right: 10px;
			}
			img {
				// object-fit: cover;
				height: auto;
				width: 150px;
				content: url("../../assets/images/admin_header.svg");
				// @include for-medium-phone-only {
				//     height: 50px;
				//     width: 120px;
				// }
				@include for-small-phone-only {
					object-fit: cover;
					height: 43px;
					width: auto;
				}
			}
		}
	}
}

.constellationModal {
	:global .ant-modal-content {
		background-color: $stone;
		border-radius: 0px !important;
		box-shadow: none;
		text-align: center;

		:global .ant-modal-close-x {
			right: 10px;
			top: 5px;
			width: 24px;
			height: 36px;
		}
	}
	:global .ant-modal-body {
		padding: 0 !important;
		img {
			height: 100vh;
			width: 50vw;
		}
	}
}

.sampleReportTitleFlexBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	.headingText {
		text-align: center;
		@include for-both-phone-and-tablet {
			text-align: left;
		}

		&.smallWidth {
			width: 60%;
			@include for-both-phone-and-tablet {
				width: 100%;
			}
		}
	}
}

.sampleReportDetailsBox {
	align-self: stretch;
	position: relative;
	background-color: #494642;
	height: auto;
	text-align: left;
	font-size: 24px;
	color: $day;
}

.yourSkinDiscovery {
	line-height: 36px;
	text-transform: uppercase;
	display: block;
	margin: 0 auto 20px;
}

.sampleReportPreviewContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 25px;
}

.completeReportScreenshot {
	border-radius: 0px;
	max-width: 100%;
	overflow: hidden;
}

.sampleReportDetailsRow {
	:global(.text-light) {
		.bodyText1,
		.bodyText2 {
			color: $day;
		}
	}
}

.contentMask {
	display: none;
}

.masked {
	position: relative;

	.contentMask {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(10px);
		z-index: 1;

		.overlayContent {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: 20px;
			height: 100%;
		}
	}
}

.promoImagesBox {
	.bannerContent {
		padding-top: 0 !important;

		.marqueeContent:global.show-only-on-desktop {
			@include for-desktop-up {
				display: flex;
				gap: 30px;
			}
		}

		.marqueeContent {
			:global(.marquee) {
				min-width: 50% !important;
			}
		}
	}
}

.viewSampleReportBtn {
	min-height: 60px !important;

	@include for-both-phone-and-tablet {
		min-height: 40px !important;
	}

	&:global(.show-only-on-desktop) {
		@include for-both-phone-and-tablet {
			display: none !important;
		}
	}

	&:global(.hide-on-desktop) {
		width: 100%;
		@include for-desktop-up {
			display: none !important;
		}
	}
}

.orderSkinKitBtnContainer {
	margin: 50px 0 100px;

	@include for-both-phone-and-tablet {
		margin: 30px 0 60px;
		width: 100%;
	}

	.button {
		width: 100%;
	}
}

.sampleReportModal {
	width: 90vw !important;
	min-height: 90vh !important;
	@include for-both-phone-and-tablet {
		width: 85vw !important;
		left: -10px;

		:global(.ant-modal-content),
		:global(.ant-modal-body) {
			padding: 0 !important;
		}

		:global(.ant-modal-close) {
			right: -35px !important;
		}
	}

	:global {
		.ant-modal-close,
		.ant-modal-close-x {
			width: 40px !important;
			height: 40px !important;
		}

		.ant-modal-close {
			right: -40px;
		}
	}
}

.blurredPreview {
	backdrop-filter: blur(10px);
	width: 100%;
	height: auto;
}

.thankyouContainer {
	padding: 40px 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $night;

	.retestText,
	.thankyouText {
		@extend .h3;
		color: $white;
	}
	.thankyouText {
		font-style: italic;
		font-weight: 500;
		font-family: "PPEditorialOld UltralightItalic" !important;
	}
	@include for-both-phone-and-tablet {
		padding: 0px 20px 40px 20px;
		width: 100%;
		background-color: $night;
		.retestText,
		.thankyouText {
			color: $white;
		}
	}
}

// Admin kit code section

.kitcodeSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding: 50px 20px;
	margin-bottom: 40px;
	background-color: $stone;
	@include for-phone-only {
		padding: 16px;
	}
	.quincyText {
		@extend .font-NeueHGDP-light;
		font-style: normal;
		font-weight: 100;
	}
	.otpInput {
		input {
			text-align: center !important;
			border: 1px solid $night !important;
			border-radius: 0px !important;
			font-size: $h2-text !important;
			color: $night !important;
			background: transparent!important;
			font-weight: 400 !important;
			caret-color: $night !important;
			outline: none !important;
			width: 90px !important;
			height: 60px !important;
			text-transform: uppercase !important;
			@include for-phone-only {
				width: 34px !important;
				height: 34px !important;
				border-radius: 0px !important;
				font-size: $h2-text-mobile !important;
			}
		}
	}
	.continueButton {
		width: fit-content;
		height: 40px;
		padding: 0 30px;
		font-size: $button-text;
		line-height: $button-text;
		@include for-phone-only {
			font-size: $button-text-mobile;
			line-height: $button-text-mobile;
		}
	}
}
