@import "../../../styles/main";

.PhTabs {
    height: 50px;
    margin-top: 20px;

    @include for-both-phone-and-tablet {
        height: 60px;
        width: 100%;
    }

    @include for-phone-only{
        height: 60px;
        width: 100%;
    }

    .ant-tabs-tab {
        border: 2px solid $uv-blue;
    }

    :global(.ant-tabs-nav) {
        &::before{
            border: none;
        }
    }
    :global(.ant-tabs-ink-bar){
        display: none;
    }
    :global(.ant-tabs-content-holder){
        display: none;
    }
    :global(.ant-tabs-nav-operations){
        display: none !important;
    }

    :global(.ant-tabs-nav .ant-tabs-tab){
        padding: 22px 48px !important;
        width: 100%;
        max-height: 60px;
        border: 2px solid $uv-blue;
        color: $uv-blue;
        border-radius: $button-border-radius !important;
        background-color: $white;
        margin-left: 0px !important;
        margin-right: 18px !important;
        border-bottom-color: $uv-blue !important;
        @extend .body-txt-lg;
        @include for-phone-only{
            padding: 12px 28px !important;
            max-height: 60px;
        }
    }
    :global(.ant-tabs-nav .ant-tabs-tab-active){
        background-color: $uv-blue; 
    }
    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn){
        color: $white;
    }
}
