@import "../../../styles/main";

.darkHeader {
    background: $primary-color !important;
    .sidebarToggleButton {
        color: $secondary-color !important;
    }
    .headerTabContainer {
        .headerTab {
            .headerTabLink {
                .text {
                    color: $white !important;
                    &:hover {
                        color: $uv-blue-40 !important;
                    }
                }
            }
        }
    }
    .buttonSection {
        .space {
            .verticalSeparator {
                background: $secondary-color !important;
            }
        }
    }
}

.headerContainer {
    background: $secondary-color;
    height: $header-desktop-height;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 0 60px;
    z-index: 999;
    transition: all .45s ease-in-out;
    // box-shadow: #2a2d2633 0px 8px 24px;
    // -webkit-box-shadow: #2a2d2633 0px 8px 24px;
    // -moz-box-shadow: #2a2d2633 0px 8px 24px;
    :global(.ant-col){
        max-height: 100%;
    }
    :global(.ant-row){
        max-width: $header-desktop-max-width;
        width: 100%;
    }
    .sidebarToggleButton {
        border: none;
        color: $primary-color;
        outline: none;
        background: inherit;
        z-index: 1000;
        width: fit-content;
        padding: 0;
    }
    // @include for-both-phone-and-tablet {
    //     padding: 0 20px;
    //     height: $header-xs-height !important;
    // }
    @include for-phone-only {
        height: $header-mobile-height !important;
        display: block;
        padding: 0 16px;
    }
    .logoSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            width: 160px;
            height: 82px;
            // @include for-tablet-portrait-up {
            //     width: 130px;
            //     height: 42px;
            // }
            @include for-phone-only {
                width: 28px;
                height: 22px;
            }
        }
    }
    .headerTabContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // margin: 20px 0;
        .logoSection {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // padding-right: 40px;
            padding-right: 3vw;
            margin-left: -6px;
            // @include for-screen-1367-1199 {
            //     padding-right: 20px;
            // }
            img {
                width: 160px;
                height: 82px;
                // @include for-tablet-portrait-up {
                //     width: 130px;
                //     height: 42px;
                // }
                @include for-phone-only {
                    width: 106.22px;
                    height: 33px;
                }
            }
        }
        .headerTab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            // gap: 20px;
            gap: 24px;
            .headerTabLink {
                // margin: 0 10px;
                .text {
                    color: $secondary-color;
                    @extend .body-txt-md;
                    white-space: pre;
                    letter-spacing: 0.05em !important;
                }
                // @include for-phone-only {
                //     margin: 0 6px;
                // }
            }
        }
    }
    .hexSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }
    .headerHex {
        position: absolute;
        left: 49.5%;
        top: 38%;
        width: auto;
        height: 15px;
        @include for-phone-only {
            height: 22px;
            left: 46%;
            top: 32%;
        }
    }
    .buttonSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        .space {
            // gap: 30px !important;
            gap: 24px !important;

            @include for-both-phone-and-tablet {
                gap: 12px !important;
            }
        
            @include for-phone-only {
                gap: 24px !important;
            }
            .linkText {
                color: $white;
                // font-size: 16px !important;
                @extend .body-txt-md;    
                white-space: pre;
                &:hover {
                    color: $uv-blue-40;
                }
            }
            .protocolButton {
                padding: 8px 28px;
                height: 40px !important;
                @extend .center-flex;
            }
            // .dashboardButton {
            //     width: 176px;
            //     height: 58px;
            //     color: $secondary-color;
            //     border-radius: 12px;
            //     @include for-screen-1367-1199 {
            //         width: 126px;
            //         height: 50px;
            //         border-radius: 5.13px;
            //         font-size: 12px;
            //         padding: 5px;
            //     }
            //     @include for-both-phone-and-tablet {
            //         width: 126px;
            //         height: 30px;
            //         border-radius: 5.13px;
            //         font-size: 12px;
            //         padding: 5px;
            //     }
            // }
            .verticalSeparator {
                width: 35px;
                margin: 0 -15px;
                height: 2px;
                transform: rotate(90deg);
                background: $primary-color;
            }
            .icon {
                width: 20px;
                height: 25px;
                cursor: pointer;
                // @include for-screen-1367-1199 {
                //     width: 21px;
                //     height: 22px;
                // }
                // @include for-phone-only {
                //     width: 21px;
                //     height: 22px;
                // }
            }
        }
    }
    
}
.drawer {

    :global(.ant-drawer-header) {
        border-bottom: none;
        background-color: $night;
    }
    :global(.ant-drawer-close) svg{
        color: $white;
        position: absolute;
        right: 20px;
        top: 28px;
        font-size: 24px;
    }
    :global(.ant-drawer-body){
        background-color: $night;
    }

    :global(.ant-drawer-wrapper-body){
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        background-color: $secondary-color !important;
    }

  .ant-drawer-wrapper-body {
   
    .ant-drawer-header {
      padding: 0 25px !important;
      .ant-drawer-header-title {
        justify-content: end;
      }
    }
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin-top: -20px;
      
    }
  }

  .divider {
        margin: 0;
        background-color: $stone;
      }
      .buttonContainer {
        margin-top: 24px;

        .sidebarButton {
            text-transform: capitalize;
            font-size: 12px;
            line-height: 12px;
            border: 1px solid $secondary-color;
            border-radius: $border-radius;
            color: $secondary-color;
            background: inherit;
            margin-right: 8px;
        }
      }
      
      .sideBarText {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        // letter-spacing: 0.05em;
        color: $secondary-color;
      }

      .sidebarRoutesSection {
        display: flex;
        flex-direction: column;
        // height: 100%;
        gap: 30px;
        .routeUrl {
          display: flex;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          // letter-spacing: 0.05em;
          color: $secondary-color;
          cursor: pointer;
        }
      }
}

.text {
    color: $white;
    &:hover {
        color: $uv-blue-40;
    }
}