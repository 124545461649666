@font-face {
  font-family: "Quincy CF";
  src: local("QuincyCF"), url("../../common/fonts/QuincyCF.otf") format("truetype");
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: local("NeueHaasDisplayRoman"), url("../../common/fonts/NeueHaasDisplayRoman.ttf") format("truetype");
}

@font-face {
  font-family: "Neue Haas Grotesk Light";
  src: local("NeueHaasDisplayLight"), url("../../common/fonts/NeueHaasDisplayLight.ttf") format("truetype");
}

@font-face {
  font-family: "Neue Haas Grotesk Medium";
  src: local("NeueHaasDisplayMediu"), url("../../common/fonts/NeueHaasDisplayMediu.ttf") format("truetype");
}

// fonts.scss
// fonts.scss

@font-face {
  font-family: 'PPNeueMontrealMono Bold';
  src: url('../../common/fonts/PPNeueMontrealMono-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'PPNeueMontrealMono BoldItalic';
  src: url('../../common/fonts/PPNeueMontrealMono-BoldItalic.otf') format('opentype');
  font-weight: bold; /* This should be bold for bold fonts */
  font-style: italic; /* Added font-style for italic */
}

@font-face {
  font-family: 'PPNeueMontrealMono Book';
  src: url('../../common/fonts/PPNeueMontrealMono-Book.otf') format('opentype');
  font-weight: normal; /* This should be normal for regular fonts */
}

@font-face {
  font-family: 'PPNeueMontrealMono Medium';
  src: url('../../common/fonts/PPNeueMontrealMono-Medium.otf') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: 'PPNeueMontrealMono Italic';
  src: url('../../common/fonts/PPNeueMontrealMono-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic; 
}

@font-face {
  font-family: 'PPNeueMontrealMono Thin';
  src: url('../../common/fonts/PPNeueMontrealMono-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'PPEditorialOld Italic';
  src: url('../../common/fonts/PPEditorialOld-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic; 
}

@font-face {
  font-family: 'PPEditorialOld Regular';
  src: url('../../common/fonts/PPEditorialOld-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'PPEditorialOld Ultrabold';
  src: url('../../common/fonts/PPEditorialOld-Ultrabold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'PPEditorialOld UltraboldItalic';
  src: url('../../common/fonts/PPEditorialOld-UltraboldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic; 
}

@font-face {
  font-family: 'PPEditorialOld Ultralight';
  src: url('../../common/fonts/PPEditorialOld-Ultralight.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'PPEditorialOld UltralightItalic';
  src: url('../../common/fonts/PPEditorialOld-UltralightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic; 
}

body {
  margin: 0;
  font-family: "Neue Haas Grotesk Display Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d2a26 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#PHfooter {
  display: none;
}
