@import "../../../styles/main";


$quiz-header-md-height: 33px;

.stickyHeader {
    position: fixed !important;
    top: 0;
    width: 100%;
}

.headerContainer {
    background: $night;
    height: $quiz-header-md-height !important;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-phone-only {
        height: 34px !important;
        display: block;
    }
    .sidebarToggleButton {
        border: none;
        color: $primary-color;
        outline: none;
        background: inherit;
        z-index: 1000;
        width: fit-content;
        padding: 0;
    }
   
    .buttonSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }

    .restartIcon {
        padding-right: 8px;
        padding-bottom: 2px;
    }
    .firstContainer {
        @extend .center-flex;
        .text {
            @extend .body-txt-lg;
            color: $white;
            line-height: 0px;
        }
    }
    .closeIcon {
        position: absolute;
        right: 0;
        height: $quiz-header-md-height !important;
        // border: 1px solid $night;
        width: 36px;
        // padding: 6px;
        cursor: pointer;
        // background: $white;
        @include for-phone-only {
            height: $protocol-header-xs-height;
        }
        // &:hover {
        //     transform: scale(1.1);
        // }
    }
    .quizBox{
        @include for-phone-only{
            padding: 0px 16px;
        }
    }
}
