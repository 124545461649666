@import "@ph/common/styles/main";

.pcrWellContainer {
  display: grid;
  grid-template-columns: 30px repeat(12, 1fr);
  column-gap: 8px;
  row-gap: 8px;
  margin-top: 15px;

  .wellLabel {
    @extend .body-txt-lg;
    text-align: center;
    &.left {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;
    }
  }

  .wellContent {
    aspect-ratio: 1;
    background-color: #fff;
    border-radius: 8px;

    .innerContainer {
      @extend .body-txt-md;
      background-color: #fff;
      border-radius: 8px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &:not(:empty) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    .ativeCell {
      border: 5px solid $night;
    }
    
    &:hover, &.active {
      .innerContainer {
        border: 5px solid $night;
      }
    }
  }
}