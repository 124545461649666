@import "../../styles/main";

.crossSellBox {
    margin-bottom: 16px !important;
}
.bannerContent {
    // padding: 40px;
    // @include for-phone-only {
    //     padding: 16px;
    //     // padding-bottom: 10px;
    // }
    .marqueeSection {
        padding: 65px 0 75px;
        cursor: pointer;
        @include for-phone-only {
            padding: 28px 0;
        }
        .marqueeContent {
            .marqueeImage {
                width: 100%;
                height: 275px;
                // height: 30vh;
                border-radius: 8px;
                margin: 8px;
                @include for-phone-only {
                    width: 162px;
                    height: 114px;
                    border-radius: 4px;
                    margin: 4px;
                }
            }
        }
    }
    .bannerSection {
        padding: 0 40px 40px 40px;
        @include for-phone-only {
            padding: 0 16px 16px 16px;
        }
        .banner {
            // background: url("../../assets/images/beta_banner_wave.png");
            // background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("/assets/images/PH_waves_bg.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-color: $primary-color !important;
            border-radius: 12px;
            // padding: 20px 70px;
            padding: 145px 5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include for-both-phone-and-tablet {
                display: block;
                padding: 2.5rem 24px;
            }
            @include for-phone-only {
                padding: 2rem 0 24px;
            }
            @include for-medium-phone-only {
                background-size: 300% 121%;
                background-position: top;
            }
            @include for-small-phone-only {
                background-size: 300% 121%;
                background-position: top;
            }
            .bannerTitle {
                font-family: "Quincy CF", serif;
                font-style: normal;
                font-weight: 100;
                font-size: $h1-text;
                color: $secondary-color;
                line-height: $h1-line-height;
                @include for-both-phone-and-tablet {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            .colType1 {
                padding: 0 20px 0 0;
                gap: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include for-both-phone-and-tablet {
                    // align-items: center;
                    // text-align: center;
                    gap: 10px;
                }
                @include for-phone-only {
                    padding: 0 1rem 2rem;
                    align-items: center;
                    text-align: center;
                }
                .bannerButton {
                    width: fit-content;
                    height: 50px;
                    border-radius: 12px;
                    color: $primary-color;
                    font-size: $button-text;
                    line-height: $button-text;
                    // @include for-both-phone-and-tablet {
                    //     display: none;
                    // }
                    @include for-phone-only {
                        display: none;
                    }
                    // @include for-phone-only {
                    //     height: 37px;
                    //     font-size: $button-text-mobile-large;
                    // }
                }
            }
            .colType2 {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                @include for-both-phone-and-tablet {
                    // margin: 25px 0;
                }
                @include for-phone-only {
                    // margin: 15px 0;Skin Test Coming Up

                    justify-content: center;
                }
                .bannerImage {
                    width: 98%;
                    object-fit: contain;
                    content: url("../../assets/images/beta_banner.png");
                    @include for-both-phone-and-tablet {
                        width: 205px;
                        height: auto;
                    }
                    @include for-phone-only {
                        object-fit: contain;
                        content: url("../../assets/images/beta_banner_mobile.png");
                    }
                    // @include for-medium-phone-only {
                    //     width: auto;
                    //     content: url("/assets/images/PH_CSB_mobile.svg");
                    // }
                    // @include for-small-phone-only {
                    //     width: auto;
                    //     content: url("/assets/images/PH_CSB_mobile.svg");
                    // }
                }
            }
            .bannerButtonSmall {
                width: fit-content;
                height: 60px;
                border-radius: 12px;
                margin-top: 20px;
                font-size: $button-text;
                line-height: $button-text;
                display: none;
                color: $primary-color;
                // @include for-both-phone-and-tablet {
                //     display: block;
                // }
                @include for-phone-only {
                    display: block;
                    height: 40px;
                    font-size: $button-text-mobile-large;
                    line-height: $button-text-mobile-large;
                    width: 60%;
                    margin: 40px auto 0;
                    border-radius: 4px;
                }
            }
        }
    }
}
