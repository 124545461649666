@import "@ph/common/styles/main";

.section {
	// height: 100%;
	// overflow: auto;
	overflow-y: auto !important;
	overflow-x: clip !important;

	.container {
		// max-width: 1440px;
		overflow-y: auto !important;
		overflow-x: clip !important;
		min-height: calc(100vh - #{$header-md-height});
		position: relative;
		// padding-top: $header-md-height;
		// padding-bottom: calc(#{$footer-md-height} + #{$footer-subs-md-height});
		// padding-bottom: 16px;
		display: flex;
		justify-content: center;
		margin: $header-md-height auto 0;
		padding: 10px;
		background-color: $secondary-color !important;
		// background: url("../../../common/assets/images/PH_waves_bg.svg") no-repeat top center;
		background-size: 100vw;
		margin: 90px auto 0px auto;

		.ant-spin:not(.light-theme) .ant-spin-dot-item {
			background-color: $uv-blue;
		}

		// @include for-phone-only {
		//   // min-height: calc(100vh - (#{$footer-xs-height} + #{$footer-subs-xs-height}));
		//   // padding-top: $header-xs-height;
		//   // padding-bottom: calc(#{$footer-xs-height} + #{$footer-subs-xs-height});
		//   // padding-bottom: 16px;
		//   // margin-top: $header-xs-height;
		// }
	}
	.reportContainer,
	.reportContainer1 {
		// min-height: 100vh;
		overflow-y: auto !important;
		overflow-x: clip !important;
		position: relative;
		display: flex;
		justify-content: center;
		background-color: $secondary-color !important;
		margin-top: $header-md-height;
		border-bottom: 1px solid $sand;
		padding-bottom: 100px;
		min-height: calc(100vh - (#{$header-md-height} + #{$header-md-height}));
		@include for-phone-only {
			margin-top: $header-xs-height;
			border-top: none;
			padding-bottom: 40px;
			min-height: calc(
				100vh - ((#{$footer-xs-height} - 450px) + #{$header-xs-height})
			);
		}

		.ant-spin-dot-item {
			background-color: $uv-blue !important;
		}
	}
	.reportContainer1 {
		background-color: $night !important;
		margin-top: 0px;
		padding-top: 80px;
    max-width: 1440px;
    margin: auto;
		border-bottom: none;
		@include for-phone-only {
			margin-top: 0px !important;
			padding-top: 30px;
		}
	}
}

.admin-portal-main-content-wrapper {
	background: $day;
	min-height: calc(100vh - 90px) !important;
	margin: 0;
	padding: 15px 20px;
}

.clickable-link {
	@extend .hand-cursor;
	color: $walnut;
}

.main-search-input {
	border: 1px solid $night !important;
	border-radius: 4px !important;
	padding: 8px 10px !important;
	background-color: transparent !important;
	width: auto;

	img {
		width: 20px;
		height: 20px;
	}

	input {
		@extend .body-txt-lg;
		color: $night;
		background-color: transparent;
		padding-left: 5px;
		position: relative;
		top: 1.25px;

		&::placeholder,
		&:placeholder-shown {
			color: $night;
		}
	}
}

.lab-portal-table {
	background-color: $secondary-color;

	.ant-table {
		border-top: 2px solid $sand;
		border-left: 2px solid $sand;
		border-right: 2px solid $sand;
		border-radius: 4px;
	}

	.ant-table-thead > tr > th {
		background-color: $sand;
		color: $primary-color;
		font-family: "Neue Haas Grotesk Display Pro", serif;
		font-weight: 600;
	}

	.ant-table-thead > tr > th.ant-table-column-has-sorters {
		&:hover,
		&:focus,
		&:active {
			background-color: $sand;
		}

		color: $walnut !important;
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: transparent;
	}

	.ant-table-tbody > tr > td {
		border-bottom: 2px solid $sand;
		background-color: $secondary-color;
	}

	.show-on-hover {
		display: none;
	}

	.hide-on-hover {
		display: block;
	}

	.invisible-on-hover {
		animation: 0s fadeIn;
		animation-fill-mode: forwards;
		visibility: visible;
	}

	.visible-on-hover {
		animation: 0s fadeOut;
		animation-fill-mode: forwards;
		visibility: hidden;
	}

	.ant-table-cell-row-hover {
		@include background-opacity($sand, 0.75);

		.invisible-on-hover {
			animation: 2s fadeOut;
			animation-fill-mode: forwards;
			visibility: hidden;
		}

		.visible-on-hover {
			animation: 2s fadeIn;
			animation-fill-mode: forwards;
			visibility: visible;
		}

		.show-on-hover {
			display: block;
		}

		.hide-on-hover {
			display: none;
		}
	}

	.ant-table-expanded-row > td,
	.ant-table-expanded-row:hover > td {
		background-color: $secondary-color;
	}

	/* Target the scrollbar track (the background) */
	::-webkit-scrollbar {
		width: 4px; /* Set the width of the scrollbar */
	}

	/* Target the scrollbar thumb (the draggable part) */
	::-webkit-scrollbar-thumb {
		background-color: #cec3c1; /* Change the thumb color */
		border-radius: 8px; /* Round the edges of the thumb */
	}

	/* Target the scrollbar track (the background) on hover */
	::-webkit-scrollbar-thumb:hover {
		background-color: $day; /* Change the thumb color on hover */
	}

	/* Target the scrollbar track (the background) */
	::-webkit-scrollbar-track {
		background-color: #cec3c1; /* Change the track color */
	}
}

.ant-tooltip-inner {
	color: $day !important;
}

@keyframes fadeIn {
	99% {
		visibility: hidden;
	}
	100% {
		visibility: visible;
	}
}

@keyframes fadeOut {
	99% {
		visibility: visible;
	}
	100% {
		visibility: hidden;
	}
}


.default-diagram {
	fill: #dee0e0;
	stroke-width: 0px;
}