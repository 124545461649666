@import "../../styles/main";

.container {
    margin: 60px 30px 0px 30px;
    display: grid;
    align-content: space-between;
    // justify-content: center;
    width: 100%;
    @include for-phone-only {
        margin: 40px 0px 0px 0px;
    }
    .dashboardContent {
        padding: 0px 16px;
        // @include for-both-phone-and-tablet {
        //     padding: 0 3.5rem !important;
        // }
        // @include for-phone-only {
        //     padding: 0 12px !important;
        // }
    }
    .userTitle {
        font-family: "Neue Haas Grotesk Light" !important;
        font-style: normal;
        font-weight: 100;
    }
    .userSubtitle {
        font-style: normal;
        font-weight: 500;
        font-size: $h4-text;
        line-height: $h4-line-height;
        letter-spacing: 0.05em;
        color: $secondary-color;
        @include for-phone-only {
            font-size: $h4-text-mobile;
            line-height: $h4-line-height-mobile;
        }
    }
    .kitUserGuideBox {
        margin-bottom: 20px !important;
    }
    .kitUseGuideSection {
        background: $white;
        padding: 40px;
        text-align: center;
        @include for-phone-only {
            padding: 20px;
        }
        .text {
            font-family: "Neue Haas Grotesk Light" !important;
            font-style: normal;
            font-weight: 100;
            @extend .h4;
            color: $night;
        }
        .button {
            height: $button-height;
            background-color: $night !important;
            color: $white !important;
            border-radius: $button-border-radius !important;
            margin-top: 20px;
            font-size: $button-text;
            line-height: $button-text;
            font-weight: 500 !important;
            @include for-phone-only {
                width: fit-content;
                height: $button-height-mobile;
                font-size: $button-text-mobile-large;
                line-height: $button-text-mobile-large;
            }
        }
    }
}

.skinTestProdName {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 14px;

    @include for-both-phone-and-tablet {
        font-size: 16px;
        line-height: 24px;
    }
}

.kitShowCaseImgContainer {
    height: 220px !important;
    max-width: 220px !important;

    img {
        width: 100%;
    }
}
.insideContainer {
    margin: 60px 25px 60px; 
    @include for-both-phone-and-tablet {
        margin: 25px 16px 25px;
    }
}
.whiteLink {
    color: $day !important;
    text-decoration: underline !important;
}