@import "../../../styles/main";

.card {
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: none;

  .cardImgHolder {
    @extend .img-container-square;
    height: 285px;
    position: relative;
    @include for-desktop-up {
      max-width: 285px;
      border-radius: 6px;
    }
    @include for-both-phone-and-tablet {
      border-radius: 0;
      text-align: center;
    }

    .tagPill {
      box-sizing: border-box;
      background-color: $bright-terra;
      border-radius: 25px;
      color: $secondary-color;
      font-family: "Neue Haas Grotesk Display Pro", sans-serif;
      font-weight: bold;
      font-size: $h5-text;
      line-height: $h5-line-height;
      letter-spacing: 1px;
      margin: 8px 0 0;
      padding: 10px 0;
      margin-inline-end: 8px;
      padding-inline: 15px;
      height: auto;
      white-space: nowrap;
      opacity: 1;
      transition: all 0.2s;
      text-align: start;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .cardTextBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    padding: 0 0 0 30px;
    @include for-both-phone-and-tablet {
      background-color: $sand;
      border-radius: 0 0 4px 4px;
      display: block;
      padding: 24px;
      margin: 0 0 10px !important;
      min-height: 100px;
    }

    .cardTitle {
      font-family: "Neue Haas Grotesk Display Pro", sans-serif;
      @include getFontSizeMapFor('h4');
      color: $night;
      font-style: normal;
      font-weight: 500;
      flex: 0.5;
      width: 100%;
      padding-right: 5px;
      @include for-both-phone-and-tablet {
        font-weight: 500;
        padding: 0;
        margin-bottom: 25px !important;
      }
    }

    .cardDescriptionWrapper {
      @include for-desktop-up {
        padding-right: 5px;
      }
      width: 100%;
    }

    .cardDescription {
      @extend .body-txt-lg;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: $night;
      margin-bottom: 25px;
      span {
        color: $night;
      }
      @include clipTextToNLines(3);
      @include for-both-phone-and-tablet {
        font-weight: 500;
        margin-bottom: 25px;
      }
    }

    .cardActionBtn {
      width: fit-content;
      height: 60px;
      border-radius: 12px;
      font-size: $button-text;
      line-height: $button-text;
      padding: 16px 30px;
      @include for-both-phone-and-tablet {
        height: 40px;
        width: 100%;
        border-radius: 4px;
        font-size: $button-text-mobile-large;
        font-weight: 500;
        padding: 10px 20px;
      }
    }
  }
}