@import "../../styles/main";

.container {
    height: 100vh;
    width: 100vw;
    .featureSection {
        display: flex;
        align-items: flex-start;
        width: 100%;
        background-color: $white;
        .featureImageBigScreen {
            height: 100vh;
            width: 100%;
            object-fit: cover;
            // margin-left: -46px ;
            // @media (max-width: 1440px) {
            //     margin-left: -90px ;
            // }
            // @media (max-width: 1300px) {
            //     margin-left: -140px ;
            // }
            @include for-both-phone-and-tablet {
                display: none;
            }
        }
        .featureImageSmallScreen {
            display: none;
            // width: 50vw;
            height: 350px;
            margin: 2vh auto auto auto;
            border-radius: 0px;
            @include for-both-phone-and-tablet {
                display: flex;
            }
            @include for-phone-only {
                width: 100vw;
                height: auto;
                margin: auto;
                border-radius: 0;
            }
        }
    }
    .contentSection {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        text-align: center;
        align-items: center;
        overflow-y: auto;
        overflow-x: clip;
        position: relative;
        padding-top: 12vh;
        max-height: 100vh;
        background-color: $white;

        @include for-both-phone-and-tablet {
            gap: 8px;
            max-height: fit-content;
            padding: 30px 3vw 0 3vw;
            background-color: $white;
            // background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("/assets/images/PH_waves_bg.svg");
            // background: url("../../assets/images/PH_waves_bg.svg") no-repeat;
            // background-size: cover;
            // background-clip: border-box;
        }
        .logo {
            max-width: 31%;
            @include for-phone-only {
                max-width: 184px;
            }
        }
        .verticalSpace {
            margin-top: 2vh;
            @include for-phone-only {
                margin-top: 0;
            }
        }
        :global .otp-input {
            @include for-phone-width-297 {
                margin-right: -4px;
            }
            @include for-desktop-up {
                input {
                    width: 67px !important;
                    height: 46px !important;
                }
            }
        }
        .kitTypeContainer {
            min-height: $h4-line-height;
            @include for-phone-only {
                min-height: $h4-line-height-mobile;
            }
        }
        .welcomeText {
            color: $primary-color;
            @extend .body-txt-lg;
            padding-top: 15px;
        }
        .titleText {
            font-style: normal;
            color: $primary-color;
            margin: 0px 0px 20px 0px !important;
            @include getFontSizeMapFor('h3');
            @include for-phone-only {
                padding: 0 10px;
            }
        }
        .textType3 {
            color: $primary-color;
            @extend .body-txt-lg;
        }
        .textType4 {
            color: $primary-color;
            @extend .body-txt-md;
        }
        .continueButtonSection {
            width: 670px;
            @include for-phone-only {
                width: 95vw;
            }
            .continueButton {
                width: fit-content;
                height: $button-height;
                padding: 0 30px;
                font-size: $button-text;
                line-height: $button-text;
                @include for-phone-only {
                    font-size: $button-text-mobile;
                    line-height: $button-text-mobile;
                    height: $button-height-mobile;
                }
            }
            .continueButton:disabled {
                background: $stone !important;
                color: grey !important;
            }
        }
        .linkButton {
            width: fit-content;
            color: $primary-color;
            padding: 0;
        }
        .loginButton {
            width: fit-content;
            height: $button-height;
            padding: 0 30px;
            font-size: $button-text;
            line-height: $button-text;
            background: $infrared;
            color: $secondary-color;
            @include for-phone-only {
                font-size: $button-text-mobile;
                line-height: $button-text-mobile;
                height: $button-height-mobile;
            }
        }
        .boldLink {
            color: $uv-blue !important;
            @extend .body-txt-lg;
            font-weight: bold;
        }
        .linkText {
            color: $night !important;
            &:hover {
                text-decoration: underline;
            }
        }
        .addUserImage {
            width: 60px;
            @include for-phone-only {
                width: 40px;
            }
        }
        .separator {
            height: 1px;
            background-color: $walnut;
            width: 670px;
            margin: 2vh 0;
            @include for-desktop-up {
                border-top: 1px solid $walnut;
            }
            @include for-phone-only {
                margin: 1vh 0;
                width: 100%;
            }
        }
        .loginSection {
            display: flex;
            flex-direction: row;
            gap: 16px;
            @include for-phone-only {
                flex-direction: column;
            }
            .accountSection1 {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                @include for-phone-only {
                    justify-content: center;
                }
            }
            .accountSection2 {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include for-phone-only {
                    justify-content: center;
                }
            }
        }
        .policy {
            position: absolute;
            bottom: 3vh;
            gap: 5vh !important;
            @include for-both-phone-and-tablet {
                position: relative;
                margin-top: 6vh;
                gap: 2vh !important;
            }
            @include for-screen-height-800 {
                position: relative;
                margin-top: 6vh;
            }
        }
    }
}
