/*
Primary account colors
*/
$primary-color: #2D2926;
$secondary-color: #FFFFFF;
$sand: #f5f1f0;
$stone: #f5f1f0;
$bright-terra: #fd8272;
$walnut: #6d3628;
$crest: #9aaaa9;
$white: #FFFFFF;
$errorText: #FF677B;
$day: #FFFFFF;
$day-cream: #FFFFFF;
$night: #2D2926;
$light-dark: #494642;
$uv-blue: #2E5ACC;
$uv-blue-20: #D5DEF5;
$uv-blue-40: #ABBDEA;
$uv-blue-75: #6283D8;

$infrared: #FF677B;
$infrared-20: #FFE1E5;
$infrared-40: #FFC2CA;
$infrared-75: #FF8D9C;

$header-md-height: 90px;
$header-desktop-height: 68px;
$header-desktop-max-width: 1540px;
$header-mobile-height: 52px;
$protocol-header-md-height: 37.5px;
$protocol-header-xs-height: 40px;
$quiz-header-md-height: 42px;
$quiz-header-xs-height: 44px;
// $header-xs-height: 69px;
// $footer-md-height: 263px;
// $footer-md-height: 336px;
// $footer-xs-height: 308px;
// $footer-xs-height: 44px;
$footer-subs-md-height: 180px;
$footer-subs-xs-height: 180px;

//beta
$footer-md-height: 310px;
$footer-xs-height: 60px;
$header-xs-height: 70px;
$header-sm-height: 60px;

$big-text: 72px;
$h1-text: 72px;
$h2-text: 48px;
$h3-text: 40px;
$h4-text: 30px;
$h5-text: 24px;
$h6-text: 22px;
$body-text-lg: 18px;
$body-text-med: 14px;
$button-text: 14px;
$button-height: 48px;
$button-border-radius: 42px;

$note-1-text: 15px;
$note-2-text: 12px;
$note-3-text: 10px;
$note-4-text: 12px;

$quiz-1-text: 26.5px;
$quiz-2-text: 18px;

$big-text-mobile: 50px;
$h1-text-mobile: 48px;  //28px
$h2-text-mobile: 34.5px;
// $h2-text-mobile: 20px;
$h3-text-mobile: 24px;
$h4-text-mobile: 20px;
$h5-text-mobile: 18px;
$h6-text-mobile: 17px;
$body-text-mobile-lg: 16px;
$body-text-mobile-med: 14px;
$body-text-mobile-small: 12px;
$button-text-mobile: 12px;
$button-text-mobile-large: 14px;
$button-height-mobile: 42px;
$button-border-radius-mobile: 42px;
$note-1-text-mobile: 14px;
$note-2-text-mobile: 12.5px;
$note-3-text-mobile: 11px;
$note-4-text-mobile: 10px;

$quiz-1-text-mobile: 20px;
$quiz-2-text-mobile: 16px;

$big-text-line-height: 73px;
$h1-line-height: 79px;
$h2-line-height: 57px;
$h3-line-height: 48px;
$h4-line-height: 36px;
$h5-line-height: 28px;
$h6-line-height: 31.5px;
$body-line-height-lg: 27px;
$body-line-height-med: 20px;
$note-1-line-height: 21px;
$note-2-line-height: 18px;
$note-3-line-height: 15px;
$note-4-line-height: 12px;

$quiz-1-line-height: 39px;
$quiz-2-line-height: 27px;

$big-line-height-mobile: 53px;
$h1-line-height-mobile: 48px;
$h2-line-height-mobile: 38px;
$h3-line-height-mobile: 36px;
$h4-line-height-mobile: 26px;
$h5-line-height-mobile: 20px;
$h6-line-height-mobile: 24px;
$body-line-height-mobile-lg: 24px;
$body-line-height-mobile-med: 21px;
$body-line-height-mobile-small: 18px;
$note-1-line-height-mobile: 16px;
$note-2-line-height-mobile: 15px;
$note-3-line-height-mobile: 12.4px;
$note-4-line-height-mobile: 12px;

$quiz-1-line-height-mobile: 26px;
$quiz-2-line-height-mobile: 24px;
$fontSizeMaps: (
  "h1": $h1-text,
  "h1-line-height": $h1-line-height,
  "h1-mobile": $h1-text-mobile,
  "h1-mobile-line-height": $h1-line-height-mobile,
  "h2": $h2-text,
  "h2-line-height": $h2-line-height,
  "h2-mobile": $h2-text-mobile,
  "h2-mobile-line-height": $h2-line-height-mobile,
  "h3": $h3-text,
  "h3-line-height": $h3-line-height,
  "h3-mobile": $h3-text-mobile,
  "h3-mobile-line-height": $h3-line-height-mobile,
  "h4": $h4-text,
  "h4-line-height": $h4-line-height,
  "h4-mobile": $h4-text-mobile,
  "h4-mobile-line-height": $h4-line-height-mobile,
  "h5": $h5-text,
  "h5-line-height": $h5-line-height,
  "h5-mobile": $h5-text-mobile,
  "h5-mobile-line-height": $h5-line-height-mobile,
  "h6": $h6-text,
  "h6-line-height": $h6-line-height,
  "h6-mobile": $h6-text-mobile,
  "h6-mobile-line-height": $h6-line-height-mobile,
  "note-1": $note-1-text,
  "note-1-line-height": $note-1-line-height,
  "note-1-mobile": $note-1-text-mobile,
  "note-1-mobile-line-height": $note-1-line-height-mobile,
  "note-2": $note-2-text,
  "note-2-line-height": $note-2-line-height,
  "note-2-mobile": $note-2-text-mobile,
  "note-2-mobile-line-height": $note-2-line-height-mobile,
  "note-3": $note-3-text,
  "note-3-line-height": $note-3-line-height,
  "note-3-mobile": $note-3-text-mobile,
  "note-3-mobile-line-height": $note-3-line-height-mobile,
  "body-lg": $body-text-lg,
  "body-lg-line-height": $body-line-height-lg,
  "body-lg-mobile": $body-text-mobile-lg,
  "body-lg-mobile-line-height": $body-line-height-mobile-lg,
  "body-md": $body-text-med,
  "body-md-line-height": $body-line-height-med,
  "body-md-mobile": $body-text-mobile-med,
  "body-md-mobile-line-height": $body-line-height-mobile-med,
  "quiz-1": $quiz-1-text,
  "quiz-1-line-height": $quiz-1-line-height,
  "quiz-1-mobile": $quiz-1-text-mobile,
  "quiz-1-mobile-line-height": $quiz-1-line-height-mobile,
  "quiz-2": $quiz-2-text,
  "quiz-2-line-height": $quiz-2-line-height,
  "quiz-2-mobile": $quiz-2-text-mobile,
  "quiz-2-mobile-line-height": $quiz-2-line-height-mobile,
);

$screen-xs: 480px;
$screen-xs-min: 400px;
$screen-sm: 576px;
$screen-sm-min: 576px;
$screen-sm-max: 749px;
$screen-md: 768px;
$screen-md-min: 768px;

// for beta
// $screen-sm-max: 749px;
// $screen-md: 750px;
// $screen-md-min: 750px;

$screen-lg: 992px;
$screen-lg-min: 992px;
$screen-xl: 1200px;
$screen-xl-min: 1200px;
$screen-xxl: 1600px;
$screen-xxl-min: 1600px;
$screen-xs-max: 542px;
$screen-sm-max: 767px;

// // for beta
// $screen-sm-max: 749px;

$screen-md-max: 991px;
$screen-lg-max: 1199px;
$screen-xl-max: 1599px;

$border-radius: 4px;