@import "@ph/common/styles/main";

.quizFaceArea {
	width: 100%;

	.titleContainer {
		@extend .center-flex;
		height: 100%;
		width: 100%;
        gap: 20px;
        padding: 0px 30px;
        flex-direction: column;
		.title {
			@extend .quiz-1;
            text-align: center;
		}

        @include for-phone-only{
            width: 100%;
            gap: 12px;
            text-align: center;
        }
	}
    .faceAreaButton {
        min-width: 214px;
        height: 30px !important;
        @extend .center-flex;
        box-shadow: none;

        span {
            @extend .note-1;
            @extend .font-Mono-medium;
            text-transform: uppercase;
        }

        &:hover {
            color: $night;
            border-color: $night;
        }

        &.selected {
            border: 1px solid $night !important;
        }
    }
    .faceContainer {
        width: 100%;
        @extend .center-flex;

        @include  for-phone-only{
            svg {
                height: 280px;
                margin: 20px 0px;
            }
        }
    }
}
