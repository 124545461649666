@import "../../../styles/main";

.footerContainer {
    overflow: hidden;
    /*height: $footer-md-height;
     position: absolute;
     bottom: 0;
     left: 0;
     width: 100vw;
     display: flex;
     justify-content: flex-start;
     align-items: center;
    @include for-phone-only {
         height: $footer-xs-height;
    }*/

    .bigFooter {
        background: $primary-color;
        // height: $footer-md-height;
        padding: 70px 60px;
        @include for-both-phone-and-tablet {
            padding: 24px 16px 8px 16px;
        }
        @include for-phone-only {
            // height: $footer-xs-height;
            // display: none;
        }
        .navSection {
            display: flex;
            align-items: center;
            justify-content: right;
            @include for-both-phone-and-tablet {
               justify-content: center;
            }
        }
        .logoSection {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            z-index: 999;
            /*.appName {
                font-family: "Quincy CF";
                font-style: normal;
                font-weight: 100;
                font-size: 64px;
                color: #ffffff;
                @include for-phone-only {
                    font-size: 30px;
                }
            }*/
            img {
                width: 170px;
                height: 82px;
                z-index: 999999;
                @include for-tablet-portrait-up {
                    width: 130px;
                    height: 42px;
                }
                @include for-phone-only {
                    width: 128px;
                    height: 42px;
                }
            }
            @include for-phone-only {
                justify-content: left;
            }
        }
        .footerTabContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            // margin: 20px 0;
            .footerTab {
                display: flex;
                flex-direction: row;
                justify-content: center;
                // margin: 20px 0;
                margin: 20px 0 20px 2vw;
                gap: 2vw;
                .footerTabLink {
                    // margin: 0 10px;
                    .text {
                        color: $secondary-color !important;
                        // font-size: 16px !important;
                        @extend .body-txt-md;
                        font-weight: 600 !important;
                       
                        white-space: pre;
                        // @include for-tablet-portrait-up {
                        //     font-size: 10px !important;
                        //     // line-height: 5px;
                        //     letter-spacing: 0.05em !important;
                        // }
                    }
                    @include for-phone-only {
                        margin: 0 6px;
                    }
                }
                @include for-phone-only {
                    margin: 8px 0;
                }
            }
            @include for-both-phone-and-tablet {
                display: block;
            }
        }
        .buttonSection {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .questionButton {
                padding: 10px 28px;
                height: 56px;
                background: #e2e0df;
                border-radius: 12px;
                font-size: $button-text;
                font-weight: 500;
                line-height: $button-text;
                @include for-phone-only {
                    width: 126px;
                    height: 40px;
                    border-radius: 4px;
                    font-size: 14px;
                    line-height: $button-text-mobile;
                    padding: 5px;
                }
            }
            @include for-phone-only {
                justify-content: center;
            }
        }
        .policySection {
            .separator {
                width: 100%;
                background-color: $secondary-color;
                opacity: 0.5;
                height: 1px;
                margin: 38px 0;
                @include for-phone-only {
                    margin: 16px 0;
                }
            }
            .text {
                // font-size: 14px !important;
                @extend .body-txt-md;
                // line-height: 36px;
                
                color: $secondary-color !important;
                opacity: 0.5 !important;
                white-space: nowrap;
                // @include for-tablet-portrait-up {
                //     font-size: 12px !important;
                // }
                
            }
            .URLSection {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                @include for-tablet-portrait-up {
                    justify-content: center;
                }
                @include for-phone-only {
                    justify-content: center;
                }
            }
            .socialIconSection {
                display: flex;
                align-items: center;
                justify-content: center;
                @include for-both-phone-and-tablet {
                    justify-content: flex-start;
                }
                @include for-tablet-portrait-up {
                    justify-content: center;
                }
                @include for-phone-only {
                    justify-content: center;
                }
                .icon {
                    // font-size: 20px;
                    // color: $white;
                    margin: 10px;
                    height: 21px;
                    // @include for-phone-only {
                    //     font-size: 30px;
                    // }
                }
            }
            .linkSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                gap: 2vw;
                .footerLink {
                    // margin: 0 10px;
                    // @include for-phone-only {
                    //     margin: 0 5px;
                    // }
                }
                @include for-phone-only {
                    justify-content: space-between;
                }
                @include for-tablet-portrait-up {
                    justify-content: space-between;
                }
            }
            @include for-phone-only {
                margin: 20px 0 -5px 0;
            }
        }
    }
    .smallFooter {
        display: none;
        background: $day;
        height: $footer-xs-height;
        border-top: 0.25px solid $sand;
        position: fixed;
        bottom: 0;
        z-index: 99;
        @include for-phone-only {
            display: flex;
        }
        .separator {
            height: $footer-xs-height;
            width: 0.25px;
            margin: 0;
            background: $sand;
        }
        .footerSpace {
            gap: 0 !important;
            height: inherit !important;
        }
        .footerBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25vw;
            padding: 6px 0;
            gap: 6px;
            .footerIcon {
                width: 28px;
                height: 24px;
            }
            .footerDashboardIcon {
                width: 22px;
                height: 21px;
            }
            .footerText {
                font-size: $h6-text-mobile;
                line-height: $h6-text-mobile;
                color: $night;
                text-align: center;
                letter-spacing: 0.693548px;
            }
            .footerTextActive {
                color: $bright-terra !important;
            }
        }
    }
}
.separator {
    width: 100%;
    background-color: $secondary-color;
    opacity: 0.5;
    height: 1px;
    margin: 38px 0;
    @include for-phone-only {
        margin: 16px 0;
    }
}

.footerNav {
    margin-top: 12px;
}

.socialIconSection {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    .socialContainer {
        margin-left: -18px;
    }
    @include for-both-phone-and-tablet {
        align-items: center;
        justify-content: center;
    }
    .icon {
        // font-size: 20px;
        // color: $white;
        margin: 10px;
        height: 21px !important;
        width: 40px !important;
        // @include for-phone-only {
        //     font-size: 30px;
        // }
    }
}
.linkContainer {
    margin-top: 18px;  
}
.textlink {
    margin-top: 12px;
    color: $white;
    @extend .body-txt-md;
    @include for-both-phone-and-tablet {
        font-size: 12px;
    }
    &:hover {
        color: $uv-blue-40;
    }
}