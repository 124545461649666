.hideHeaderInSmallScreen {
	@media (max-width: 767px) {
		display: none;
	}
}

.hideHeaderInBigScreen {
	@media (min-width: 768px) {
		display: none !important;
	}
}

.viewBoxWidth {
	width: 100%;
	padding: 0 5rem;
	@include for-both-phone-and-tablet {
		width: 100vw;
		padding: 0 3.5rem;
		// padding: 0 15px;
	}
	@include for-phone-only {
		padding: 0 1.5rem;
	}
}

.smallViewBoxWidth {
	width: 100%;
	padding: 0 2rem;
	@include for-both-phone-and-tablet {
		width: 100vw;
		padding: 0 3.5rem;
		// padding: 0 15px;
	}
	@include for-phone-only {
		padding: 0 1.5rem;
	}
}

.light-theme .ant-spin-dot-item {
	background-color: $uv-blue;
}

.ant-btn {
	border-radius: $button-border-radius !important;
	font-size: $button-text !important;
	line-height: $button-text !important;
	width: fit-content;
	height: $button-height !important;
	padding: 16px 30px !important;
	@include for-phone-only {
		height: $button-height-mobile !important;
		font-size: $button-text-mobile !important;
		line-height: $button-text-mobile !important;
		padding: 4px 15px !important;
	}
}

.ant-btn-primary {
	background: $night;
	color: $day !important;
	font-weight: 500 !important;
	border-radius: $button-border-radius !important;

	&:hover,
	&:active,
	&:focus {
		background: $night !important;
	}

	&[disabled],
	&:disabled {
		@include background-opacity($night, 0.75);
	}
}

.ant-btn-secondary {
	background: $white !important;
	color: $night !important;
	font-weight: 500 !important;
	border: 1px solid $night;
	&.transparent {
		background: transparent !important;
	}
	&:hover,
	&:active,
	&:focus {
		background: $white !important;
	}
}

.ph-btn {
	border-radius: 12px;
	box-shadow: none !important;
	font-weight: 600;
	font-size: 18px;
	height: 45px !important;
	width: fit-content;
	padding: 10px 30px !important;
}

.ph-btn.large {
	height: 50px !important;
	padding: 10px 40px !important;
}

.ph-btn.transparent {
	background-color: transparent !important;
	border: 1px solid $night !important;

	&:hover,
	&:active,
	&:focus {
		background: transparent !important;
	}
}

.ph-btn.transparent.dark-txt {
	color: $night !important;
}

.ph-btn.ph-btn-success {
	background-color: $crest !important;

	&:hover,
	&:active,
	&:focus {
		background: $crest !important;
	}
}

.ph-btn.ph-btn-danger {
	background-color: $bright-terra !important;

	&:hover,
	&:active,
	&:focus {
		background: $bright-terra !important;
	}
}

.ph-btn.no-border {
	border: 0 !important;
}

.ph-btn-selectable {
	background-color: #fff !important;
	border-radius: 4px !important;
	padding: 8px !important;
	width: 100% !important;
	height: 45px !important;
}

.ph-btn-selectable.selected {
	background-color: $night !important;
	color: $day !important;
}

.ph-btn.ant-btn-primary[disabled],
.ph-btn.ant-btn-primary[disabled]:hover,
.ph-btn.ant-btn-primary[disabled]:focus,
.ph-btn.ant-btn-primary[disabled]:active {
	background-color: $night !important;
	opacity: 0.5;
}

/*.marquee {
  // min-width: 100% !important;
  --duration: 30.903s !important;
}*/

.ant-typography,
.ant-typography p {
	margin-bottom: 0 !important;
}

// .ant-drawer {
//   z-index: 1010 !important;
//   .ant-drawer-wrapper-body {
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: top center;
//     background-color: $secondary-color !important;
//     .ant-drawer-header {
//       padding: 0 25px !important;
//       .ant-drawer-header-title {
//         justify-content: end;
//         .ant-drawer-close {
//           margin-right: 0;
//           padding: 0;
//           margin-top: 20px;
//           z-index: 10;
//           color: #2d2a26;
//         }
//       }
//     }
//     .ant-drawer-body {
//       display: flex;
//       flex-direction: column;
//       padding: 0;
//       margin-top: -20px;
//       .divider {
//         margin: 0;
//         background-color: $stone;
//       }
//       .sidebarButton {
//         text-transform: capitalize;
//         font-size: 12px;
//         line-height: 12px;
//         width: 100%;
//         height: 40px;
//         border: 1px solid $secondary-color;
//         border-radius: $border-radius;
//         color: $secondary-color;
//         font-weight: 600;
//         background: inherit;
//       }
//       .sideBarText {
//         font-weight: 500;
//         font-size: 18px;
//         line-height: 24px;
//         // letter-spacing: 0.05em;
//         color: $secondary-color;
//       }

//       .sidebar-routes-section {
//         display: flex;
//         flex-direction: column;
//         // height: 100%;
//         gap: 30px;
//         padding: 25px;
//         .route-url {
//           display: flex;
//           font-weight: 500;
//           font-size: 14px;
//           line-height: 20px;
//           // letter-spacing: 0.05em;
//           color: $secondary-color;
//           cursor: pointer;
//         }
//       }
//     }
//   }
// }

.verticalStep {
	.ant-steps-vertical
		> .ant-steps-item
		> .ant-steps-item-container
		> .ant-steps-item-tail {
		top: -18px !important;
		left: 10px !important;
		padding: 38px 0 10px !important;
		background: #302e2b !important;
		@media (max-width: 767px) {
			left: 6px !important;
		}
		// @media (max-width: 1199px) {
		//   left: 1px !important;
		// }
	}

	.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
		margin-top: -20px !important;
	}

	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-description {
		color: $night !important;
		margin-left: 120px !important;
		margin-top: -39px;
		@media (max-width: 767px) {
			margin-left: 48px !important;
		}
	}

	.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
		overflow: visible !important;
		@media (max-width: 767px) {
			min-height: 70px !important;
		}
	}

	.ant-steps-vertical > .ant-steps-item {
		overflow: visible !important;
		height: 60px !important;
	}

	.ant-steps-vertical
		> .ant-steps-item
		> .ant-steps-item-container
		> .ant-steps-item-tail::after {
		background-color: #302e2b !important;
	}
}

.horizontalStepKitFlow {
	@include for-both-phone-and-tablet {
		display: none;
	}
	.ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #302e2b !important;
	}
	.ant-steps-label-vertical .ant-steps-item-tail {
		padding: 3.5px 0 !important;
	}
	.ant-steps:not(.ant-steps-vertical)
		.ant-steps-item-custom
		.ant-steps-item-icon {
		margin-left: 57px;
	}
	.ant-steps-label-vertical .ant-steps-item {
		overflow: visible;
		width: 44px;
	}
	.ant-steps-label-vertical .ant-steps-item-content {
		margin-left: 17px;
	}
	.status-icon-container {
		.ant-steps-item-icon {
			margin-left: 40px !important;
		}
		.ant-steps-item-content {
			margin-left: 0;
		}
		.ant-steps-icon {
			top: -9px !important;
		}
		// &:not(.first-icon) .ant-steps-icon {
		//   top: -4px !important;
		// }
	}
	.ant-steps-item-description {
		font-family: "PPNeueMontrealMono Medium" !important;
		color: $night !important;
		margin-left: 24px;
		font-size: 12px !important;
		line-height: 16px !important;
	}
	.status-completed {
		color: $crest;
		font-size: 18px;
		margin-top: 12px;
	}
}

.verticalStepKitFlow {
	display: none;
	margin-left: 8px;
	@include for-both-phone-and-tablet {
		display: block;
	}
	.ant-steps-item-container > .ant-steps-item-tail {
		top: 2px !important;
		left: 20px !important;
		padding: 45px 0 10px !important;
		background: #302e2b !important;
	}
	.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
		font-family: "PPNeueMontrealMono Book" !important;
		color: $night !important;
		padding: 0 !important;
		margin-top: 8px !important;
		margin-left: 6px;
	}
	.ant-steps-vertical > .ant-steps-item {
		overflow: visible !important;
		height: 36px !important;
	}
	.status-icon-container {
		.ant-steps-item-content {
			position: relative;
			top: -15px;
		}
		.ant-steps-icon {
			top: -11px !important;
			left: 2px;
		}
	}
	.status-completed {
		margin-left: 8px;
	}
}

.kit-result-collapse {
	&.ant-collapse-header {
		// color: #9e8bba !important;
		// font-weight: bold;
		background-color: #fbeee4 !important;
		padding: 30px 16px !important;
		@include for-phone-only {
			padding: 20px 0 !important;
		}
	}
	&.ant-collapse-borderless {
		background-color: #fbeee4 !important;
		border: 0;
	}
	&.ant-collapse-borderless > .ant-collapse-item {
		border-bottom: 1px solid rgba($night, 0.1) !important;
	}
	&.ant-collapse-content > .ant-collapse-content-box {
		padding: 0 16px 30px 16px !important;
		background: #fbeee4 !important;
		@include for-phone-only {
			padding: 0 0 10px 0 !important;
		}
	}
	&.ant-collapse
		> .ant-collapse-item
		> .ant-collapse-header
		.ant-collapse-arrow {
		@include for-both-phone-and-tablet {
			top: 40px !important;
		}
		@include for-phone-only {
			top: 27px !important;
			right: 25px !important;
			width: 14px !important;
		}
	}
	.ant-collapse-item-active .ant-collapse-header {
		@include for-phone-only {
			border-bottom: 1px solid rgba($night, 0.1);
		}
	}
}

.reportCollapse {
	background: $white !important;
	// border: 1px solid #cec3c1 !important;
	.ant-collapse-header {
		background-color: #fbeee4 !important;
		padding: 30px !important;
		@include for-phone-only {
			padding: 20px 10px !important;
		}
		.ant-collapse-header-text {
			padding-right: 30px !important;
			width: -webkit-fill-available;
		}
		.ant-collapse-arrow {
			right: 30px !important;
			@include for-phone-only {
				right: 10px !important;
			}
		}
	}
	.ant-collapse-content {
		border-top: none !important;
	}
	.ant-collapse-content > .ant-collapse-content-box {
		padding: 0 30px 30px 30px !important;
		background: #fbeee4 !important;
		@include for-phone-only {
			padding: 0 10px 20px 10px !important;
		}
	}
}

.pieChartCollapse {
	margin-left: 89px;
	@include for-phone-only {
		margin-left: 25px;
	}
	.ant-collapse-header {
		background-color: inherit !important;
		padding: 20px !important;
		@include for-phone-only {
			padding: 10px !important;
		}
	}
	.ant-collapse-content {
		border-top: none !important;
	}
	.ant-collapse-content > .ant-collapse-content-box {
		padding: 0 20px 20px 20px !important;
		background: inherit !important;
		@include for-phone-only {
			padding: 0 10px 10px 10px !important;
		}
	}
}

.ant-tooltip {
	z-index: 998 !important;
	// width: 400px !important;
	max-width: 400px !important;
	@include for-phone-only {
		max-width: 250px !important;
		width: calc(min(80vw, 250px)) !important;
	}
}

.tooltip-dark {
	.ant-tooltip-arrow-content:before {
		background: $primary-color;
	}
	.ant-tooltip-inner {
		color: $secondary-color !important;
		padding: 16px;
		background: $primary-color;
	}
}

.card-content-spinner {
	margin-top: 30px;
	text-align: center;
}

.status-info-container {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.ant-skeleton-element,
.ant-skeleton-image {
	width: 100% !important;
	height: 100% !important;
}

.img-container-square {
	background-color: #fff;
	text-align: center;
	@include for-desktop-up {
		border-radius: 0px;
	}
	img {
		height: 100%;
		max-width: 100%;
		object-fit: cover;
		@include for-desktop-up {
			border-radius: 0px;
		}
	}
}

.otp-input {
	input {
		text-align: center !important;
		border: none !important;
		border: 1px solid $night !important;
		border-radius: 0px !important;
		font-size: $h4-text !important;
		color: $primary-color !important;
		background: #ffffff !important;
		font-weight: 400 !important;
		caret-color: $primary-color !important;
		outline: none !important;
		width: 90px !important;
		height: 60px !important;
		text-transform: uppercase !important;
		@include for-phone-only {
			width: 34px !important;
			height: 34px !important;
			border-radius: 0px !important;
			font-size: $h4-text-mobile !important;
		}
	}
	span,
	span.input-separator {
		width: 15px;
		@include for-both-phone-and-tablet {
			width: 8px;
		}
	}
	&:nth-child(1),
	&:nth-child(4) {
		span,
		span.input-separator {
			width: 30px;
			@include for-both-phone-and-tablet {
				width: 15px;
			}
		}
	}
}
.otp-input.opt-input-small {
	input {
		width: 90% !important;
		height: 50px !important;
	}
	&:nth-child(1),
	&:nth-child(4) {
		margin-right: 20px !important;
		@include for-both-phone-and-tablet {
			margin-right: 15px !important;
		}
	}
}

.redirect-message-container {
	@include for-desktop-up {
		margin: auto 0;
	}
	@include for-tablet-landscape-up {
		margin: 25px auto;
	}
	@include for-tablet-portrait-up {
		margin: 25px auto;
	}
	@include for-phone-only {
		margin: 25px auto;
	}
}

.group-selection-tabs {
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: $secondary-color !important;

		&:focus-within {
			box-shadow: none !important;
		}
	}

	.ant-radio-button-wrapper:first-child {
		border-start-start-radius: 4px;
		border-end-start-radius: 4px;
	}

	.ant-radio-button-wrapper:last-child {
		border-start-end-radius: 4px;
		border-end-end-radius: 4px;
	}

	.ant-radio-button-wrapper:not(:first-child)::before {
		background-color: $night !important;
	}

	.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
		background-color: $day !important;
		border: 1px solid $night !important;
	}
}

.flex-box {
	display: flex;
	gap: 10px;
}

.space-between {
	justify-content: space-between;
}

.valign-middle {
	align-items: center;
}

.ph-confirmation-modal {
	.ant-modal-confirm-btns {
		@extend .flex-box;
		@extend .valign-middle;
		justify-content: space-around;
		text-align: center;

		.ph-btn {
			padding: 8px 22px !important;
		}
	}
}

.ph-btn-arrow {
	padding-left: 12px;
}

#skinMicrobiomeSlider {
	.noUi-tooltip {
		@extend .font-quincy;
	}
}

.transparent-modal .ant-modal-content {
	background-color: #ffffff00;
}

.control-table .ant-table-cell:has(.ant-empty) {
	border-bottom: none !important;
}

$kitCellColors: (
	CK: #fcd34d,
	AP: #fcd34d,
	BB: #fcd34d,
	SL: #fcd34d,
	CT: #ff9ecd,
	CN: #93e89b,
	TZ: #a3d8ff,
	X1: #f56b6b,
	X2: #ae93e8,
	X3: #a8aeb1,
	X4: #bf9935,
);

@each $key, $value in $kitCellColors {
	.#{$key} {
		background-color: $value;
	}
}

// horizontalStepQuizOption

.horizontalStepQuizOption {
	// @include for-both-phone-and-tablet {
	//   display: none;
	// }

	// padding-top: 40px ;

	@include for-phone-only {
		transform: rotate(270deg);
	}

	.ant-steps-item-container {
		position: relative;
	}

	.ant-steps-item-content {
		// position: absolute;
		// top: -40px;

		// @include for-phone-only{
		//   position: inherit;
		//   top: 0vw;
		// }
	}

	.ant-steps-item-finish,
	.ant-steps-item-process {
		.ant-steps-item-container {
			// & > .ant-steps-item-tail::after {
			//   // background-color: #FD8272 !important;
			//   // height: 5px;
			//   // margin-top: -2px
			// }
		}
	}

	.ant-steps-item-wait {
		@include for-phone-only {
			.ant-steps-item-content {
				white-space: wrap;
			}
		}
	}

	// .ant-steps-item-container > .ant-steps-item-tail::after {
	//   background-color: #302e2b !important;
	// }
	.ant-steps-label-vertical .ant-steps-item-tail {
		padding: 1px 0 !important;
		background: $night;
		height: 2px;
		margin-top: -2px;
	}
	.ant-steps:not(.ant-steps-vertical)
		.ant-steps-item-custom
		.ant-steps-item-icon {
		margin-left: 57px;
	}
	.ant-steps-label-vertical .ant-steps-item {
		overflow: visible;
		width: 54px;
		@include for-phone-only {
			width: 22px;
		}
	}

	.status-icon-container {
		.ant-steps-item-icon {
			margin-left: 40px !important;
		}
		.ant-steps-item-content {
			margin-left: 0;
			display: block;
			width: 140px;

			@include for-phone-only {
				transform: rotate(90deg);

				margin-top: 66px;
				width: 186px;
				text-align: left;
				margin-left: -35px;
			}
		}
		.ant-steps-icon {
			top: -10px !important;
		}
		// &:not(.first-icon) .ant-steps-icon {
		//   top: -4px !important;
		// }
	}
	.ant-steps-item-description {
		color: $night !important;
		@extend .body-txt-lg;
	}
	.status-completed {
		color: $crest;
		font-size: 18px;
		margin-top: 12px;
	}
	.ant-steps .ant-steps-item-tail {
		top: 16px;
	}
}

// quiz progress bar start

.ant-select-dropdown {
	background-color: $stone !important;
	color: $white !important;
	div[aria-selected="true"] {
		background-color: $primary-color !important;
		color: $white !important;
	}
}

.phSelect {
	width: 100%;
	display: flex;
	align-items: center;
	.ant-select-selector {
		border-radius: 0px !important;
		padding: 11px !important;
		height: 50px !important;
		background-color: transparent !important;
		border-color: $night !important;
		input {
			background-color: transparent !important;
		}
	}
	.ant-select-arrow {
		color: $night;
		padding-top: 14px !important;
	}
	&.dark {
		.ant-select-selector {
			border-color: $day !important;
		}
		.ant-select-selection-placeholder {
			color: #838383 !important;
		}
		.ant-select-selection-item {
			color: $day !important;
		}
		.ant-select-arrow {
			color: $day;
		}
		input {
			color: $day !important;
		}
	}
}

.protocol-icon {
	width: 18px;
	@include for-both-phone-and-tablet {
		width: 16px;
		margin-left: -6px;
		margin-top: -6px;
	}
}

.protocol-description {
	@extend .body-txt-md;
	// @extend .font-Mono-medium;
	color: $uv-blue;
	// margin-bottom: 20px;
	div {
		margin-top: 2px;
		@include for-both-phone-and-tablet {
			margin-top: 6px;
		}
	}
	div,
	a {
		@extend .body-txt-lg;
		// @extend .font-Mono-medium;
		color: $uv-blue;
		font-style: italic;
		@include for-phone-only {
			font-size: 14px;
			line-height: 20px;
		}
	}
	a {
		cursor: pointer;
		@extend .font-Neue-medium;
		&:hover {
			color: $uv-blue;
		}
	}
}

.next-icon {
	.barIcon {
		background: transparent !important;
		pointer-events: none !important;
	}
}
