@import "../../styles/main";

.recommendationsBody {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include for-both-phone-and-tablet {
    padding: 0;
    > :global(.ant-row) {
      gap: 30px;
    }
  }
  .bodyDescription {
    @include for-both-phone-and-tablet {
      margin-bottom: 0 !important;
    }
  }
}