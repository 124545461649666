@import "../../styles/main";

:global .reportContainer {
    padding-bottom: 0 !important;
}

.videoContainer {
    // margin: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
    //background-image: url("../../assets/images/PH_banner_waves.png");
    background-color: $white;
    background-size: contain;
    @include for-phone-only {
        // margin: 30px;
        padding-top: 0px;
    }
    .userTitle {
        font-family: "Neue Haas Grotesk Light" !important;
        color: $primary-color;
        @include for-phone-only {
            text-align: center;
        }
    }
    .userSubtitle {
        // @extend .font-PPNeueMontrealMono;
        @extend .body-txt-lg;
    
        color: $primary-color;
        @include for-medium-phone-only {
            text-align: center;
        }
        @include for-small-phone-only {
            text-align: center;
        }
    }
    .box {
        padding-top: 60px;
        // margin: 60px 60px 0 60px;
        @include for-phone-only {
            padding-top: 30px;
            max-width: 100vw;
            padding: 30px 16px 0px 16px;
            // margin: 30px 30px 0 30px;
        }
    }
    .tileContainerLeft {
        background: $primary-color;
        margin-right: 10px;
        border-radius: 12px;
        padding: 30px;
        min-height: 211px;
        display: flex;
        @include for-tablet-portrait-up {
            padding: 20px;
        }
        @include for-phone-only {
            margin-right: 0;
            padding: 20px;
            border-radius: 4px;
            min-height: fit-content;
        }
    }
    .tileContainerRight {
        margin-left: 10px;
        margin-right: 0 !important;
        @include for-phone-only {
            margin-left: 0;
            margin-right: 0 !important;
        }
    }
    .tile {
        width: 100%;
        @include for-phone-only {
            gap: 20px;
        }
        .tileQuestion {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include for-both-phone-and-tablet {
                justify-content: center;
            }
            .questionText {
                font-size: $h5-text;
                line-height: $h5-line-height;
                color: $secondary-color;
                letter-spacing: 0.05em;
                @include for-phone-only {
                    font-size: $h5-text-mobile;
                    line-height: $h5-line-height-mobile;
                    text-align: center;
                }
            }
        }
        // .tileDivider {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     @include for-phone-only {
        //         // margin: -20px 0;
        //     }
        // }
        .tileTitleContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include for-both-phone-and-tablet {
                justify-content: center;
                align-items: flex-start;
            }
            .titleSection {
                display: flex;
                // align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                @include for-both-phone-and-tablet {
                    justify-content: center;
                    align-items: center;
                }
                .tileTitle {
                    font-size: $h2-text;
                    line-height: $h2-line-height;
                    color: $secondary-color;
                    @include for-phone-only {
                        font-size: $h2-text-mobile;
                        line-height: $h2-line-height-mobile;
                        text-align: center;
                    }
                }
                .tileSubtitle {
                    font-size: $h4-text;
                    line-height: $h4-line-height;
                    color: $secondary-color;
                    margin-top: 13px;
                    @include for-phone-only {
                        font-size: $h4-text-mobile;
                        line-height: $h4-line-height-mobile;
                        text-align: center;
                    }
                }
            }
        }
    }
    // .kitItemSection {
    //     background-color: $primary-color;
    //     border-radius: 12px;
    //     padding: 70px 20px;
    //     text-align: center;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 80px;
    //     @include for-phone-only {
    //         border-radius: 4px;
    //         padding: 20px 10px;
    //         gap: 40px;
    //         padding-bottom: 0;
    //     }
    //     .kitItemList {
    //         // gap: 80px !important;
    //         align-items: center;
    //         justify-content: center;
    //         @include for-both-phone-and-tablet {
    //             justify-content: space-evenly;
    //         }
    //         .kitItemContainer {
    //             display: flex;
    //             flex-direction: column;
    //             align-items: center;
    //             justify-content: center;
    //             gap: 20px;
    //             @include for-phone-only {
    //                 gap: 10px;
    //             }
    //             .itemImage {
    //                 // height: 120px;
    //                 // width: 120px;
    //                 width: 8vw;
    //                 // @include for-tablet-portrait-up {
    //                 //     width: 10vw;
    //                 // }
    //                 @include for-both-phone-and-tablet {
    //                     // height: 30px;
    //                     width: 13vw;
    //                 }
    //             }
    //             .textContainer {
    //                 // width: 189px;
    //                 width: 10vw;
    //                 height: 45px;
    //                 @include for-tablet-portrait-up {
    //                     height: 65px;
    //                     width: 16vw;
    //                 }
    //                 @include for-phone-only {
    //                     height: 75px;
    //                     width: 16vw;
    //                     font-size: 9px;
    //                     line-height: 12px;
    //                     text-align: center;
    //                 }
    //                 .itemDesc {
    //                     font-size: $h5-text;
    //                     line-height: $h5-line-height;
    //                     color: $secondary-color;
    //                     letter-spacing: 0.05em;
    //                     @include for-phone-only {
    //                         font-size: $h6-text-mobile;
    //                         line-height: 12px;
    //                         text-align: center;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    .VideoSection {
        margin-bottom: 100px;
        @include for-phone-only {
            margin-bottom: 30px;
        }
        .videoFrame {
            // height: 70vh;
            height: calc(1400px / 1.82);
            width: 100%;
            // width: 100%;
            // height: 80vh;
            border-radius: 12px;
            @include for-big-desktop-up {
                height: calc(1300px / 1.82);
            }
            @include for-both-phone-and-tablet {
                height: calc(100vw / 1.82);
            }
            @include for-phone-only {
                border-radius: 4px;
            }
        }
    }
    //     .VideoSection {
    //         position: relative;
    //         height: 0;
    //         padding-bottom: 56.25%;
    //   /* 16:9 */
    //   padding-top: 25px;
    //       }

    //       .VideoSection iframe {
    //          position: absolute;
    //          width: 90%;
    //          height: 90%;
    //          top: 0;
    //          left: 0;
    //       }
    .lightBackground {
        background-color: $stone;
        display: flex;
        justify-content: center;
        max-width: 1440px;
        padding: 0px 60px;
        &.RemoveBg {
            background-color: $white !important;
        }
        @include for-both-phone-and-tablet {
            padding: 0px 16px;     
        }
        .instructionSection {
            .kitItemSection {
                border-radius: 12px;
                padding: 70px 0px 90px 0px;
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 30px;

                @include for-phone-only {
                    border-radius: 4px;
                    padding: 30px 10px 40px 10px;
                    gap: 20px;
                }
                .kitItemList {
                    // gap: 80px !important;
                    align-items: center;
                    justify-content: center;
                    @include for-both-phone-and-tablet {
                        justify-content: space-evenly;
                        // padding: 0 10px;
                    }
                    .kitItemContainer {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 20px;
                        @include for-phone-only {
                            gap: 10px;
                        }
                        .itemImage {
                            // height: 120px;
                            // width: 120px;
                            width: 8vw;
                            // @include for-tablet-portrait-up {
                            //     width: 10vw;
                            // }
                            @include for-both-phone-and-tablet {
                                // height: 30px;
                                width: 13vw;
                            }
                        }
                        .textContainer {
                            // width: 189px;
                            width: 10vw;
                            height: 45px;
                            @include for-tablet-portrait-up {
                                height: 65px;
                                width: 16vw;
                            }
                            @include for-phone-only {
                                height: 75px;
                                width: 16vw;
                                font-size: 9px;
                                line-height: 12px;
                                text-align: center;
                            }
                            .itemDesc {
                                font-size: $h5-text;
                                line-height: $h5-line-height;
                                color: $night;
                                letter-spacing: 0.05em;
                                @include for-phone-only {
                                    font-size: $h6-text-mobile;
                                    line-height: 12px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
            .instructionContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 70px;
                padding: 60px 0 0 0;
                &.lessPadding {
                    padding: 0px 0px 0px 0px;
                }
                @include for-phone-only {
                    gap: 30px;
                    padding: 30px 0 0 0;
                }
                .instructionHeading {
                    padding-bottom: 40px;
                }
                .smallTextContainer {
                    @include for-phone-only {
                        font-size: $h6-text-mobile;
                        line-height: 12px;
                    }
                    .smallText {
                        font-size: $h5-text;
                        line-height: $h5-line-height;
                        letter-spacing: 0.05em;
                        color: $primary-color;
                        @include for-small-phone-only {
                            font-size: $h6-text-mobile;
                            line-height: 12px;
                        }
                        @include for-medium-phone-only {
                            font-size: $h6-text-mobile;
                            line-height: 12px;
                        }
                    }
                }
                .instructionStepper {
                    display: flex;
                    justify-content: flex-start;
                    max-width: 1440px;
                    margin-top: 24px;
                    @include for-both-phone-and-tablet {
                        margin-top: 50px;
                        width: 90vw;
                    }
                    .hideStep {
                        @include for-both-phone-and-tablet {
                            display: none;
                        }
                    }

                    .smallBar {
                        background: #302e2b;
                        height: 1px;
                        width: 21px;
                        @include for-phone-only {
                            width: 11px;
                        }
                        // @include for-both-phone-and-tablet {
                        //     display: none;
                        // }
                    }
                    .mediumBar {
                        background: #302e2b;
                        height: 1px;
                        width: 48px;
                        @include for-phone-only {
                            width: 11px;
                        }
                        // @include for-both-phone-and-tablet {
                        //     display: none;
                        // }
                    }
                    .LargeBar {
                        background: $uv-blue;
                        height: 5px;
                        z-index: 1;
                        width: 100px;
                        @include for-phone-only {
                            width: 30px;
                            height: 2px;
                        }
                    }
                    .contactInfo {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 100px;
                        padding: 0px 20px 20px 20px;
                        // background: $secondary-color;
                        @include for-phone-only {
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            padding: 10px 20px;
                            padding: 10px;
                            gap: 2px;
                        }
                        .question {
                            font-style: normal;
                            font-weight: 100;
                            font-size: $h4-text;
                            color: $primary-color;
                            line-height: 42px;
                            text-align: left;
                            @include for-phone-only {
                                font-size: $h4-text-mobile;
                                line-height: $h4-line-height-mobile;
                            }
                        }
                        .link {
                            color: $uv-blue !important;
                            font-size: 16px;
                            font-weight: 600;
                            margin-left: 4px;
                            @include for-phone-width-297 {
                                font-size: 12px;
                            }
                        }
                    }
                    .stepSubtitle {
                        @extend .body-txt-lg;
                        letter-spacing: 0.05em;
                        margin-right: 10%;
                        
                        @include for-small-phone-only {
                            // font-size: $h4-text-mobile;
                            // line-height: $h4-line-height-mobile;
                            text-align: center;
                        }
                        @include for-both-phone-and-tablet{
                            font-size: $body-text-mobile-med;
                            line-height: $body-line-height-mobile-med;
                            text-align: center;
                            margin-right: 0px;
                        }
                        @include for-phone-width-297 {
                            font-size: 10px !important;
                            text-align: center;
                        }
                    }
                    .instructionBlock {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        .step {
                            background-color: $uv-blue;
                            padding: 8px 16px;
                            min-width: 69px;
                            @include for-phone-only {
                                padding: 8px 8px;
                            }
                        }

                        .title {
                            @extend .h4;
                            color: $primary-color;
                            // @include for-medium-phone-only {
                            //     font-size: $h2-text-mobile;
                            //     line-height: $h2-line-height-mobile;
                            // }
                        }
                        .instructionDesc {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 40px;
                            @include for-phone-only{
                                flex-direction: column;
                                gap: 20px;
                            }
                            .image {
                                // width: 14vw;
                                // width: 204px;
                                height: 250px;
                                // @media (max-width: 671px) {
                                //     width: 142px;
                                // }
                                @media (max-width: 599px) {
                                    height: 180px;
                                }
                                @include for-medium-phone-only {
                                    // width: 132px !important;
                                    height: 154px;
                                }
                                @media (max-width: 399px) and (min-width: 330px) {
                                    height: 210px;
                                }
                                @media (max-width: 330px) {
                                    height: 130px;
                                }
                            }
                            // .description {
                            // }
                        }
                    }
                }
            }
        }
    }
    .explorePHsection {
        // background: url("../../assets/images/PH_waves_video.svg");
        // background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("/assets/images/PH_waves_bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-color: $white !important;
        padding: 70px 10vw;
        // margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        gap: 50px;
        margin-top: -78px;
        z-index: 1;
        @include for-phone-only {
            // margin-top: 30px;
            align-items: baseline;
            padding: 30px 16px;
            gap: 20px;
            margin-top: -89px;
        }
        .title {
            font-family: "Quincy CF";
            font-style: normal;
            font-weight: 100;
            font-size: $h2-text;
            color: $primary-color;
            line-height: $h2-line-height;
            text-align: left;
            @include for-phone-only {
                font-size: $h2-text-mobile;
                line-height: $h2-line-height-mobile;
            }
        }
        .button {
            width: fit-content;
            height: $button-height;
            padding: 0 30px;
            border-radius: 12px;
            font-size: $button-text;
            line-height: $button-text;
            @include for-phone-only {
                font-size: $button-text-mobile;
                line-height: $button-text-mobile;
                height: $button-height-mobile;
                border-radius: 4px;
            }
        }
    }
    .stickyfooter {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        min-height: 60px;
        background-color: $infrared;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        .warningIcon{
            font-size: 20px;
            color: $white;
            margin: -3px 6px 0px 6px;
            @include for-both-phone-and-tablet {
                font-size: 14px;
                margin: 0px 8px;
            }
        }
        a {
            text-decoration: underline;
        }
    }
}
.kitListOuterContainer {
    padding-bottom: 20px;
    padding-left: 40px;
    
    .kitListItemContainer {
        display: flex;
        align-items: flex-start;
        text-align: left;

        .listKitText {
            padding-left: 20px;

            span {
                @extend .font-PPNeueMontrealMono;
                @extend .note-1;
                color: $night;
                @include for-both-phone-and-tablet {
                    font-size: 14px;
                }
            }
        }
    }
    
    .listItemImage {
        width: 24px;
        @include for-phone-only {
           width: 20px; 
        }
    }
    @include for-both-phone-and-tablet {
        padding-left: 4px;
    }

}
.titleCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;
    .detailCard {
        border: 2px solid $uv-blue;
        border-radius: $button-border-radius;
        min-width: 15vw;
        max-width: fit-content;
        // border-radius: 8px;
        margin-right: 16px;
        margin-top: 12px;
        // background-color: $night;
        .cardContainer {
            padding: 19px 36px;
            width: 100%;
            @include for-both-phone-and-tablet {
                padding: 8px;
            }
        }
        .cardText {
            color: $day;
        }
        @include for-both-phone-and-tablet {
            min-width: 25vw;
        }
        @include for-phone-only {
            min-width: 100%;
            margin-right: 8px;
            border: 1px solid $night;
        }
        // :hover {
        //     background-color: $stone;
        //     border-radius: 8px;
        //     .cardText {
        //         color: $night;
        //     }
        // }
    }
    @include for-phone-only {
        flex-direction: column;
    }
}

.sandContainer {
    width: 100%;
    padding: 36px 0px;
    // border-radius: 8px

}

.kitRegistContainer {
    @extend .center-flex;
    flex-direction: column;
    margin-bottom: 50px;
    gap: 14px;
    .icon {
        width: 48px;
    }
    @include for-both-phone-and-tablet {
        gap: 8px;
        margin-bottom: 40px;
        .icon {
            width: 40px;
        }
    }
    .formButtons {
        @extend .center-flex;
        gap: 18px;
        @include for-both-phone-and-tablet {
            flex-direction: column-reverse;
            gap: 12px;
        }
        .buttonContainer {
            @extend .center-flex;
            flex-direction: column;
            position: relative;
            .reviewButton {
                background-color: $stone !important;
            }
            .chevron {
                position: absolute;
                top: 48px;
                font-size: 24px;
                @include for-phone-only{
                    top: 40px;
                    font-size: 22px;
                }
            }
        }
    }

}

.textAlign {
    text-align: center;
    @include for-phone-only{
        text-align: left;
    }
}


 