@import "@ph/common/styles/main";

.adminContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .continueButton {
        width: fit-content;
        height: 40px;
        border-radius: 12px;
        padding: 0 30px;
        font-size: $button-text;
        line-height: $button-text;
        margin-top: 30px !important;
        @include for-phone-only {
            font-size: $button-text-mobile;
            line-height: $button-text-mobile;
            border-radius: 4px;
            margin-top: 0;
        }
    }
    .continueButton:disabled {
        background: $sand !important;
        color: $secondary-color !important;
    }
    .h1Text {
        @extend .h2;
        font-size: $h1-text;
        color: $primary-color;
        font-weight: 100;
    }
    //24
    .bodyText1 {
        @extend .body-txt-lg;
        color: $primary-color;
    }
    //18
    .bodyText2 {
        @extend .body-txt-md;
        color: $primary-color;
        margin-bottom: 30px !important;
    }
}
