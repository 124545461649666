@import "../../../styles/main";

.megaMenu {
    width: 100%;
    top: 68px !important;
    
    :global(.ant-popover-inner){
        border-radius: 0px;
        background-color: #383431;
        padding: 32px;
        padding-bottom: 46px;
    }
    .icon {
        width: 31px;
        height: 32px;
        cursor: pointer;
        @include for-screen-1367-1199 {
            width: 21px;
            height: 22px;
        }
        @include for-phone-only {
            width: 21px;
            height: 22px;
        }
    }
}
   
.megaMenuCards{
    max-width: 1330px;

    .menuCardContainer {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;
        margin-top: 18px;
        .description, .title{
            color: $white;
            @extend .body-txt-md;
        }
        .title {
            @extend .font-Neue-medium;
        }
        .description {
            padding-right: 10%;
        }

        .image {
            height: 280px;
        }
    }
}
