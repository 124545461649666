@import "../../../styles/main";

.descriptionContainer {
	height: 100%;
	.title {
		@extend .note-1;
		@extend .font-Mono-medium;
		text-transform: uppercase;
		border-left: 2px solid var(--title-border);
		padding: 15px 30px;
		background: $night;
		color: $white;
	}
	.description {
		// background: linear-gradient(180deg, var(--base-color) 0%, #ffffff 100%);
		@extend .body-txt-lg;
		padding: 30px 30px;
		position: relative;
		height: calc(100% - 51px);
		border-left: 2px solid $night;
		background: linear-gradient(180deg, var(--base-color) 0%, #ffffff 55%);
		min-height: 80%;
		@include for-phone-only {
			padding: 30px 16px;
			border-left: none;
		}
	}
	.subTitle {
		@extend .h4;
		margin-bottom: 14px;
	}
	.closeIcon {
		position: absolute;
		cursor: pointer;
		top: 0;
		right: 0;
		@extend .show-desktop-hide-mobile;
		img {
			width: 32px;
			background: $white;
			border: 1px solid $night;
			padding: 7px;
		}
	}

	@include for-phone-only {
		border-left: none;
		.title {
			padding: 15px 16px;
		}
	}
}

.tableContainer {
	.tableHeader {
		background: $night;
		.text {
			@extend .note-1;
			@extend .font-Mono-medium;
			padding: 15px 30px;
			background: $night;
			color: $white;
		}
	}
	.dataRow {
		cursor: pointer;
		outline: 1px solid $night;
		padding-left: 8px;
		min-height: 84px;

		&.selected {
			padding-left: 0px;
			border-left: 8px solid $night;
			background: var(--base-color);
		}
		.dataTitle,
		.dataDescription {
			display: flex;
			align-items: center;
			justify-content: left;
			height: 100%;
		}
		.dataTitle {
			text-transform: capitalize;
			padding: 15px 30px;
			@extend .body-txt-lg;
		}
		.dataDescription {
			padding: 15px 30px;
			@extend .note-2;
			@extend .font-Mono-medium;
		}
		@include for-phone-only {
			border-bottom: 1px solid $night;
		}
	}
}

.reportProduct {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 25px;
	border-top: 2px solid $night;
	border-left: 2px solid $night;
	padding-left: 28px;
	padding-right: 0px;
	height: 100%;
	background-color: var(--background-color);
	// border-left: 8px solid transparent;
	.title {
		@extend .note-2;
		@extend .font-Mono-medium;
		text-transform: uppercase;
	}
	.productName {
		@extend .h6;
	}
	.productDesc,
	.specialText {
		@extend .note-2;
		@extend .font-Mono-medium;
		padding-right: 25px;
	}
	.title,
	.productName,
	.productDesc {
		color: var(--text-color);
	}
	.specialText {
		color: $uv-blue;
		margin-bottom: 12px;
		@extend .font-Mono-bold;
		font-style: italic;
	}
	&.first {
		border-left: none;
		padding-left: 30px;
	}
	&:hover {
		background-color: var(--hover-color);
		border-left: 8px solid $night;
		padding-left: 22px;
		*,
		span {
			color: var(--text-hover-color);
		}
		.discountPercentage {
			color: var(--discount-text-hover);
			background-color: var(--discount-bg-hover);
		}
		.quantityButton {
			border-color: var(--discount-bg-hover);
			:global {
				.ant-checkbox-checked {
					.ant-checkbox-inner {
						&:after {
							border-color: var(--discount-text-hover);
						}
					}
				}
				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--discount-bg-hover) !important;
					border-color: var(--discount-bg-hover) !important;
					&:hover {
						background-color: var(--discount-bg-hover) !important;
						border-color: var(--discount-bg-hover) !important;
					}
				}
				.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) {
					&:hover {
						border-color: var(--discount-bg-hover) !important;
					}
				}
				.ant-checkbox-checked:not(.ant-checkbox-disabled) {
					&:after {
						border-color: var(--discount-bg-hover)!important;
					}
				}
			}
		}
	}
	&.selected {
		background-color: var(--hover-color);
		border-left: 8px solid $night;
		padding-left: 22px;
		*,
		span {
			color: var(--text-hover-color);
		}
		.discountPercentage {
			color: var(--discount-text-hover);
			background-color: var(--discount-bg-hover);
		}
		.quantityButton {
			border-color: var(--discount-bg-hover);
			:global {
				.ant-checkbox-checked {
					.ant-checkbox-inner {
						&:after {
							border-color: var(--discount-text-hover);
						}
					}
				}
				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: var(--discount-bg-hover) !important;
					border-color: var(--discount-bg-hover) !important;
					&:hover {
						background-color: var(--discount-bg-hover) !important;
						border-color: var(--discount-bg-hover) !important;
					}
				}
				.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) {
					&:hover {
						border-color: var(--discount-bg-hover) !important;
					}
				}
				.ant-checkbox-checked:not(.ant-checkbox-disabled) {
					&:after {
						border-color: var(--discount-bg-hover)!important;
					}
				}
			}
		}
	}

	.adminButtonContainer {
		min-height: 46px;
	}

	.actionButtonContainer {		
		position: absolute;
			bottom: 32px;

		.actionButton {			
			color: $night !important;
			span, div,svg, path {
				color: $night !important;
				&:hover {
				color: $night !important;
			
			}
			}
			@extend .center-flex;
			border: 1px solid $night !important;
			span {
				@extend .note-1;
				@extend .font-Mono-medium;
			}
			&:hover {
				color: $night !important;
				border: 1px solid $night !important;
			}
		}
	}
	
	@include for-both-phone-and-tablet {
		gap: 12px;
		padding-top: 30px;
		border-left: none;
	}
	@include for-tablet-portrait-up {
		border-right: none;
	
	}

	@include for-phone-only {
		border-top: 1px solid $night;
		padding: 25px 16px;
		padding-top: 30px;
		padding-right: 0px;
		border-left: none;
		&.first {
			padding-left: 16px;
		}
		&:hover,
		&.selected {
			border-left: 6px solid $night;
			padding-left: 10px;
		}
		.productDesc,
		.specialText {
			padding-right: 16px;
		}
	}
}

.reportSingleProduct {
	position: relative;
	border-top: 2px solid $night;
	padding-left: 8px;

	.productCol,
	.descriptionCol {
		padding: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.descriptionCol {
		padding-left: 0px;
		padding-top: 40px;
		@include for-both-phone-and-tablet {
			padding-left: 24px;
			padding-top: 0px;
			align-items: flex-start;
		}
	}
	.actionButton {
		color: $night !important;
		@extend .center-flex;
		border: 1px solid $night !important;
		span {
			@extend .note-1;
			@extend .font-Mono-medium;
		}
		&:hover {
			color: $night !important;
			border: 1px solid $night !important;
		}
	}
	.imageCol {
		@extend .center-flex;
		margin-left: -2px;
		img {
			width: 100%;
			height: 100%;
			max-height: 150px;
			object-fit: cover;
			object-position: center center;
			background-color: $sand;
		}
	}
	.clinicalImage {
		@extend .center-flex;
		height: 100%;
		padding-right: 20px;
		padding-top: 20px;
		img {
			width: 100%;
			height: 100%;
			max-height: 150px;
			object-fit: cover;
			object-position: center center;
			background-color: $sand;
		}
		@include for-phone-only {
			padding-top: 38px;
			padding-right: 12px;
			max-height: 160px;
		}
	}
	.labelCol {
		align-items: center;
		justify-content: center;
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		.labelRow {
			position: absolute;
			bottom: 0;
		}
	}
	.dividerRow {
		height: 100%;

		@include for-both-phone-and-tablet {
			border-top: 2px solid $night;
			margin-top: -2px;
			&.nonImage {
				border-top: none;
			}

		}
		@include for-phone-only {
			border-top: 1px solid $night;
			margin-top: -1px;
		}
	}
	// border-left: 8px solid transparent;
	.title {
		position: absolute;
		top: 25px;
		text-transform: uppercase;
		@extend .note-2;
		@extend .font-Mono-medium;
	}
	.productName {
		@extend .h6;
	}
	.productDesc,
	.specialText {
		@extend .note-2;
		@extend .font-Mono-medium;
	}
	.specialText {
		color: $uv-blue;
		margin-bottom: 12px;
	}
	&:hover {
		background-color: $stone;
		border-left: 8px solid $night;
		padding-left: 0px;
	}
	&.selected {
		border-left: 8px solid $night;
		padding-left: 0px;
	}
	.nameContainer {
		padding-top: 24px;
	}
	@include for-both-phone-and-tablet {
		border-right: none;
		padding-left: 0px;

		.descriptionCol {
			padding-top: 0px;
		}
		.nameContainer {
			padding-top: 30px;
		}
		&:hover,
		&.selected {
			border-left: 6px solid $night;
		}
		.imageCol {
			max-height: 168px;
			margin-left: 0px;
		}
	}
	@include for-phone-only {
		border-top: 1px solid $night;
		.productCol,
		.descriptionCol {
			padding: 20px 16px;
		}
		.descriptionCol {
			padding-top: 0px;
		}
		.imageCol {
			max-height: 168px;
			margin-left: 0px;
		}
	}
}

.reconTextContainer {
	display: flex;
	align-items: baseline;
	justify-content: left;
	gap: 8px;
	width: 100%;
	margin-top: 6px;
	.bulletPoint {
		width: 8px;
		height: 8px;
		background: $uv-blue;
	}
	.reconText {
		@extend .note-2;
		color: $uv-blue;
		@extend .font-Mono-bold;
	}
}

.productHelpLabel {
	@extend .center-flex;
	@extend .note-1;
	background: $night;
	color: $white;
	padding: 12px;
	@include for-phone-only {
		padding: 10px 16px;
	}
}

.priceContainer {
	@extend .center-flex;
	gap: 8px;
	width: 100%;
	justify-content: left;
	flex-wrap: wrap;
	// padding-right: 20px;
	.price {
		@extend .note-2;
		@extend .font-Mono-bold;
		color: var(--text-color);
	}
	.discountPrice {
		@extend .note-2;
		@extend .font-Mono-medium;
		// color: var(--text-color);
		color: #2d292666;
		text-decoration: line-through;
	}
	.strikeContainer {
		@extend .center-flex;
		gap: 12px;
		@include for-phone-only {
			gap: 4px;
		}
	}
	.discountPercentage {
		@extend .note-2;
		@extend .font-Mono-medium;
		padding: 4px 8px;
		background: var(--discount-bg);
		color: var(--discount-text);
		@include for-phone-only {
			padding: 4px 4px;
			font-size: 11px;
		}
	}
	&.extraPadding {
		padding-right: 12px;
	}
	&.compact {
		justify-content: end;
		flex-wrap: nowrap;
		.strikeContainer {
			align-items: end;
		}
		@include for-phone-only {
			.strikeContainer {
				flex-direction: column;
				gap: 2px;
			}
		}
	}
}

.upgradeModal {
	
	text-align: center;
	z-index: 999999;
	min-width: 600px;
	@include for-phone-only {
		min-width: calc( 100% - 32px);
		padding: 0px;
		max-width: calc( 100% - 48px) !important;

	}
	.modalTitle {
		@extend .h1;
		font-style: italic;
		font-weight: 500;
		font-family: "PPEditorialOld UltralightItalic" !important;
		padding-bottom: 30px;
	}
	.modalSubtitle {
		@extend .body-txt-lg;
	}
	.buttonContainer {
		@extend .center-flex;
		margin-top: 30px;
		gap: 14px;
		.cancelButton {
			color: $uv-blue !important;
			border-color: $uv-blue !important;
			min-width: 170px;
		}
		.successButton {
			background-color: $uv-blue !important;
			color: $white !important;
			border-color: $uv-blue !important;
			min-width: 170px;
		}
		@include for-phone-only {
			flex-direction: column;
			.cancelButton, .successButton {
				min-width: 80%;
				max-width: 250px;
			}
		}
	}

	:global .ant-modal-content {
		background-color: $white;
		border-radius: 0px !important;
		box-shadow: none;
		text-align: center;
		padding: 60px 40px 40px 40px;
		// min-width: 600px;
		// margin-left: 16px;
		// margin-right: 17px;
		margin-top: -1px;

		:global .ant-modal-close-x {
			height: 36px;
			position: absolute;
			top: 0px;
			right: -2px;
			width: 24px;
		}
		@include for-phone-only {
			min-width: 100%;
			padding: 40px 16px 30px 16px !important;
			margin: 0px; 
		}
	}
	:global .ant-modal-body {
		padding: 0 !important;
	}
	.description {
		min-height: 400px;
	}
	:global(.ant-modal-close-x) {
		img {
			background: $white;
	
			// border: 1px solid $night;
			// padding: 7px;
		}	
	}
	:global(.ant-modal-close) {
		top: 0;
		right: 8px;
		background-color: $white !important;
		:hover {
			background-color: $white;
		}
	}
}
