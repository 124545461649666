@import "../../../styles/main";

.footerContainer {
    height: $footer-md-height;
    // position: absolute;
    bottom: 0;
    // left: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-color;
    padding-top: 10px;
    padding-bottom: 24px;
    @include for-phone-only {
        height: $footer-xs-height;
        align-items: flex-start;
    }
    .subscribeText {
        font-family: "Quincy CF";
        font-style: normal;
        font-weight: 100;
        font-size: 24px;
        color: $secondary-color;
        @include for-phone-only {
            font-size: 20px;
            line-height: 40px;
        }
    }
    .mediumText {
        color: $secondary-color;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
    .smallText {
        font-size: 10px;
        color: $secondary-color;
        @include for-phone-only {
            font-size: 8px;
        }
    }
    .parallelLink {
        text-decoration: none;
        color: $secondary-color !important;
    }
    a:hover {
        text-decoration: underline !important;
    }
    .faq {
        color: #fbeee4;
        line-height: 1.5;
        font-size: 20px;
        text-decoration-line: underline;
        text-underline-offset: 3px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
    .centerAlignmentMobile {
        margin-top: 2rem;
        @include for-phone-only {
            display: flex;
            align-items: center !important;
            justify-content: center !important;
        }
    }
    .socialIconSection {
        gap: 1.5rem !important;
        margin-top: 0.5rem;
        .socialIcon {
            width: 1.8rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .verticalEnd {
        @include for-both-phone-and-tablet {
            align-items: flex-end;
            padding-bottom: 10px;
        }
    }
    .hideOnLargeScreen {
        display: none;
        @include for-phone-only {
            display: flex;
        }
    }

    .logoSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            // width: 170px;
            // height: 82px;
            // @include for-tablet-portrait-up {
            //     width: 130px;
            //     height: 42px;
            // }
            max-width: calc(min(100%, 200px));
            height: auto;
            // @include for-phone-only {
            //     width: 106.22px;
            //     height: 33px;
            // }
        }
        @include for-phone-only {
            justify-content: center;
        }
    }
    .disclaimerSection {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        @include for-phone-only {
            flex-direction: column;
            gap: 1.8rem;
            padding: 2rem 0 3rem 0;
        }
        .linkText {
            font-size: 14px;
            line-height: 18px;
            color: $secondary-color !important;
            cursor: pointer;
            &:hover {
                text-decoration: underline !important;
                text-underline-offset: 3px !important;
            }
        }
    }
    .footerTabContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 20px 0;
        .footerTab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            // margin: 20px 0;
            margin: 20px 0 20px 2vw;
            gap: 2vw;
            .footerTabLink {
                // margin: 0 10px;
                .text {
                    color: $secondary-color !important;
                    // font-size: 16px !important;
                    font-size: $h5-text !important;
                    font-weight: 600 !important;
                    line-height: $h5-line-height !important;
                    white-space: pre;
                    // @include for-tablet-portrait-up {
                    //     font-size: 10px !important;
                    //     // line-height: 5px;
                    //     letter-spacing: 0.05em !important;
                    // }
                    @include for-phone-only {
                        font-size: $h5-text-mobile !important;
                        letter-spacing: 0.05em !important;
                    }
                }
                @include for-phone-only {
                    margin: 0 6px;
                }
            }
            @include for-phone-only {
                margin: 8px 0;
            }
        }
        @include for-both-phone-and-tablet {
            display: block;
        }
    }
    .buttonSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .questionButton {
            width: 248px;
            height: 56px;
            background: $stone;
            font-size: $button-text;
            line-height: $button-text;
            @include for-phone-only {
                width: 126px;
                height: 30px;
                font-size: $button-text-mobile;
                line-height: $button-text-mobile;
                padding: 5px;
            }
        }
        @include for-phone-only {
            justify-content: center;
        }
    }
    .policySection {
        .separator {
            width: 100%;
            background-color: $secondary-color;
            opacity: 0.5;
            height: 1px;
            margin: 38px 0;
            @include for-phone-only {
                margin: 16px 0;
            }
        }
        .text {
            // font-size: 14px !important;
            font-size: $h5-text !important;
            // line-height: 36px;
            letter-spacing: 0.69px;
            color: $secondary-color !important;
            opacity: 0.5 !important;
            white-space: nowrap;
            // @include for-tablet-portrait-up {
            //     font-size: 12px !important;
            // }
            @include for-phone-only {
                font-size: $h6-text-mobile !important;
            }
        }
        .URLSection {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include for-tablet-portrait-up {
                justify-content: center;
            }
            @include for-phone-only {
                justify-content: center;
            }
        }
        .socialIconSection {
            display: flex;
            align-items: center;
            justify-content: center;
            @include for-both-phone-and-tablet {
                justify-content: flex-start;
            }
            @include for-tablet-portrait-up {
                justify-content: center;
            }
            @include for-phone-only {
                justify-content: center;
            }
            .icon {
                // font-size: 20px;
                // color: $white;
                margin: 10px;
                height: 21px;
                // @include for-phone-only {
                //     font-size: 30px;
                // }
            }
        }
        .linkSection {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 2vw;
            .footerLink {
                // margin: 0 10px;
                // @include for-phone-only {
                //     margin: 0 5px;
                // }
            }
            @include for-phone-only {
                justify-content: center;
            }
            @include for-tablet-portrait-up {
                justify-content: center;
            }
        }
        @include for-phone-only {
            margin: 20px 0 -5px 0;
        }
    }

    .smallFooter {
        display: none;
        background: $primary-color;
        height: $footer-xs-height;
        border-top: 0.25px solid $stone;
        position: fixed;
        bottom: 0;
        z-index: 99;
        @include for-phone-only {
            display: flex;
        }
        .separator {
            height: $footer-xs-height;
            width: 0.25px;
            margin: 0;
            background: $stone;
        }
        .footerSpace {
            gap: 0 !important;
            height: inherit !important;
        }
        .footerBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25vw;
            padding: 6px 0;
            gap: 6px;
            .footerIcon {
                width: 18px;
                height: 18px;
            }
            .footerDashboardIcon {
                width: 22px;
                height: 16px;
            }
            .footerText {
                font-size: $h6-text-mobile;
                line-height: $h6-text-mobile;
                color: $secondary-color;
                text-align: center;
                letter-spacing: 0.693548px;
            }
            .footerTextActive {
                color: $bright-terra !important;
            }
        }
    }
}
