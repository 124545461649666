@import "../../../styles/main";
.bodyText {
	font-weight: 500;
	@extend .note-1;
	@extend .font-Mono-medium;
	color: $night;
}
// .kitDate {
//     @include for-both-phone-and-tablet {
//         font-size: 16px;
//         line-height: 20px;
//     }
// }
.button {
	opacity: 0;
	width: fit-content;
	height: $button-height;
	border-radius: 12px;
	font-size: $button-text;
	line-height: $button-text;
	padding: 4px 30px;
	@include for-both-phone-and-tablet {
		height: 40px;
		border-radius: 4px;
		font-size: $button-text-mobile;
		line-height: $button-text-mobile;
	}
}
.warningTextContainer {
	display: flex;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 8px;
	.warningText {
		@extend .font-Mono-medium;
		font-weight: 500;
		@extend .note-2;
		color: $night;
		margin-top: 6px;

		span {
			@extend .font-Mono-bold;
		}
	}
	.warningImage {
		margin-right: 12px;
		@include for-both-phone-and-tablet {
			position: absolute;
			width: 20px;
			right: -21px;
			top: -6px;
		}
	}
}

.reportContainer {
	position: relative;
	padding: 16px 40px;
	background-color: $white;
	color: $night;
	border-bottom: 1px solid $night;
	.reportHeader {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		// border-radius: 12px;
		@include for-both-phone-and-tablet {
			gap: 15px;
			margin-right: 0;
			align-items: flex-start;
		}

		@include for-phone-only {
			flex-direction: column;
		}

		.warningImage {
			margin-right: 12px;
			@include for-both-phone-and-tablet {
				display: none;
			}
		}
		.reportImage {
			display: none;
			@include for-both-phone-and-tablet {
				width: 30px;
				display: block;
				align-self: center;
			}
		}
		.mobileSection {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			@include for-desktop-up {
				display: none;
			}

            .mobileInnerContainer {
                display: flex;
                align-items: center;
                gap: 20px;

                @include for-phone-only {
                    flex-direction: column;
                    gap: 10px;
                    align-items: flex-start;
                }
            }
		}
		.reportHeaderSection1 {
			display: flex;
			align-items: center;
			gap: 20px;
			@include for-both-phone-and-tablet {
                width: 100%;
				gap: 40px;
				align-items: flex-start;
			}

            @include for-phone-only {
			    flex-direction: column;
                gap: 10px;
				align-items: flex-start;
		    }

			.sampleDateContainer {
				// border: 1px solid #2d2a26;
				background-color: $stone;
				color: $night;
				padding: 8px 15px;
				// border-radius: 6px;
				min-width: 320px;
				text-align: center;
				span {
					@extend .font-Mono-medium;
				}
				@media (max-width: 1425px) and (min-width: 800px) {
					width: max-content;
				}
				@include for-both-phone-and-tablet {
					text-align: center;
					width: 100%;
					padding: 8px 2px;
				}
			}
		}
		.reportHeaderSection2 {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;
			@include for-both-phone-and-tablet {
				justify-content: space-between;
				padding: 0 20px;
				width: 100%;
				gap: 12px;
			}
			.checkIcon {
				color: $night;
				font-size: 16px;
				line-height: 20px;
				@include for-both-phone-and-tablet {
					position: absolute;
					left: -5px;
					top: -7px;
					line-height: 0;
					background-color: #fff;
				}
			}
		}
		.showSectionDesktop {
			display: flex;
			@include for-both-phone-and-tablet {
				display: none;
			}
		}
	}

	@include for-both-phone-and-tablet {
		border-bottom: 1px solid $night;
		margin: 0px 0px 10px 0px;
		padding: 16px 16px 20px 16px;
	}

	&:hover {
		background-color: $white;
		.bodyText,
		.warningText {
			color: $night;
		}
		.reportHeader .sampleDateContainer {
			background-color: $stone;
			color: $night;
		}
		.button {
			opacity: 1;
		}
	}
}
