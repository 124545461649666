.phNumberInput{
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid black;
  input {
    border-radius: 4px !important;
    height: 46px;
  }
}
.filterContainer {
  max-width: 200px;
  display: inline-block;
  min-width: 120px;
  margin-right: 30px;
}
.genarteButton {
    position: absolute;
    bottom: 2px;
}