@import "../../../styles/main";

.slideContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
    margin-top: 30px;
    position: relative;
    width: 100%;
    @include for-phone-only {
        margin-top: 20px;
    }
    .outputContainer {
        width: 100%;
        overflow: visible;
        position: relative;
        height: calc(#{$big-text-line-height} + 10px);
        @include for-phone-only {
            height: calc(#{$h1-line-height-mobile} + 10px);
        }
        output {
            margin-bottom: 0px;
            position: absolute;
            margin-top: 20px;
            background: transparent;
            text-align: center;
            width: 100px;
            font-family: "Quincy CF";
            font-style: normal;
            font-weight: 100;
            font-size: $big-text;
            line-height: $big-text-line-height;
            letter-spacing: -4px;
            color: $primary-color;
            @include for-phone-only {
                letter-spacing: -2px;
                font-size: $h1-text-mobile;
                line-height: $h1-line-height-mobile;
                width: 60px;
                margin-top: 10px;
            }
        }
    }
    .textContainer {
        position: relative;
        height: calc(#{$h5-line-height-mobile} + 10px);
        width: 100%;
        @include for-phone-only {
            height: calc(#{$h6-line-height-mobile} + 5px);
        }
    }
    .smallText {
        font-size: $h5-text;
        color: $primary-color;
        line-height: $h5-line-height;
        // width: 61px;
        text-align: center;
        @include for-phone-only {
            width: fit-content;
            font-size: $h6-text-mobile;
            line-height: $h6-line-height-mobile;
        }
    }
    .leftBar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 39px;
        @include for-phone-only {
            justify-content: flex-start;
            align-items: flex-start;
            bottom: 20px;
            left: 10px;
        }
    }
    .rightBar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 39px;
        @include for-phone-only {
            justify-content: flex-end;
            align-items: flex-end;
            bottom: 20px;
            right: 10px;
        }
    }
    .verticalBar {
        height: 80px;
        width: 1px;
        background: #302e2b;
        @include for-phone-only {
            // height: 60px;
            width: 2px;
            opacity: 0.6;
        }
    }
    .inputContainer {
        width: 100%;
        // margin: 20px 0;
        border-radius: 12px;
        background-image: linear-gradient(to right, $walnut, $bright-terra, $crest, $secondary-color, $sand);
        padding: 0 30px;
        overflow: visible;
        position: relative;
        @include for-phone-only {
            padding: 0 10px;
        }
    }
    /********** Range Input Styles **********/
    /*Range Reset*/
    .slider {
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        background: transparent;
        // cursor: pointer;
        width: 100%;
        height: 110px;
        position: relative !important;
        padding-top: 20px;
        @include for-phone-only {
            height: 70px;
        }
    }

    /* Removes default focus */
    .slider:focus {
        outline: none;
    }

    /***** Chrome, Safari, Opera and Edge Chromium styles *****/
    /* slider track */
    .slider::-webkit-slider-runnable-track {
        background: url("../../../assets/images/range_slider.svg");
        background-size: 100% 100px;
        background-repeat: no-repeat;
        background-position: center;
        outline: none;
        height: 100px;
        z-index: 99;
        margin-bottom: 10px;
        @include for-phone-only {
            background: url("../../../assets/images/range_slider_mobile.svg");
            background-size: 100% 100px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    /* slider thumb */
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        outline: none;
        margin-top: 5px; /* Centers thumb on the track */

        /*custom styles*/
        background-color: transparent;
        background: url("../../../assets/images/range_thumb.svg");
        // background-position: 0 10px;
        background-size: cover;
        background-repeat: no-repeat;
        // cursor: pointer;
        z-index: 200;
        // width: 90px;
        // height: 100px;
        width: 100px;
        height: 120px;
        transition: 0.4s all ease-out;
        @include for-phone-only {
            width: 60px;
            height: 70px;
            margin-top: 23px;
        }
    }

    .slider:focus::-webkit-slider-thumb {
        border: none;
        outline: none;
        // outline-offset: 0.125rem;
    }

    /******** Firefox styles ********/
    /* slider track */
    .slider::-moz-range-track {
        background: url("../../../assets/images/range_slider.svg");
        background-size: 100% 100px;
        background-repeat: no-repeat;
        background-position: center;
        outline: none;
        height: 100px !important;
        z-index: 99;
        padding-top: 10px;
        // position: absolute;
        // top: 0;
        @include for-phone-only {
            background: url("../../../assets/images/range_slider_mobile.svg");
            background-size: 100% 100px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    /* slider thumb */
    .slider::-moz-range-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        outline: none;
        border: 0;
        // margin-top: 10px;

        /*custom styles*/
        background: url("../../../assets/images/range_thumb.svg");
        // background-position: 0 10px;
        background-size: cover;
        background-repeat: no-repeat;
        // cursor: pointer;
        z-index: 200;
        width: 100px;
        height: 100px;
        @include for-phone-only {
            width: 60px;
            height: 70px;
        }
    }

    .slider:focus::-moz-range-thumb {
        border: none;
        outline: none;
        // outline-offset: 0.125rem;
    }

    // -------------

    .slider::-ms-track {
        background: url("../../../assets/images/range_slider.svg");
        background-size: 100% 100px;
        background-repeat: no-repeat;
        background-position: center;
        outline: none;
        height: 110px !important;
        margin-bottom: 10px;
        @include for-phone-only {
            background: url("../../../assets/images/range_slider_mobile.svg");
            background-size: 100% 100px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    /* slider thumb */
    .slider::-ms-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        outline: none;
        border: 0;
        // position: absolute !important;
        // top: 20px !important;
        margin-top: 5px !important; /* Centers thumb on the track */

        /*custom styles*/
        background: url("../../../assets/images/range_thumb.svg");
        background-position: 0 10px;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 200;
        width: 100px;
        height: 120px;
        @include for-phone-only {
            width: 60px;
            height: 70px;
            margin-top: 23px;
        }
    }

    .slider:focus::-ms-thumb {
        border: none;
        outline: none;
        // outline-offset: 0.125rem;
    }
}
// @-moz-document url-prefix() {
//     .slideContainer {
//         margin-top: 30px;
//     }
// }
