@import "@ph/common/styles/main";

.questionSection {
	position: relative;
	width: 100%;
	height: 100%;
}
.questionContainer {
	display: flex;
	width: 100%;
	max-height: 100%;
	height: calc(100vh - 290px);
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 18px;
	margin-top: 40px;

	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none;
	-ms-overflow-style: none;
	@include for-phone-only {
		height: calc(100vh - 180px);
		margin-top: 0px;
		margin-bottom: 0px;
		overflow: scroll;
	}
	@include for-tablet-portrait-up {
		height: calc(100vh - 380px);
	}
	align-items: center;
	justify-content: center;
	// flex-direction: column;

	.questionWrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-self: center;
		max-height: 100%;
	}
	.questionWrap {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: scroll;
		overflow-x: hidden;
		// box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.5);

		// padding-top: 40px;
		// padding-bottom: 40px;

		// &::-webkit-scrollbar {
		// 	display: none;
		// }

		// scrollbar-width: none;
		// -ms-overflow-style: none;
		&::-webkit-scrollbar {
			width: 5px; // Width of the entire scrollbar
		}

		&::-webkit-scrollbar-track {
			background: transparent; // Color of the tracking area
		}

		&::-webkit-scrollbar-thumb {
			background: #2d2926; // Color of the scroll thumb
			border-radius: 12px; // Rounded corners on the scroll thumb
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #262626; // Color of the scroll thumb on hover
		}

		@include for-phone-only {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}

.inlineBlock {
	display: inline-block;

	@include for-phone-only {
		display: block;
		margin: 10px 0px 0px 0px;
		min-width: 75vw !important;
	}
}

.bigInput {
	font-style: italic;
	font-weight: 500;
	font-family: "PPEditorialOld UltralightItalic" !important;
	//font-family: 'PPEditorialOld Italic' !important;
	@extend .h3;
	@include for-phone-only {
		font-size: $h2-text-mobile;
		line-height: 42px;
	}
}

.midInput {
	width: 420px;
	@include for-phone-only {
		max-width: 100%;
	}
	// width: 100%;
}

.smallButtonOption {
	width: 65%;
	padding-bottom: 8px;
	@include for-phone-only {
		width: 100%;
	}
}

.midButtonOption {
	width: 80%;
	padding-bottom: 8px;
	@include for-phone-only {
		width: 100%;
	}
}
.bigButtonOption {
	width: 100%;
	padding-bottom: 8px;
	@include for-phone-only {
		width: 100%;
	}
}

.warningMsg {
	@extend .note-2;
	@extend .font-Mono-medium;
	color: #817f7d;
	width: 100%;
	text-align: right;
	@include for-phone-only {
		text-align: center;
		margin: 10px 0px;
	}
}

.selectWrapper {
	border-bottom: 2px solid black;
	display: inline-block;
	padding-bottom: 4px;

	@include for-phone-only {
		display: block;
		margin-bottom: 18px;
		width: 100%;
	}

	&.full {
		width: 75%;
		padding-bottom: 2px;
		@include for-phone-only {
			width: 90%;
		}
	}
	&.medium {
		width: 60%;
		padding-bottom: 4px;
		@include for-phone-only {
			width: 90%;
		}
	}
}

.extraMargin {
	@media (max-width: 600px) and (max-height: 640px) {
        // Styles for screens that are 600px or narrower and 400px or shorter
        margin-top: 20vh !important;
    }
	@media (max-width: 600px) and (max-height: 720px) {
        // Styles for screens that are 600px or narrower and 400px or shorter
        margin-top: 15vh;
    }
}

.leftSelect {
	text-align: left;
	:global(.ant-select-item) {
		text-align: left !important;
	}
}