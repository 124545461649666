@import "../../../styles/main";

.floatLabel {
    position: relative;
    border: 1px solid $secondary-color;
    // border-radius: 12px;
    padding: 10px 12px;
    // width: 350px;
    width: calc(min(100%, 350px));
    // width: 55%;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    @include for-medium-phone-only {
        width: calc(min(90%, 350px));
    }
    @include for-small-phone-only {
        width: calc(min(90%, 350px));
    }
    // min-height: calc(1px * 2);
    // min-width: calc(7rem + (1px * 2));

    // flex-grow: 1;
    // text-align: left;
    // // padding: 1.5rem;
    // margin: 1px;
    // transition: box-shadow var(.1s) ease;
    input {
        color: $secondary-color !important;
        margin-top: 20px !important;
        font-size: 16px !important;
    }
}

.label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 7px;
    transition: 0.2s ease all;
    color: $secondary-color;
    padding: 4px 11px;
    font-size: 16px !important;
}

.asPlaceholder {
    color: $secondary-color;
}

.asLabel {
    top: 0;
    font-size: 10px !important;
    background: transparent;
    // padding: 0 4px;
    // padding: 4px 11px;
    // margin-left: -4px;
}

.icon {
    color: $secondary-color !important;
    // margin-top: -19px !important;
}
