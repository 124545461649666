@import "@ph/common/styles/main";

.topSectionWrapper {
  margin: 0 0 20px;
}

.title {
  margin-bottom: 20px !important;
}

.subTitleBorder {
  border-right: 2px solid $night;
  padding-right: 10px;
  margin-right: 10px;
}

.subTitle {
  top: -5px;
  position: relative;
}

.searchInput {
  width: 350px;
  margin-right: 20px;
}