@import "@ph/common/styles/main";

.detailCard {
    
    border: 2px solid $night;
    border-radius: 8px;
    // background-color: $day;
    margin-right: 16px;
    .cardContainer {
        padding: 16px;
        width: 100%;
    }
    .cardText {
        color: $night;
    }
    // :hover {
    //     background-color: $night;
    //     .cardText {
    //         color: white;
    //     }
    // }
}
.detailRow {
    background-color: $day;
    border-bottom: 2px solid #cec3c1;
    .rowContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        .titleContainer {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}