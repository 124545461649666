@import "../../styles/main";

.rightSectionWrapper {
  margin: 0 12.5%;
  width: 75%;
}

.backLinkContainer {
  margin-bottom: 20px;
}

.formContainer {
  .formTitle {
    padding: 20px 0 40px;
  }

  :global(.ant-form-item-explain-error){
    text-align: left;
  }
  .formGroup .input {
    border-radius: 0 !important;
    border: 1px solid #000;
    height: 50px;
  }

  .formGroup .input.ant-input-status-error {
    border-color: $infrared;
  }
}