@import "@ph/common/styles/main";

.timeFilter {
  padding: 0 !important;
  border: 0 !important;
  height: 47px !important;
  background-color: $sand;

  &:hover, &:focus, &:active {
    background-color: $sand;
  }

  .calenderIcon, .filterLabel {
    display: inline-block;
  }

  .calenderIcon {
    background-color: $sand;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 3px 10px;
    height: 32px;
    border-radius: 42px;
  }

  .filterLabel {
    padding: 11.5px 27px;
    background-color: $secondary-color;
    border: 1px solid $night;
    border-radius: 4px;
    margin-left: 0 !important;
  }
}

$colWidth: calc(50% - 16px);
.timeRangeFilterModal {
  position: absolute;
  top: 140px;
  right: 20px;

  .closeIcon {
    position: relative;
    top: -25px;
    right: -25px;
  }

  :global {
    .ant-row {
      gap: 8px;
    }

    .ant-col-12 {
      width: $colWidth;
      flex: $colWidth;
    }

    .ant-modal-footer {
      padding-top: 24px;
      padding-bottom: 0;
    }
  }

  .timeRangePickerContainer {
    gap: 8px 0;

    :global .ant-col-12 {
      margin-top: 8px;
    }

    .label {
      color: #838383;
    }

    .timeRangePickerFilter {
      width: 100%;
      margin-top: 0;
    }
  }

  .saveTimerBtn {
    padding: 8px 22px !important;
    border: 2px solid $night !important;
    height: 40px;
    font-weight: 600;
  }
}