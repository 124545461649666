@import "@ph/common/styles/main";

.areaButton {
	min-width: 214px;
	height: 30px !important;
	@extend .center-flex;
	box-shadow: none;
	border-color: transparent !important;
	background-color: #dee0e0;

	border: 2px solid transparent !important;
	@include for-phone-only {
		height: 26px !important;
		border: 1px solid transparent !important;
	}

	span {
		@extend .note-1;
		@extend .font-Mono-medium;
		text-transform: uppercase;
		color: $night !important;
	}

	&:hover,
	&:active,
	&:focus,
	&:focus-visible,
	&:focus-visible {
		// color: $night
		color: $night !important;
		border-color: transparent !important;
		background-color: #dee0e0 !important;

		// background-color: var(--bg-color) !important;
		span {
			color: $night !important;
		}
	}

	&.selected {
		border: 2px solid $night !important;
		background-color: var(--bg-color);
		@include for-phone-only {
			border: 1px solid $night !important;
		}
		span {
			color: var(--text-color) !important;
		}
		&:hover,
		&:active,
		&:focus,
		&:focus-visible,
		&:focus-visible {
			color: var(--text-color) !important;
			background-color: var(--bg-color) !important;
			span {
				color: var(--text-color) !important;
			}
		}
	}

	&.otherButton {
		border-radius: 42px;
		height: 36px !important;
		text-align: center;
		@extend .center-flex;
		min-height: 36px !important;
		background-color: #bab9b8;
		&:hover {
			background-color: #bab9b8 !important;
		}
		&.selected {
			border: 2px solid $night !important;
			@include for-phone-only {
				border: 1px solid $night !important;
			}
		}
		@include for-phone-only {
			height: 26px !important;
			min-height: 26px !important;
		}
		.otherInput {
			text-align: center;
			cursor: pointer;
			background-color: #bab9b8;
			border-radius: 42px;
			border: none;
			box-shadow: none;
			min-width: 130px;
			@extend .note-1;
			@extend .font-Mono-medium;
			text-transform: uppercase;
			padding: 4px 6px;
			min-height: 32px;
			text-align: center;
			width: 90%;
			:global(.ant-input-clear-icon) {
				svg {
					color: $night;
					font-size: 18px;
				}
			}
			input {
				@extend .note-1;
				@extend .font-Mono-medium;
				text-align: center;
				background: transparent;
				text-transform: uppercase;
				border-radius: 0px;
				&:focus {
					border: none;
					box-shadow: none;
					color: $night;
					border-bottom: 2px solid $night;
					:global(.ant-input-clear-icon) {
						visibility: visible;
					}
				}
			}
			@include for-phone-only {
				min-height: 26px;
				max-height: 26px;
			}
		}
	}
	// &.otherButton{
	//     text-align: center;
	//     background-color: #BAB9B8;
	//     border-radius: 42px;
	//     input {
	//         text-align: center;
	//     background-color: #BAB9B8;
	//     border-radius: 42px;
	//     width: 100%;
	//     }
	//     &.selected {
	//     border: 2px solid $night !important;
	//     @include for-phone-only{
	//         border: 1px solid $night !important;
	//     }
	// }
}
