@import "@ph/common/styles/main";

.headerContainer {
    background: $primary-color;
    color: $secondary-color;
    height: $header-md-height !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-phone-only {
        height: $header-xs-height !important;
    }
    .linkText {
        color: $secondary-color !important;
        white-space: pre;
        font-size: 12px !important;
        margin-right: 20px;
        cursor: pointer;
        @include for-phone-only {
            margin-right: 0;
        }
        @include for-small-phone-only {
            font-size: 8px;
        }
        // &:hover {
        //     // transform: scale(1.1);
        //     text-decoration: underline;
        //     text-underline-offset: 3px;
        // }
    }
    .active {
        color: $bright-terra !important;
    }
    .logoutIcon {
        width: 25px;
        height: auto;
        content: url("../../../../../common/assets/images/admin_logout.svg");
        cursor: pointer;
        @include for-small-phone-only {
            height: 15px;
        }
    }
    .logoutSection {
        display: flex;
        align-items: center;
        gap: 10px;
        @include for-small-phone-only {
            gap: 8px;
        }
    }
    .customViewBoxPadding {
        @include for-phone-width-297 {
            padding: 0 0.5rem !important;
        }
    }
    .logoSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include for-phone-only {
            padding-right: 10px;
        }
        img {
            height: auto;
            width: 150px;
            content: url("../../../../../common/assets/images/admin_header.svg");
            // @include for-medium-phone-only {
            //     object-fit: cover;
            //     height: 50px;
            //     width: 120px;
            // }
            @include for-small-phone-only {
                object-fit: cover;
                height: 43px;
                width: auto;
            }
        }
    }
    .headTitle {
        color: $day;
        width: 100%;
        margin-left: 26px;
        border-left: 1px solid;
        height: 40px;
        @extend .h5;
        padding: 7px 0 10px 20px;
    }
    .searchInput {
        width: 240px;
        border: 1px solid $day !important;
        border-radius: 8px;
        padding: 8px 10px;
        margin-right: 20px;
        background-color: transparent !important;

        img {
            width: 20px;
            height: 20px;
        }

        input {
            font-family: "Neue Haas Grotesk Display Pro", serif;
            color: $day;
            background-color: transparent;
        }
        :global(.ant-input-clear-icon){
            color: $day;
        }
    }
    .scanButton {
      border: 1px solid $night;
      border-radius: 8px;
      background-color: $day;
      display: flex;
      height: 40px;
      padding: 8px 16px;
      margin-right: 20px;

      span {
        color: $night
      }

      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
      &:hover,&:active,&:focus {
        background-color: $day !important;
        span {
            color: $night
        }
      }
    }
}
